import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const ContentCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

export const ContentBox = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;
export const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 6px;
`;
export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: solid 1px ${PALETTE.line.normal};
  border-radius: 6px;
`;
export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body1};
  font-weight: 500;
  text-overflow: ellipsis;
  word-break: break-all;
`;
export const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;

  ${TYPOGRAPHY.label2};
  color: ${PALETTE.text.caption};
  text-overflow: ellipsis;
  word-break: break-all;
`;
