import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const Title = styled.p`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2}
  font-weight: 700;
`;
export const Required = styled.span`
  color: ${PALETTE.primary.normal};
  ${TYPOGRAPHY.body2}
`;
export const PlayTime = styled.div`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2}
`;
