import { Button } from '@/components';

import { PALETTE } from '@/themes';

import { ReactNode } from 'react';
import { IconType } from 'react-icons';

import * as S from './index.style';

type PillProps = {
  label: ReactNode;
  buttonIcon?: IconType;
  maxWidth?: number;
  iconClick?: () => void;
};

export const Pill = ({
  label,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  buttonIcon: Icon,
  maxWidth,
  iconClick,
}: PillProps) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Label $maxWidth={maxWidth}>{label}</S.Label>
        {Icon && (
          <Button.Svg onClick={iconClick}>
            {<Icon size={18} color={PALETTE.component.strong} />}
          </Button.Svg>
        )}
      </S.Wrapper>
    </S.Container>
  );
};
