import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  ${TYPOGRAPHY.body2}
  font-weight: 700;

  user-select: none;

  svg {
    margin-top: 4px;
  }

  > * {
    ${TYPOGRAPHY.body2}
    font-weight: 700;
  }

  &.stop {
    color: ${PALETTE.status.negative};
  }

  &.play {
    color: ${PALETTE.status.positive};
  }

  &.pause {
    color: ${PALETTE.status.cautionary};
  }

  &.unregister {
    color: ${PALETTE.component.normal};
  }
`;
