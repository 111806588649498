import { useEffect, useLayoutEffect } from 'react';

/**
 * `useIsomorphicLayoutEffect`는 현재 환경(서버 또는 클라이언트)에 따라 적절한 효과 훅을 사용하는 커스텀 훅입니다.
 *
 * 브라우저(클라이언트) 환경에서는 `useLayoutEffect`를 사용하여 모든 DOM 변경 후에 효과를 동기적으로 실행합니다. 이는
 * 레이아웃 측정과 같은 DOM 업데이트 후 즉시 수행되어야 하는 작업에 유용합니다.
 *
 * 서버 환경(예: Node.js를 사용한 서버 사이드 렌더링)에서는 경고를 피하기 위해 `useEffect`를 사용합니다. 서버에서는
 * `useLayoutEffect`가 지원되지 않기 때문입니다.
 *
 * 이를 통해 서버 사이드와 클라이언트 사이드 렌더링 모두에서 코드가 문제 없이 실행되도록 보장합니다.
 *
 * @example
 *   import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';
 *
 *   function MyComponent() {
 *   useIsomorphicLayoutEffect(() => {
 *   // 여기에 효과 로직을 작성하세요
 *   }, []);
 *
 *   return <div>My Component</div>;
 *   }
 *
 * @returns {Function} 클라이언트에서는 `useLayoutEffect`처럼 동작하고 서버에서는 `useEffect`처럼
 *   동작하는 훅을 반환합니다.
 */
export const useIsomorphicLayoutEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect;
