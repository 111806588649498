import { Fragment, PropsWithChildren, ReactNode } from 'react';

import * as S from './index.style';

type CardProps = {
  header: ReactNode;
  controls?: Array<ReactNode>;
  isShadow?: boolean;
} & PropsWithChildren;

export const Card = ({
  children,
  header,
  controls = [],
  isShadow = false,
}: CardProps) => {
  return (
    <S.Container $isShadow={isShadow}>
      <S.Header>
        {header}
        <S.Controls>
          {controls.map((c, i) => (
            <Fragment key={i}>{c}</Fragment>
          ))}
        </S.Controls>
      </S.Header>
      {children && <S.Body>{children}</S.Body>}
    </S.Container>
  );
};
