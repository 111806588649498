import { useSSE } from '@/hooks/useSSE';

import { PATH, QUERY_KEY, SNBITEMS } from '@/constants';

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Account } from './account';
import * as S from './index.style';

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isNewDevice, setIsNewDevice] = useState(false);

  const handleItemClick = (navi: string) => {
    navigate(navi);
    if (isNewDevice && navi === PATH.DEVICE_PAGE) {
      setIsNewDevice(false);
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.DEVICE(),
      });
    }
  };

  useEffect(() => {
    const unsub = useSSE.subscribe((state) => {
      if (state.message?.event === 'NEW_DEVICE') {
        setIsNewDevice(true);
      }
    });
    return unsub;
  }, []);

  return (
    <S.Container>
      <S.Title>
        <img src="/imgs/logo.png" alt="everythin Logo" />
      </S.Title>
      <S.Wrapper>
        {SNBITEMS.map((item, idx) => {
          const isActive = location.pathname.startsWith(`${item.navi}`);
          return (
            <S.Item
              key={idx}
              $isActive={isActive}
              onClick={() => handleItemClick(item.navi)}
            >
              <S.ItemGroup>
                <S.ItemIconWrapper>{item.icon}</S.ItemIconWrapper>
                {item.title}
              </S.ItemGroup>
              {item.navi.includes('device') && isNewDevice && (
                <S.Badge>NEW</S.Badge>
              )}
            </S.Item>
          );
        })}
      </S.Wrapper>
      <Account />
    </S.Container>
  );
};
