import { PALETTE } from '@/themes';

import {
  IoDesktopSharp,
  IoPhonePortraitOutline,
  IoTabletPortraitSharp,
  IoTv,
} from 'react-icons/io5';

import * as S from './index.style';

const PHONE = new Set(['IOS', 'Android', 'Fuchsia', 'phone']);
const TABLET = new Set(['tablet']);
const PC = new Set(['MacOS', 'Windows', 'Linux']);

type DeviceIconProps = {
  os: string;
  size?: number;
};

export const DeviceIcon = ({ os, size = 20 }: DeviceIconProps) => {
  return (
    <S.Container>
      {PHONE.has(os) ? (
        <IoPhonePortraitOutline size={size} color={PALETTE.component.strong} />
      ) : TABLET.has(os) ? (
        <IoTabletPortraitSharp size={size} color={PALETTE.component.strong} />
      ) : PC.has(os) ? (
        <IoDesktopSharp size={size} color={PALETTE.component.strong} />
      ) : (
        <IoTv size={size} color={PALETTE.component.strong} />
      )}
    </S.Container>
  );
};
