import {
  AddStoreModal,
  Button,
  Empty,
  Header,
  StoreTableLayout,
  useModal,
} from '@/components';

import { useStoreListQuery } from '@/hooks';

import { PALETTE } from '@/themes';

import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { IoSearch, IoStorefrontOutline } from 'react-icons/io5';

import * as S from './index.style';

export const Store = () => {
  const [search, setInputValue] = useState<string>('');
  const [pagination, setPagenation] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const pageOptions = {
    page: pagination.pageIndex,
    take: pagination.pageSize,
    q: search,
  };
  // getData
  const { data, refetch } = useStoreListQuery(pageOptions);

  const addStoreModal = useModal(() => (
    <AddStoreModal
      registered={new Set()}
      closeModal={addStoreModal.closeModal}
    />
  ));

  return (
    <>
      <Header
        title="매장 관리"
        isBorder={false}
        search={{
          placeHolder: '매장 이름, 매장 전화번호를 검색해 보세요',
          onSearch: setInputValue,
        }}
      />
      <S.MainBox>
        {data?.data && data?.data.length > 0 ? (
          <StoreTableLayout
            data={data}
            refetch={refetch}
            pagination={pagination}
            setPagination={setPagenation}
          />
        ) : (
          <S.StoreEmpty>
            {search.length > 0 ? (
              <Empty
                icon={<IoSearch size={60} color={PALETTE.component.normal} />}
                title={'검색 결과가 없어요.'}
              />
            ) : (
              <Empty
                icon={
                  <IoStorefrontOutline
                    size={60}
                    color={PALETTE.component.normal}
                  />
                }
                title={'등록된 매장이 없어요!'}
                description={
                  <S.StoreEmptyContainer>
                    <div>
                      매장 추가 버튼을 클릭해서 매장을 추가하고 관리해 보세요.
                    </div>
                    <Button
                      type="button"
                      outlined={true}
                      colorType="secondary"
                      onClick={addStoreModal.openModal}
                    >
                      매장 추가
                    </Button>
                  </S.StoreEmptyContainer>
                }
              />
            )}
          </S.StoreEmpty>
        )}
      </S.MainBox>
    </>
  );
};
