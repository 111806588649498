import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div<{ $bgColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0.25rem 1rem;
  border-radius: 0.375rem;

  background-color: ${({ $bgColor }) => $bgColor};
`;
export const Title = styled.div`
  flex-grow: 1;
  max-width: 100%;

  overflow: hidden;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.label2}
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Range = styled.div`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.label2}
`;
