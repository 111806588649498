import { AlertModal } from '@/components';

import { PATH } from '@/constants';

import { useNavigate } from 'react-router-dom';

type MoveToDevicePageModalProps = {
  closeModal: () => void;
};

export const MoveToDevicePageModal = ({
  closeModal,
}: MoveToDevicePageModalProps) => {
  const navigate = useNavigate();

  return (
    <AlertModal
      title="해당 매장에 연결된 디바이스가 있어요."
      description={'매장과 연결된 디바이스를 먼저 삭제해 주세요.'}
      submitButtonLabel={'디바이스 페이지로 이동'}
      closeButtonLabel="취소"
      closeButtonEnable={true}
      onSubmit={() => {
        navigate(PATH.DEVICE_PAGE);
        closeModal();
      }}
      onClose={closeModal}
      closeModal={closeModal}
    />
  );
};
