import { AlertModal, useModal } from '@/components';

import { useAuth } from '@/hooks';
import { useSSE } from '@/hooks/useSSE';

import { PALETTE } from '@/themes';

import { Sidebar } from '@/layout';

import { PropsWithChildren, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import * as S from './index.style';

export const MainLayout = ({ children }: PropsWithChildren) => {
  const checkLoginModal = useModal(() => (
    <AlertModal
      title="로그아웃 안내"
      description={
        <div>
          이 계정이 다른 기기에서 로그인 되어, 자동으로 로그아웃 됐어요.
          <br />
          <br />
          <span style={{ color: PALETTE.text.caption }}>
            {'※ 작업 중인 내용은 저장되지 않아요.'}
          </span>
          <br />
          <span style={{ color: PALETTE.text.caption }}>
            {'※ 자동 로그인 설정된 기기도 모두 로그아웃 돼요.'}
          </span>
        </div>
      }
      submitButtonLabel="확인"
      onSubmit={() => {
        useAuth.getState().resetAuth();
        checkLoginModal.closeAllModal();
      }}
      closeModal={() => {}}
    />
  ));

  useEffect(() => {
    const unsub = useSSE.subscribe((state) => {
      if (state.message?.event === 'LOGOUT') {
        useAuth.getState().resetAuth();
        checkLoginModal.closeAllModal();
      }
      if (state.message?.event === 'MULTIPLE_LOGIN') {
        checkLoginModal.closeAllModal();
        checkLoginModal.openModal();
      }
    });
    return unsub;
  }, []);

  return (
    <S.MainLayoutContainer>
      <Sidebar />
      <S.MainContent>{children ?? <Outlet />}</S.MainContent>
    </S.MainLayoutContainer>
  );
};
