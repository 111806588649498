import styled, { css, keyframes } from 'styled-components';

const qrMove = (width: number) => keyframes`
   0%, 50% {
    transform: translateX(32px);
  }
  50.01%, 100% {
    transform: translateX(calc(100vw - ${width}px - 32px));
  }
`;

export const Container = styled.div<{ $width: number; $position: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ $position, $width }) =>
    $position === 'move'
      ? css`
          animation: ${qrMove($width)} 20s infinite;
        `
      : $position === 'left'
        ? css`
            left: 32px;
          `
        : css`
            right: 32px;
          `}
`;

export const QRWithImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;
export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
export const Description = styled.div`
  width: 100%;

  font-family: SUIT;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-all;
`;
