import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  background-color: ${PALETTE.background.normal};
`;
export const ScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;

  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
