import { ReactNode } from 'react';

import * as S from './index.style';

type EmptyProps = {
  children?: ReactNode;
  icon?: ReactNode;
  title: string;
  description?: ReactNode;
};

export const Empty = ({ children, icon, title, description }: EmptyProps) => {
  return (
    <S.EmptyContainer>
      {icon}
      <S.EmptyTitle>{title}</S.EmptyTitle>
      <S.EmptyDescription>{description}</S.EmptyDescription>
      {children}
    </S.EmptyContainer>
  );
};
