import { PALETTE } from '@/themes';

import { forwardRef } from 'react';
import { IoImage, IoVideocam } from 'react-icons/io5';

import * as S from './index.style';

type PlaceholderProps = {
  type?: 'image' | 'video';
  width?: number;
  height?: number;
  size?: number;
};

export const Placeholder = forwardRef<HTMLDivElement, PlaceholderProps>(
  ({ type = 'image', width, height, size }, ref) => {
    return (
      <S.Container ref={ref} $width={size || width} $height={size || height}>
        {type === 'image' ? (
          <IoImage size={24} color={PALETTE.component.normal} />
        ) : (
          <IoVideocam size={24} color={PALETTE.component.normal} />
        )}
      </S.Container>
    );
  },
);
