import { ContentsPreviewCard } from './contentsPreviewCard';
import { DevicePlaylistCard } from './playlistCard';
import { PromotionCard } from './promotionPlaylistCard';

const DeviceModiftyPlaylistCard =
  DevicePlaylistCard as typeof DevicePlaylistCard & {
    Promotion: typeof PromotionCard;
    ContentsPreview: typeof ContentsPreviewCard;
  };

DeviceModiftyPlaylistCard.Promotion = PromotionCard;
DeviceModiftyPlaylistCard.ContentsPreview = ContentsPreviewCard;

export { DeviceModiftyPlaylistCard };
