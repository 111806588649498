import { Button } from '@/components';

import { PALETTE } from '@/themes';

import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import * as S from './index.style';

export type TooltipListType = {
  name: React.ReactNode;
  icon: React.ReactNode;
  color: string;
  disabled?: boolean;
  handle: () => void;
};

type TooltipProps = {
  itemList: Array<TooltipListType>;
};

export const Tooltip = ({ itemList }: TooltipProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      padding={4}
      positions={['bottom', 'left', 'top']}
      align="end"
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <S.TooltipListContainer>
          {itemList.map((elem, idx) => (
            <S.TooltipListItem
              key={idx}
              disabled={elem.disabled ?? false}
              $color={elem.disabled ? PALETTE.text.disable : elem.color}
              onClick={() => {
                setIsPopoverOpen(false);
                elem.handle();
              }}
            >
              {elem.icon}
              {elem.name}
            </S.TooltipListItem>
          ))}
        </S.TooltipListContainer>
      }
    >
      <Button.Svg onClick={() => setIsPopoverOpen((prev) => !prev)}>
        <S.TooltipIcon size={24} />
      </Button.Svg>
    </Popover>
  );
};
