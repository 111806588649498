import { InputHTMLAttributes, forwardRef } from 'react';

import * as S from './index.style';

type RadioOption = {
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef<HTMLInputElement, RadioOption>(
  ({ ...props }, ref) => {
    return (
      <S.RadioWrapper>
        <S.Radio
          type="radio"
          id={props.name}
          name={props.name}
          {...props}
          ref={ref}
        />
        {props.label && <S.Label htmlFor={props.name}>{props.label}</S.Label>}
      </S.RadioWrapper>
    );
  },
);
