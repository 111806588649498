import { ApiErrorResponse, PlayContentResDto } from '@/types';

import {
  Button,
  Card,
  DeleteModal,
  MoveToDevicePageModal,
  PlaylistCardDetail,
  PlaylistDeviceRegistrationModal,
  PreviewContentsModal,
  Tooltip,
  useModal,
} from '@/components';

import { useDeletePlaylistMutation } from '@/hooks';

import { isArrEmpty } from '@/utils';

import { API_ERROR_CODE, PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { IoAdd, IoPencil, IoPlayCircleOutline, IoTrash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

type PlaylistCardProps = {
  search: string;
  playlistId: string;
  name: string;
  totalPlayTime: number;
  contentCount: number;
  playContents: Array<PlayContentResDto>;
  createdAt: Date;
  updatedAt?: Date;
};

export const PlaylistCard = ({
  playlistId,
  name,
  totalPlayTime,
  contentCount,
  playContents,
  createdAt,
  updatedAt,
}: PlaylistCardProps) => {
  const navigate = useNavigate();
  const { mutate: deletePlaylist } = useDeletePlaylistMutation({
    onError: (error: AxiosError<ApiErrorResponse>) => {
      if (error.response?.data.errorCode === API_ERROR_CODE.INUSE_PLAYLIST) {
        moveToDevice.openModal();
      }
    },
  });
  const deviceRegistration = useModal(() => (
    <PlaylistDeviceRegistrationModal
      playlistId={playlistId}
      closeModal={deviceRegistration.closeModal}
    />
  ));
  const previewContents = useModal(() => (
    <PreviewContentsModal
      playContents={playContents}
      closeModal={previewContents.closeModal}
    />
  ));
  const moveToDevice = useModal(() => (
    <MoveToDevicePageModal closeModal={moveToDevice.closeModal} />
  ));
  const deleteWarning = useModal(() => (
    <DeleteModal
      closeModal={deleteWarning.closeModal}
      onSubmit={() => deletePlaylist(playlistId)}
    />
  ));

  const itemList = useMemo(
    () => [
      {
        name: '디바이스에 등록하기',
        icon: <IoAdd size={24} />,
        color: PALETTE.text.normal,
        handle: deviceRegistration.openModal,
      },
      {
        name: '수정하기',
        icon: <IoPencil size={24} />,
        color: PALETTE.text.normal,
        handle: () =>
          navigate(PATH.PLAYLIST_MODIFY_PAGE(playlistId), {
            state: {
              prevUrl: PATH.PLAYLIST_PAGE,
            },
          }),
      },
      {
        name: '삭제하기',
        icon: <IoTrash size={24} />,
        color: PALETTE.status.negative,
        handle: deleteWarning.openModal,
      },
    ],
    [playlistId],
  );
  return (
    <Card
      isShadow
      header={
        <PlaylistCardDetail
          name={name}
          totalPlayTime={totalPlayTime}
          contentCount={contentCount}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      }
      controls={[
        <Button.Svg
          disabled={isArrEmpty(playContents)}
          onClick={previewContents.openModal}
        >
          <IoPlayCircleOutline
            size={24}
            color={
              isArrEmpty(playContents)
                ? PALETTE.interaction.disable
                : PALETTE.component.heavy
            }
          />
        </Button.Svg>,
        <Tooltip itemList={itemList} />,
      ]}
    />
  );
};
