import { FileType } from '@/types';

import { PlaylistContentType } from '@/pages';

import {
  Button,
  Empty,
  PlaylistModifyContentCard,
  SortableItemType,
  SortableList,
} from '@/components';

import { useFileUpload } from '@/hooks';

import { isArrEmpty, secondToTimeFormat } from '@/utils';

import { PALETTE } from '@/themes';

import { FaPhotoVideo } from 'react-icons/fa';
import { v4 } from 'uuid';

import * as S from './index.style';

type PlaylistModifyContentsProps = {
  contents: PlaylistContentType[];
  setPlayTime: (contentId: string, time: string) => void;
  updateContentOrder: (contents: PlaylistContentType[]) => void;
  addContents: (files: File[]) => Promise<void>;
  copyContent: (contentId: string) => void;
  deleteContent: (contentId: string) => void;
};

export const PlaylistModifyContents = ({
  contents,
  updateContentOrder,
  setPlayTime,
  addContents,
  copyContent,
  deleteContent,
}: PlaylistModifyContentsProps) => {
  const { handleFileSelection } = useFileUpload();
  const totalPlayTime = contents.reduce(
    (prev, cur) => prev + (Number(cur.playTime) ?? 0),
    0,
  );
  const sortableContents = contents
    .sort((a, b) => a.order - b.order)
    .map((v) => ({
      orderId: v4(),
      data: { ...v, file: { ...v.file } },
    }));
  const getItemId = (item: SortableItemType<PlaylistContentType>) =>
    item.orderId;

  const handleItemsUpdated = (
    updatedItems: SortableItemType<PlaylistContentType>[],
  ) =>
    updateContentOrder(updatedItems.map((v, i) => ({ ...v.data, order: i })));

  const renderItem = (item: SortableItemType<PlaylistContentType>) => {
    return (
      <PlaylistModifyContentCard
        orderId={item.orderId}
        contentName={item.data.file.originalName ?? ''}
        playTime={item.data.playTime}
        thumbnailUrl={item.data.file.thumbnailUrl ?? ''}
        type={item.data.file.type ?? FileType.IMAGE}
        setPlayTime={(time) => setPlayTime(item.data.id, time)}
        copyContent={() => copyContent(item.data.id)}
        deleteContent={() => deleteContent(item.data.id)}
      />
    );
  };

  const uploadAddContents = async () => {
    const files = await handleFileSelection();
    addContents(files);
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title>
          콘텐츠 목록 <S.Required>(필수)</S.Required>
        </S.Title>
        <S.PlayTime>재생 시간: {secondToTimeFormat(totalPlayTime)}</S.PlayTime>
      </S.Header>
      {isArrEmpty(contents) ? (
        <Empty
          title={'등록된 콘텐츠가 없어요.'}
          description={'콘텐츠를 추가하고 재생 목록을 만들어 보세요.'}
          icon={<FaPhotoVideo color={PALETTE.component.normal} size={60} />}
        >
          <Button
            outlined={true}
            colorType={'secondary'}
            onClick={uploadAddContents}
          >
            콘텐츠 추가하기
          </Button>
        </Empty>
      ) : (
        <SortableList
          items={sortableContents}
          getItemId={getItemId}
          renderItem={renderItem}
          onItemsUpdated={handleItemsUpdated}
        />
      )}
    </S.Container>
  );
};
