import { TextField } from '@/components';

type LayerNameInputProps = {
  name: string;
  setName: (name: string) => void;
};

export const LayerNameInput = ({ name, setName }: LayerNameInputProps) => {
  return (
    <TextField
      placeholder="팝업 레이어 이름을 입력하세요."
      value={name}
      onChange={(e) => setName(e.target.value)}
    />
  );
};
