import { DeviceTableLayout, Empty, Header } from '@/components';

import { useDeviceListQuery } from '@/hooks';

import { PALETTE } from '@/themes';

import { PaginationState } from '@tanstack/react-table';
import { useState } from 'react';
import { IoDesktopOutline, IoSearch } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import * as S from './index.style';

export const Device = () => {
  const location = useLocation();
  const [search, setInputValue] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>(
    location.state?.device.pagination ?? { pageIndex: 0, pageSize: 10 },
  );

  // getData
  const { data, refetch } = useDeviceListQuery({
    page: pagination.pageIndex,
    take: pagination.pageSize,
    q: search,
  });

  return (
    <>
      <Header
        title="디바이스"
        isBorder={false}
        search={{
          placeHolder: '디바이스 이름, 종류를 검색해 보세요',
          onSearch: setInputValue,
        }}
      />
      <S.MainBox>
        {data?.data && data?.data.length > 0 ? (
          <DeviceTableLayout
            data={data}
            refetch={refetch}
            pagination={pagination}
            setPagination={setPagination}
          />
        ) : (
          <S.DeviceEmpty>
            {search.length > 0 ? (
              <Empty
                icon={<IoSearch size={60} color={PALETTE.component.normal} />}
                title={'검색 결과가 없어요.'}
              />
            ) : (
              <Empty
                icon={
                  <IoDesktopOutline
                    size={60}
                    color={PALETTE.component.normal}
                  />
                }
                title={'등록된 디바이스가 없어요!'}
                description={
                  <>디바이스에서 콘솔 페이지에 접속해서 등록해 주세요.</>
                }
              />
            )}
          </S.DeviceEmpty>
        )}
      </S.MainBox>
    </>
  );
};
