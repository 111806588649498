import { ApiErrorResponse } from '@/types';

import { LoginReqDto, LoginResDto, postLogin } from '@/apis';

import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const useLoginMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: AxiosResponse<LoginResDto>) => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
}) => {
  return useMutation({
    mutationFn: async (loginData: LoginReqDto) => {
      return await postLogin({ ...loginData });
    },
    onSuccess: (res) => onSuccess?.(res),
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
  });
};
