import styled from 'styled-components';

export const StoreEmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

export const StoreEmpty = styled.div`
  width: 100%;
  height: calc(100vh - 126px);
`;

export const MainBox = styled.main`
  width: 100%;
  margin: 0 auto;

  overflow-y: scroll;
`;
