import { PlayListItemResDto } from '@/apis';

import { Accordion } from '@/components';

import { secondToTimeFormat } from '@/utils';

import * as S from './index.style';
import { PlaylistAccordionItem } from './playlistAccordionItem';
import accordionDot from '/imgs/accordionDot.svg';

type PlaylistAccordionProps = Pick<
  PlayListItemResDto,
  'name' | 'totalPlayTime' | 'contentCount' | 'playContents'
>;

const PlaylistAccordion = ({
  name,
  totalPlayTime,
  contentCount,
  playContents,
}: PlaylistAccordionProps) => {
  return (
    <Accordion
      header={
        <>
          <S.Title>{name}</S.Title>
          <S.Desc>
            재생시간: {secondToTimeFormat(totalPlayTime)}{' '}
            <img src={accordionDot} />
            콘텐츠 개수: {contentCount}개
          </S.Desc>
        </>
      }
    >
      {playContents &&
        playContents.map((item, index) => (
          <PlaylistAccordion.Item
            key={index}
            thumbnailUrl={item.file.thumbnailUrl}
            originalName={item.file.originalName}
            type={item.file.type}
          />
        ))}
    </Accordion>
  );
};

PlaylistAccordion.Item = PlaylistAccordionItem;

export { PlaylistAccordion };
