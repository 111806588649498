import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import { SortableItem } from './sortableItem';

export type OrderId = string;
export type SortableItemType<T> = { orderId: OrderId; data: T };
type SortableListProps<T> = {
  items: SortableItemType<T>[];
  getItemId: (item: SortableItemType<T>) => OrderId;
  renderItem: (item: SortableItemType<T>) => React.ReactNode;
  onItemsUpdated: (items: SortableItemType<T>[]) => void;
};

export { DragHandle } from './dragHandle';
export const SortableList = <T,>({
  items,
  getItemId,
  renderItem,
  onItemsUpdated,
}: SortableListProps<T>) => {
  const [currentItems, setCurrentItems] = useState<SortableItemType<T>[]>([]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = currentItems.findIndex(
        (item) => getItemId(item) === active.id,
      );
      const newIndex = currentItems.findIndex(
        (item) => getItemId(item) === over.id,
      );
      if (oldIndex !== -1 && newIndex !== -1) {
        const newItems = arrayMove(currentItems, oldIndex, newIndex);
        setCurrentItems(newItems);
        onItemsUpdated(newItems);
      }
    }
  };

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={currentItems.map((element) => getItemId(element))}
        strategy={verticalListSortingStrategy}
      >
        {currentItems.map((item) => (
          <SortableItem
            key={getItemId(item)}
            id={getItemId(item)}
            item={item}
            renderItem={renderItem}
            handleEnable
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
