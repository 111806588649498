import { Placeholder } from '@/components';

import * as S from './index.style';

interface FilePreviewProps {
  file: File & { preview: string };
}

export const FilePreview = ({ file }: FilePreviewProps) => {
  const fileType = file.type.startsWith('image/') ? 'image' : 'video';
  if (fileType === 'image') {
    return (
      <S.Image
        src={file.preview}
        alt={file.name}
        width={48}
        height={48}
        loading="lazy"
      />
    );
  }

  return <Placeholder type={fileType} size={48} />;
};
