import { TextField } from '@/components';

import { useDebounce } from '@/hooks';

import { PALETTE } from '@/themes';

import { useEffect, useState } from 'react';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import validator from 'validator';

type LayerUrlInputProps = {
  url: string;
  setUrl: (url: string) => void;
};

export const LayerUrlInput = ({ url, setUrl }: LayerUrlInputProps) => {
  const [localUrl, setLocalUrl] = useState('');
  const debouncedLocalUrl = useDebounce(localUrl, 300);

  useEffect(() => {
    if (url) setLocalUrl(url);
  }, [url]);

  useEffect(() => {
    const isUrl = validator.isURL(debouncedLocalUrl);
    setUrl(isUrl ? debouncedLocalUrl : '');
  }, [debouncedLocalUrl]);

  return (
    <TextField
      placeholder="URL을 입력하세요."
      value={localUrl}
      onChange={(e) => setLocalUrl(e.target.value)}
      suffix={
        validator.isURL(debouncedLocalUrl) ? (
          <IoCheckmarkCircleOutline size={24} color={PALETTE.status.positive} />
        ) : (
          <IoCloseCircleOutline size={24} color={PALETTE.status.negative} />
        )
      }
    />
  );
};
