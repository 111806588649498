import { PageReqOptionDto, StoreEntity } from '@/types';

import {
  StoreAddReqDto,
  StoreListResDto,
  StorePutReqDto,
  instance,
} from '@/apis';

import { API } from '@/constants';

type StoreId = StoreEntity['id'];

/**
 * 매장 리스트 보기(paginated)
 *
 * @param pageOption -
 *
 *   - "page": "number", -> 현재 페이지
 *   - "take": "number", -> 페이지네이션 범위
 *   - "q": "string" -> 검색할 이름의 일부분
 *
 * @returns 200: 페이지, 검색어에 따른 매장 리스트를 반환합니다(StoreListResDto)
 */
export const getStores = async (pageOption?: PageReqOptionDto) => {
  const { data } = await instance.get<StoreListResDto>(
    pageOption ? API.STORE_PAGE(pageOption) : API.STORE,
  );
  return data;
};

/**
 * 새 매장 등록
 *
 * @param body -
 *
 *   - "name": "string", -> 등록핢 매장 이름
 *   - "phoneNum": "string", -> 매장 전화번호
 *   - "description": "string" -> 매장 메모
 *
 * @returns 201 요청 실행 성공
 */
export const postStore = async (body: StoreAddReqDto) => {
  return await instance.post(API.STORE, body);
};

/**
 * 매장 수정
 *
 * @param id - 수정할 매장 id
 * @param body -
 *
 *   - "name": "string", -> 등록핢 매장 이름
 *   - "phoneNum": "string", -> 매장 전화번호
 *   - "description": "string" -> 매장 메모
 *
 * @returns 200 요청 실행 성공
 */
export const putStoreById = async (id: StoreId, body: StorePutReqDto) => {
  return await instance.put(API.STORE_ID(id), body);
};

/**
 * 매장 삭제
 *
 * @param id - 삭제할 매장 id 리스트 (','으로 구별)
 * @returns 200 요청 실행 성공
 * @returns 30002 등록된 디바이스가 있을 경우
 */
export const deleteStoreById = async (id: StoreId) => {
  return await instance.delete(API.STORE_ID(id));
};
