import { dateFormat, secondToTimeFormat } from '@/utils';

import { PALETTE } from '@/themes';

import { memo } from 'react';
import { FaCircle } from 'react-icons/fa';

import * as S from './index.style';

type PlaylistCardDetailProps = {
  name: string;
  totalPlayTime: number;
  contentCount: number;
  createdAt: Date;
  updatedAt?: Date;
};

export const PlaylistCardDetail = memo(
  ({
    name,
    totalPlayTime,
    contentCount,
    createdAt,
    updatedAt,
  }: PlaylistCardDetailProps) => {
    return (
      <S.Container>
        <S.Title>{name}</S.Title>
        <div>
          <S.Description>
            재생 시간: {secondToTimeFormat(totalPlayTime)}
          </S.Description>
          <S.Description>콘텐츠 개수: {contentCount}</S.Description>
        </div>
        <>
          <S.Dates>
            <div>생성일: {dateFormat(new Date(createdAt), '/')}</div>
            <FaCircle size={4} color={PALETTE.text.caption} />
            {updatedAt && (
              <div>최종 수정일: {dateFormat(new Date(updatedAt), '/')}</div>
            )}
          </S.Dates>
        </>
      </S.Container>
    );
  },
);
