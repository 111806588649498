import { PageReqOptionDto } from '@/types';

import { getDevices } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useQuery } from '@tanstack/react-query';

/**
 * GET /device
 *
 * 모든 디바이스를 조회하는 api입니다.
 *
 * 디바이스 페이지에서 사용됩니다.
 */
export const useDeviceListQuery = (pageOption?: PageReqOptionDto) => {
  return useQuery({
    queryKey: QUERY_KEY.DEVICE(pageOption),
    queryFn: () => getDevices(pageOption),
  });
};
