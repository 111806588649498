import { ApiErrorResponse } from '@/types';

import { deletePlayListById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useDeletePlaylistMutation = ({
  retry,
  onError,
}: {
  retry?: number;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePlayListById,
    retry: retry,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.PLAYLIST_INFINITY(),
      });
    },
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
  });
};
