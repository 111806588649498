import { AlertModal } from '@/components';

import { PALETTE } from '@/themes';

type DeviceConflictModalProps = {
  closeModal: () => void;
  onSubmit: () => void;
};

export const DeviceConflictModal = ({
  closeModal,
  onSubmit,
}: DeviceConflictModalProps) => {
  return (
    <AlertModal
      title="선택한 디바이스에 다른 팝업 레이어가 등록되어 있어요."
      description={
        <span style={{ color: PALETTE.text.caption }}>
          이미 등록된 팝업 레이어를 삭제하고 새로운 팝업 레이어로 변경할까요?
        </span>
      }
      submitButtonLabel="변경하기"
      closeButtonLabel="취소"
      closeButtonEnable={true}
      onSubmit={onSubmit}
      onClose={closeModal}
      closeModal={closeModal}
    />
  );
};
