import { TextArea } from '@/components';

type LayerDescriptionTextAreaProps = {
  description: string;
  setDescription: (description: string) => void;
  disabled?: boolean;
};

export const LayerDescriptionTextArea = ({
  description,
  setDescription,
  disabled = false,
}: LayerDescriptionTextAreaProps) => {
  return (
    <TextArea
      countLengthEnable
      disabled={disabled}
      maxLength={24}
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
};
