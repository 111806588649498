import { PATH } from '@/constants';

import {
  IoBarChart,
  IoDesktopOutline,
  IoImageOutline,
  IoMusicalNotes,
  IoStorefrontOutline,
} from 'react-icons/io5';

export const SNBITEMS = [
  {
    title: '대시보드',
    navi: PATH.DASHBOARD_PAGE,
    icon: <IoBarChart size={24} />,
  },
  {
    title: '매장 관리',
    navi: PATH.STORE_PAGE,
    icon: <IoStorefrontOutline size={24} />,
  },
  {
    title: '재생목록',
    navi: PATH.PLAYLIST_PAGE,
    icon: <IoMusicalNotes size={24} />,
  },
  {
    title: '디바이스',
    navi: PATH.DEVICE_PAGE,
    icon: <IoDesktopOutline size={24} />,
  },
  {
    title: '팝업 레이어',
    navi: PATH.POPUP_PAGE,
    icon: <IoImageOutline size={24} />,
  },
];
