import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${PALETTE.line.normal};
  border-radius: 0.375rem;

  background-color: ${PALETTE.static.white};
  overflow: hidden;

  box-sizing: border-box;

  box-shadow: ${BOXSHADOW.card};
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${PALETTE.line.normal};
`;
export const PromotionSection = styled.div`
  padding: 1rem 1.5rem;
`;
export const PromotionTitle = styled.div`
  margin-bottom: 0.625rem;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2};
  font-weight: 700;
`;
export const PromotionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.375rem;
  margin-bottom: 0.375rem;
`;
export const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
`;
export const AddButtonText = styled.div`
  margin-left: 0.125rem;

  color: ${PALETTE.text.placeholder};
  ${TYPOGRAPHY.label2};
  font-weight: 700;
`;
