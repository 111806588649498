import { BOXSHADOW } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 6px;

  background-color: white;
  gap: 12px;
  box-shadow: ${BOXSHADOW.card};
`;

export const SizeDisplay = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
`;
