import { PropsWithChildren } from 'react';

import * as S from './index.style';

export type ModalFooterProps = {
  isBorderTop?: boolean;
} & PropsWithChildren;

export const ModalFooter = ({
  children,
  isBorderTop = false,
}: ModalFooterProps) => {
  return <S.Container $isBorderTop={isBorderTop}>{children}</S.Container>;
};
