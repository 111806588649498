import { Empty, Header } from '@/components';

import { PALETTE } from '@/themes';

import { IoSettingsSharp } from 'react-icons/io5';

import * as S from './index.style';

export const Dashboard = () => {
  return (
    <>
      <Header title="대시보드" isBorder={false} />
      <S.MainBox>
        <S.DeviceEmpty>
          <Empty
            icon={
              <IoSettingsSharp size={60} color={PALETTE.component.normal} />
            }
            title={'현재 페이지는 준비 중이에요'}
            description={
              <>
                멋진 대시보드를 만들기 위해 열심히 준비 중이에요. 조금만 기다려
                주세요!
              </>
            }
          />
        </S.DeviceEmpty>
      </S.MainBox>
    </>
  );
};
