import { StatusType } from '@/types';

import * as S from './index.style';

type StatusProps = {
  type: keyof typeof StatusType;
};

export const Status = ({ type }: StatusProps) => {
  return (
    <S.StatusWrapper className={StatusType[type]}>
      <div>{type}</div>
    </S.StatusWrapper>
  );
};
