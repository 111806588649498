import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const StoreTextTd = styled.div`
  width: 100%;
  overflow-x: hidden;

  ${TYPOGRAPHY.body2}
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropdownItem = styled.li<{ $isSelected: boolean }>`
  padding: 0.375rem 0.875rem;

  ${TYPOGRAPHY.body2};
  cursor: pointer;

  font-weight: 400;

  &:hover {
    background-color: rgb(23 26 31 / 5%);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: ${PALETTE.primary.light.normal};

      color: ${PALETTE.primary.normal};
      font-weight: 700;
    `}
`;

export const HandlerButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const DropdownWrapper = styled.div`
  width: 9rem;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`;

export const TooltipItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  > div {
    ${TYPOGRAPHY.body2}
    font-weight: 400;
  }
`;

export const PaginationWrapper = styled.div`
  padding: 1rem 0 1.25rem;
`;

export const TableContainer = styled.div`
  width: 100%;

  /* max-width: 70rem; */
  padding: 0 1.5rem;

  box-sizing: border-box;
`;
