import { PlayContentResDto } from '@/types';

import {
  AlertModal,
  DeleteModal,
  PreviewContentsModal,
  useModal,
} from '@/components';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { useCallback } from 'react';
import {
  IoPencil,
  IoPinSharp,
  IoPlayCircleOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type DevicePlaylistCardMenuProps = {
  playlistId: string;
  playContents: PlayContentResDto[];
  setDefaultPlaylist: (playlistId: string) => void;
  deletePlaylist: (playlistId: string) => void;
  isDefault?: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const DevicePlaylistCardMenu = ({
  playlistId,
  playContents,
  setDefaultPlaylist,
  deletePlaylist,
  isDefault = false,
  setIsOpen,
}: DevicePlaylistCardMenuProps) => {
  const navigate = useNavigate();

  const leavePageAlertModal = useModal(() => (
    <AlertModal
      title={'현재 페이지에서 나가겠습니까?'}
      description={'변경 사항이 저장되지 않습니다.'}
      submitButtonLabel={'나가기'}
      onSubmit={() => {
        navigate(PATH.PLAYLIST_MODIFY_PAGE(playlistId), {
          state: {
            prevUrl: PATH.DEVICE_PAGE,
          },
        }),
          leavePageAlertModal.closeModal();
      }}
      closeButtonLabel={'취소'}
      closeButtonEnable={true}
      onClose={leavePageAlertModal.closeModal}
      closeModal={leavePageAlertModal.closeModal}
    />
  ));

  const previewModal = useModal(() => (
    <PreviewContentsModal
      playContents={playContents}
      closeModal={previewModal.closeModal}
    />
  ));

  const deleteModal = useModal(() => (
    <DeleteModal
      onSubmit={() => {
        deletePlaylist(playlistId);
        deleteModal.closeModal();
      }}
      closeModal={deleteModal.closeModal}
    />
  ));

  const handleSetDefault = useCallback(() => {
    setIsOpen(false);
    setDefaultPlaylist(playlistId);
  }, []);

  const handleEdit = useCallback(() => {
    setIsOpen(false);
    leavePageAlertModal.openModal();
  }, []);

  const handlePreview = useCallback(() => {
    setIsOpen(false);
    previewModal.openModal();
  }, []);

  const handleDelete = useCallback(() => {
    setIsOpen(false);
    deleteModal.openModal();
  }, []);

  return (
    <S.Container>
      <S.MenuItem $isDefault={isDefault} onClick={handleSetDefault}>
        <IoPinSharp
          size={24}
          color={isDefault ? PALETTE.text.disable : PALETTE.text.normal}
        />
        <S.MenuItemText
          style={
            isDefault
              ? {
                  color: PALETTE.text.disable,
                }
              : undefined
          }
        >
          {isDefault ? '기본 재생 콘텐츠 해제' : '기본 재생 콘텐츠로 설정'}
        </S.MenuItemText>
      </S.MenuItem>
      <S.MenuItem onClick={handleEdit}>
        <IoPencil size={24} color={PALETTE.text.normal} />
        <S.MenuItemText>수정하기</S.MenuItemText>
      </S.MenuItem>
      <S.MenuItem onClick={handlePreview}>
        <IoPlayCircleOutline size={24} color={PALETTE.text.normal} />
        <S.MenuItemText>등록된 콘텐츠 미리보기</S.MenuItemText>
      </S.MenuItem>
      <S.MenuItem onClick={handleDelete}>
        <IoTrashOutline size={24} color={PALETTE.status.negative} />
        <S.MenuItemText style={{ color: PALETTE.status.negative }}>
          삭제하기
        </S.MenuItemText>
      </S.MenuItem>
    </S.Container>
  );
};
