import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 6px;

  padding: 18px 16px;
  border-top: 1px solid ${PALETTE.line.normal};
`;
export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
`;
export const LogoutButton = styled.button`
  display: flex;
  gap: 10px;
  width: 210px;
  margin: 0;
  padding: 10px 24px;
  border: 0;
  border-radius: 10px;

  cursor: pointer;

  background-color: ${PALETTE.static.white};

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.status.negative};
`;

export const Section = styled.div<{ $justifyContent?: string }>`
  display: flex;
  justify-content: ${({ $justifyContent }) =>
    $justifyContent ?? 'space-between'};
  align-items: center;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: ${PALETTE.static.white};
  ${TYPOGRAPHY.body2}
  font-weight: 500;
`;
export const SimpleCodeLabel = styled.span`
  display: inline-flex;
  margin-right: 2px;

  color: ${PALETTE.static.white};
  ${TYPOGRAPHY.label2}
`;
export const CopyButton = styled.button`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 2px 4px;
  border: none;
  border-radius: 4px;

  background-color: transparent;
  outline: none;

  transition: background-color 0.2s;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.component.heavy};
  }
`;
export const CopyLabel = styled.span`
  ${TYPOGRAPHY.label2}
  color: ${PALETTE.static.white};
  text-decoration: underline;
`;

export const CopyBadge = styled.div`
  padding: 0 4px;
  border-radius: 4px;

  background-color: #aeafb0;

  ${TYPOGRAPHY.caption1}
  color: ${PALETTE.component.sidebar};
`;
export const CopyTooltipBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  ${TYPOGRAPHY.label2}
`;
