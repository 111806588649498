import { postLogout } from '@/apis';

import { useMutation } from '@tanstack/react-query';

export const useLogoutMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  return useMutation({
    mutationFn: async () => postLogout(),
    onSuccess,
    onError,
  });
};
