import { dateFormat } from '@/utils';

import { PALETTE } from '@/themes';

import { memo } from 'react';
import { FaCircle } from 'react-icons/fa';

import * as S from './index.style';

type PopupCardDetailProps = {
  name: string;
  type: string;
  deviceLists: string[];
  createdAt: Date;
  updatedAt?: Date;
};

export const PopupCardDetail = memo(
  ({ name, type, deviceLists, createdAt, updatedAt }: PopupCardDetailProps) => {
    return (
      <S.Container>
        <S.Title>{name}</S.Title>
        <div>
          <S.Description>팝업 레이어 유형: {type}</S.Description>
          <S.Description>
            등록된 디바이스: {deviceLists.join(',')}
          </S.Description>
        </div>
        <>
          <S.Dates>
            <div>생성일: {dateFormat(new Date(createdAt), '/')}</div>
            {updatedAt && (
              <>
                <FaCircle size={4} color={PALETTE.text.caption} />
                <div>최종 수정일: {dateFormat(new Date(updatedAt), '/')}</div>
              </>
            )}
          </S.Dates>
        </>
      </S.Container>
    );
  },
);
