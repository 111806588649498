import { BASE_URL } from '@/apis';

import { useAuth } from '@/hooks';
import { useSSE } from '@/hooks/useSSE';

import { API } from '@/constants';

import { EventSourcePolyfill } from 'event-source-polyfill';
import React, {
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface SSEContextType {
  isConnected: boolean;
  initSSE: () => void;
  closeSSE: () => void;
}

export const SSEContext = createContext<SSEContextType | undefined>(undefined);

interface SSEProviderProps {
  children: ReactNode;
}

export const SSEProvider: React.FC<SSEProviderProps> = ({ children }) => {
  const sseRef = useRef<EventSourcePolyfill | null>();
  const [isConnected, setIsConnected] = useState(false);
  const setMessage = useSSE((state) => state.setMessage);

  const closeSSE = () => {
    sseRef.current?.close();
    console.info('SSE 연결 해제');
    setIsConnected(false);
  };

  const initSSE = () => {
    const eventSource = new EventSourcePolyfill(`${BASE_URL}${API.SSE}`, {
      headers: {
        Authorization: `Bearer ${useAuth.getState().accessToken}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      heartbeatTimeout: 15 * 60 * 1000,
    });

    eventSource.addEventListener('open', () => {
      console.info('SSE 연결 성공');
      setIsConnected(true);
    });

    eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      setMessage(data);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventSource.addEventListener('error', async (error: any) => {
      console.error('SSE 연결 오류', error);
      if (error.status === 401 && useAuth.getState().refreshToken) {
        try {
          await useAuth.getState().updateToken();
          initSSE();
        } catch (refreshError) {
          console.error('SSE REFRESH TOKEN ERROR', refreshError);
          closeSSE();
          useAuth.getState().resetAuth();
          throw refreshError;
        }
      }
    });

    sseRef.current = eventSource;
  };

  useEffect(() => {
    initSSE();
    return () => {
      closeSSE();
    };
  }, []);

  return (
    <SSEContext.Provider value={{ isConnected, initSSE, closeSSE }}>
      {children}
    </SSEContext.Provider>
  );
};
