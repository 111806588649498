import { useCallback, useState } from 'react';

export const useFileUpload = () => {
  const [files, setFiles] = useState<File[]>([]);

  // 드래그 앤 드롭 처리
  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = [...event.dataTransfer.files];
    if (droppedFiles.length > 0) setFiles(droppedFiles);
  }, []);

  // 파일 선택 다이얼로그 띄우기
  const handleFileSelection = useCallback(() => {
    return new Promise<File[]>((resolve) => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.multiple = true;
      fileInput.accept = 'video/*,image/*';

      const uploadFile = (event: Event) => {
        const inputElement = event.target as HTMLInputElement;
        const selectedFiles = inputElement.files ? [...inputElement.files] : [];
        if (selectedFiles.length > 0) setFiles(selectedFiles);

        fileInput.removeEventListener('change', uploadFile);
        resolve(selectedFiles);
      };

      // 사용자가 파일을 선택했을 때 처리
      fileInput.addEventListener('change', uploadFile);

      // 파일 선택 다이얼로그 표시
      fileInput.click();
    });
  }, []);

  return {
    files,
    handleDrop,
    handleFileSelection,
  };
};
