import { create } from 'zustand';

type SseMessageType = {
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
};

type SseState = {
  message: SseMessageType | null;
};

type SseStore = SseState & {
  setMessage: (message: SseMessageType | null) => void;
};

const initialState: SseState = {
  message: null,
};

export const useSSE = create<SseStore>((set) => ({
  ...initialState,
  setMessage: (message: SseMessageType | null) => {
    set({ message });
  },
}));
