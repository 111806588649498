import {
  ModifyPromotionMappedToDeviceResDto,
  PromotionDatesResDto,
} from '@/types';

import { Button, useModal } from '@/components';
import { EditPromotionModal } from '@/components';

import {
  dashToSlash,
  numToWeekDay,
  paddingZero,
  promotionTypeToKor,
  timeToHHMM,
} from '@/utils';

import { PALETTE, getRandomColorFromString } from '@/themes';

import { useCallback } from 'react';
import {
  IoCalendarOutline,
  IoRepeatOutline,
  IoTimeOutline,
  IoTrashOutline,
} from 'react-icons/io5';

import * as S from './index.style';

type PromotionItemBoxProps = {
  promotion: ModifyPromotionMappedToDeviceResDto;
  deletePromotion: (promotionId: string) => void;
  editPromotion: (
    promotionId: string,
    promotion: ModifyPromotionMappedToDeviceResDto,
  ) => void;
};

export const PromotionItemBox = ({
  promotion,
  editPromotion,
  deletePromotion,
}: PromotionItemBoxProps) => {
  const editPromotionModal = useModal(() => (
    <EditPromotionModal
      promotion={promotion}
      closeModal={editPromotionModal.closeModal}
      onSubmit={editSubmit}
    />
  ));

  const editSubmit = useCallback(
    (p: ModifyPromotionMappedToDeviceResDto) => {
      editPromotion(promotion.id, p);
      editPromotionModal.closeModal();
    },
    [promotion.id],
  );

  return (
    <S.PromotionItemContainer>
      <S.PromotionItemBox
        $bgColor={getRandomColorFromString(promotion.id)}
        onClick={editPromotionModal.openModal}
      >
        <S.PromotionItemSection>
          <S.PromotionItemDate>
            <IoCalendarOutline size={18} color={PALETTE.text.caption} />
            {dashToSlash(promotion.startPeriod)}
            {promotion.endPeriod && ` - ${dashToSlash(promotion.endPeriod)}`}
          </S.PromotionItemDate>
          <S.PromotionItemTime>
            <IoTimeOutline size={18} color={PALETTE.text.caption} />
            {timeToHHMM(promotion.startTime)}
            {promotion.endTime && ` - ${timeToHHMM(promotion.endTime)}`}
          </S.PromotionItemTime>
        </S.PromotionItemSection>
        <S.PromotionItemSection>
          <S.PromotionItemRepeat>
            <IoRepeatOutline size={18} color={PALETTE.text.caption} />
            {promotionTypeToKor(promotion.type)}
            {promotion.type === 'weekly' &&
              ` - ${getWeeklyKorean(promotion.promotionDates)}`}
            {promotion.type === 'monthly' &&
              ` - ${getMonthly(promotion.promotionDates)}`}
          </S.PromotionItemRepeat>
        </S.PromotionItemSection>
      </S.PromotionItemBox>
      <Button.Svg onClick={() => deletePromotion(promotion.id)}>
        <S.DeleteButton>
          <IoTrashOutline size={14} color={PALETTE.text.caption} />
          <S.DeleteButtonText>삭제</S.DeleteButtonText>
        </S.DeleteButton>
      </Button.Svg>
    </S.PromotionItemContainer>
  );
};

const getWeeklyKorean = (promotionDates: Array<PromotionDatesResDto>) =>
  promotionDates
    .map(({ date }) => date) // date 추출
    .sort((a, b) => {
      if (a === 0) return 1; // 0을 맨 뒤로 보냄
      if (b === 0) return -1;
      return a - b; // 나머지 값을 오름차순으로 정렬
    })
    .map((v) => numToWeekDay(v))
    .join(', ');
const getMonthly = (promotionDates: Array<PromotionDatesResDto>) =>
  promotionDates
    .map(({ date }) => date) // date 추출
    .sort((a, b) => a - b) // 1~31 순으로 정렬
    .map((v) => paddingZero(v)) // 변환
    .join(', ');
