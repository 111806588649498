import { Loading, PopupCard, PopupListEmpty } from '@/components';

import { useInfinitePopupListQuery } from '@/hooks';

import { isArrEmpty } from '@/utils';

import { CenterLayout } from '@/layout';

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './index.style';

type PopupListProps = {
  search: string;
};

export const PopupList = ({ search }: PopupListProps) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage } = useInfinitePopupListQuery({
    q: search,
  });
  const popupList = data ?? [];

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      ) : isArrEmpty(popupList) ? (
        <PopupListEmpty search={search} />
      ) : (
        <S.Container>
          {popupList.map((popup, i) => (
            <PopupCard
              key={i}
              popupId={popup.id}
              name={popup.name}
              type={popup.type}
              deviceLists={popup.devices.map((d) => d.name)}
              createdAt={popup.createdAt}
              updatedAt={popup.updatedAt}
            />
          ))}
          <div ref={ref} />
        </S.Container>
      )}
    </>
  );
};
