import { PopupPosition } from '@/types';

import { DropDown } from '@/components';

import { useMemo } from 'react';

type LayerPositionSelectorProps = {
  position: PopupPosition;
  setPosition: (position: PopupPosition) => void;
  disabled?: boolean;
};

export const LayerPositionSelector = ({
  position,
  setPosition,
  disabled = false,
}: LayerPositionSelectorProps) => {
  const options = useMemo(
    () => [
      {
        label: '왼쪽 고정',
        value: PopupPosition.LEFT,
      },
      {
        label: '오른쪽 고정',
        value: PopupPosition.RIGHT,
      },
      {
        label: '이동 효과',
        value: PopupPosition.MOVE,
      },
    ],
    [],
  );
  return (
    <DropDown
      options={options}
      onSelect={(value) => setPosition(value as PopupPosition)}
      initialValue={options.find((v) => v.value === position)}
      disabled={disabled}
    />
  );
};
