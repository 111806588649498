import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Header = styled.div`
  padding-top: 0.5rem;

  ${TYPOGRAPHY.body1};
  color: ${PALETTE.text.normal};
  font-weight: bold;
`;
