import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1.125rem 1.5rem;
  gap: 1rem;
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 100px;

  background-color: ${PALETTE.line.normal};
`;
export const PlaylistsContainer = styled.div`
  max-width: 300px;
  overflow-y: auto;
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const PlaylistsScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.75rem;
`;
export const PlaylistBox = styled.div``;
export const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;

  .react-calendar {
    position: relative;
    width: 300px;
    border: 0;

    background: none;

    & abbr {
      text-decoration: none;
    }
  }

  .react-calendar button {
    margin: 0;

    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: 0.125rem;
  }

  .react-calendar__navigation button {
    flex-grow: unset !important;
    min-width: unset;

    border-radius: 4px;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button
    ~ .react-calendar__navigation__next-button {
    margin-left: 0.75rem;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid ${PALETTE.line.normal};

    background: ${PALETTE.static.white};
    order: 3;

    &:disabled {
      border: 1px solid ${PALETTE.interaction.disable};

      background: ${PALETTE.static.white};

      color: ${PALETTE.text.disable};
    }

    &:enabled:focus {
      border: 1px solid ${PALETTE.line.normal};

      background: ${PALETTE.static.white};
    }

    &:enabled:hover {
      border: 1px solid #d1d2d4;

      background-color: #eceded;

      color: ${PALETTE.static.black};
    }
  }

  .react-calendar__navigation__label {
    order: 1;
    margin-right: auto !important;

    &:disabled {
      background: none;

      & * {
        color: ${PALETTE.text.normal};
      }
    }
  }

  .react-calendar__navigation__label__labelText {
    padding: 0.625rem;

    ${TYPOGRAPHY.title4}
    font-weight: 700;
  }

  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }

  .react-calendar__year-view__months__month {
    padding: 15px 32px;
    border-radius: 10px;

    ${TYPOGRAPHY.body2}
    font-weight: 500;
  }

  .react-calendar__year-view__months__month:enabled:hover,
  .react-calendar__year-view__months__month:enabled:focus {
    background-color: ${PALETTE.primary.light.normal};
  }

  .react-calendar__year-view__months__month.react-calendar__tile--active {
    background-color: ${PALETTE.primary.light.normal};

    color: ${PALETTE.text.normal};

    &:enabled:hover {
      background-color: ${PALETTE.primary.light.hover};

      color: ${PALETTE.text.normal};
    }
  }

  .react-calendar__month-view__days {
    grid-row-gap: 6px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${PALETTE.text.normal};
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 1.125rem;
  }

  .react-calendar__month-view__weekdays__weekday {
    ${TYPOGRAPHY.body2}
    font-weight: 500;
  }

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__tile--active {
    background: ${PALETTE.static.white};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    color: ${PALETTE.static.white};
  }

  .react-calendar__tile--hasActive {
    background-color: ${PALETTE.primary.normal};

    color: ${PALETTE.static.white};

    &:enabled:hover,
    &:enabled:focus {
      background-color: ${PALETTE.primary.hover};
    }
  }

  .react-calendar__month-view__days__day {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 44px;
    padding: 0;
    gap: 0.125rem;

    & > abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 100px;
    }
  }

  .react-calendar__month-view__days__day {
    background: none;

    color: ${PALETTE.text.caption};
    ${TYPOGRAPHY.body2}
  }

  .react-calendar__month-view__days__day:not(
      .react-calendar__tile--range,
      .react-calendar__tile--active
    ):hover {
    background: none;
  }

  .react-calendar__month-view__days__day.react-calendar__tile--active {
    color: ${PALETTE.static.white};

    & > abbr {
      background-color: ${PALETTE.static.black};
    }
  }

  .react-calendar__month-view__days__day.react-calendar__tile--active:enabled:hover
    > abbr {
    background-color: ${PALETTE.text.normal};

    color: ${PALETTE.static.white};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: none;
  }
`;
export const TileCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${TYPOGRAPHY.caption1}
  color: ${PALETTE.component.dimmer};
`;
