import { StoreItemResDto } from '@/apis';

import { DeleteModal, Tooltip, useModal } from '@/components';

import { useDeleteStoreMutation } from '@/hooks';

import { API_ERROR_CODE } from '@/constants';

import { PALETTE } from '@/themes';

import { IoPencil, IoTrash } from 'react-icons/io5';

import { ModifyStoreModal, MoveToDevicePageModal } from '../../modal';
import * as S from './index.style';

type TableTooltipProps = {
  info: StoreItemResDto;
  registered: Set<string>;
};

export const TableTooltip = ({ info, registered }: TableTooltipProps) => {
  const moveToModal = useModal(() => (
    <MoveToDevicePageModal closeModal={moveToModal.closeModal} />
  ));

  const deleteModal = useModal(() => (
    <DeleteModal
      onSubmit={() => deleteStore(info.id)}
      closeModal={deleteModal.closeModal}
    />
  ));

  const modifyStoreModal = useModal(() => (
    <ModifyStoreModal
      info={info}
      registered={registered}
      closeModal={modifyStoreModal.closeModal}
    />
  ));

  const { mutate: deleteStore } = useDeleteStoreMutation({
    onSuccess: deleteModal.closeModal,
    onError: (error) => {
      deleteModal.closeModal();
      if (error.response?.data.errorCode === API_ERROR_CODE.INUSE_STORE) {
        moveToModal.openModal();
      }
    },
  });

  return (
    <S.LastTdContainer>
      <S.TdTooltip>
        <Tooltip
          itemList={[
            {
              name: '수정하기',
              icon: <IoPencil />,
              color: PALETTE.text.normal,
              handle: modifyStoreModal.openModal,
            },
            {
              name: '삭제하기',
              icon: <IoTrash />,
              color: PALETTE.status.negative,
              handle: deleteModal.openModal,
            },
          ]}
        />
      </S.TdTooltip>
    </S.LastTdContainer>
  );
};
