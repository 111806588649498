import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 3.75rem;
  padding: 0.625rem 0.875rem;
  border: 1px solid ${PALETTE.line.normal};
  border-radius: 6px;

  background-color: ${PALETTE.static.white};

  transition: border-color 0.2s;

  &:active {
    border-color: ${PALETTE.static.black};
  }

  &:focus {
    border-color: ${PALETTE.static.black};
  }
`;
export const Input = styled.textarea<{ $countLengthEnable: boolean }>`
  width: 100%;
  height: 100%;
  border: none;

  outline: none;
  resize: none;

  ${TYPOGRAPHY.body2};

  &::placeholder {
    color: ${PALETTE.text.placeholder};
  }
`;
export const CountLength = styled.div`
  align-self: flex-end;

  ${TYPOGRAPHY.label2}
  color: ${PALETTE.text.caption};
  white-space: nowrap;
`;
