import {
  FileAbortBody,
  FileCompleteBody,
  FileCompleteResponse,
  FileInitiateBody,
  FileInitiateResponse,
  FilePreSignBody,
  FilePreSignResponse,
  instance,
} from '@/apis';

import { API } from '@/constants';

import { AxiosResponse } from 'axios';

/** 파일 업로드 시작 요청 */
export const fileInitiate = async ({ type, name }: FileInitiateBody) => {
  const { data } = await instance.post<
    FileInitiateResponse,
    AxiosResponse<FileInitiateResponse>,
    FileInitiateBody
  >(API.FILE_INITIATE, { type, name });
  return data;
};

/** 콘텐츠 부분 업로드를 위한 S3 URL 요청 */
export const filePreSign = async ({
  partNumber,
  uploadId,
}: FilePreSignBody) => {
  const { data } = await instance.post<
    FilePreSignResponse,
    AxiosResponse<FilePreSignResponse>,
    FilePreSignBody
  >(API.FILE_PRESIGN, {
    partNumber,
    uploadId,
  });
  return data;
};

/** 콘텐츠 업로드 완료 요청 */
export const fileComplete = async ({ parts, uploadId }: FileCompleteBody) => {
  const { data } = await instance.post<
    FileCompleteResponse,
    AxiosResponse<FileCompleteResponse>,
    FileCompleteBody
  >(API.FILE_COMPLETE, {
    parts,
    uploadId,
  });
  return data;
};

/** 콘텐츠 업로드 완료 요청 */
export const fileAbort = async ({ uploadId }: FileAbortBody) => {
  await instance.post(API.FILE_ABORT, {
    uploadId,
  });
};
