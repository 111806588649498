export const ZINDEX = {
  overlay: 100_000,
  popover: 50_000,
  modal: {
    content: 10_000,
    backdrop: 9999,
  },
  toast: {
    content: 999,
  },
  menu: {
    content: 100,
    backdrop: 99,
  },
  tooltip: {
    content: 50,
  },
  header: {
    content: 10,
  },
  contentOverlay: {
    overlay5: 5,
    overlay4: 4,
    overlay3: 3,
    overlay2: 2,
    overlay1: 1,
  },
  bottom: {
    default: -1,
  },
} as const;
