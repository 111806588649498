import { Button, ColorSelect } from '@/components';

import { BOXSHADOW, ZINDEX } from '@/themes';

import { useEffect, useState } from 'react';
import { useColor } from 'react-color-palette';
import { Popover } from 'react-tiny-popover';

type LayerDescriptionColorSelectorProps = {
  descriptionColor: string;
  setDescriptionColor: (descriptionColor: string) => void;
  disabled?: boolean;
};

export const LayerDescriptionColorSelector = ({
  descriptionColor,
  setDescriptionColor,
  disabled = false,
}: LayerDescriptionColorSelectorProps) => {
  const [color, setColor] = useColor(descriptionColor);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setDescriptionColor(color.hex);
  }, [color]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      padding={10}
      positions={['left', 'top']}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <ColorSelect
          color={color}
          onChange={setColor}
          hideInput={['hsv', 'rgb']}
          closeColorSelect={() => setIsPopoverOpen(false)}
          containerStyle={{ boxShadow: BOXSHADOW.toolTip }}
        />
      }
      containerStyle={{
        zIndex: `${ZINDEX.overlay}`,
      }}
    >
      <Button
        disabled={disabled}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        색상 선택
      </Button>
    </Popover>
  );
};
