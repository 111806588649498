import { PALETTE, ZINDEX } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  z-index: ${ZINDEX.modal.backdrop};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: ${PALETTE.component.dimmer};
`;
export const ModalBox = styled.div`
  min-width: 400px;

  border-radius: 0.375rem;

  background-color: ${PALETTE.static.white};
`;
