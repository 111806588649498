import { ApiErrorResponse } from '@/types';

import { PopupDataType, postPopup } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const useAddPopupMutation = ({
  popupId,
  onMutate,
  onError,
  onSuccess,
}: {
  popupId: string;
  onMutate?: () => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
  onSuccess?: (id: string) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PopupDataType) => postPopup(data),
    onMutate: onMutate,
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
    onSuccess: (res: AxiosResponse<{ id: string }>) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.POPUPLAYER_ID(popupId),
      });
      onSuccess?.(res.data.id);
    },
  });
};
