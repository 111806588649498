import { ExtendedFile } from '@/types';

import { Progress } from '@/components';

import { useFileManager } from '@/hooks';

import { formatBytes } from '@/utils';

import { useEffect } from 'react';

import { FilePreview } from '../filePreview';
import * as S from './index.style';

type FileCardProps = ExtendedFile;

export const FileCard = ({
  id,
  file,
  uploadStatus,
  uploadProgress,
}: FileCardProps) => {
  const removeFile = useFileManager((state) => state.removeFile);

  useEffect(() => {
    return () => {
      removeFile(id);
    };
  }, []);

  return (
    <S.Container>
      <S.Wrapper>
        {isFileWithPreview(file) ? <FilePreview file={file} /> : null}
        <S.ProgressWithInfo>
          <S.NameWithSize>
            <S.FileName>{file.name}</S.FileName>
            <S.FileSize>{formatBytes(file.size)}</S.FileSize>
          </S.NameWithSize>
          <Progress value={uploadProgress} status={uploadStatus} />
        </S.ProgressWithInfo>
      </S.Wrapper>
    </S.Container>
  );
};

function isFileWithPreview(file: File): file is File & { preview: string } {
  return 'preview' in file && typeof file.preview === 'string';
}
