import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.text.normal};
`;

export const DropdownHeader = styled.div<{
  $isOpen: boolean;
  $disabled: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0.875rem;
  border: 1px solid ${PALETTE.line.normal};
  border-radius: 6px;
  border-color: ${(props) =>
    props.$isOpen ? PALETTE.static.black : PALETTE.line.normal};

  background-color: ${({ $disabled }) =>
    $disabled ? PALETTE.interaction.disable : PALETTE.static.white};
  cursor: pointer;
`;
export const Text = styled.div<{ $disabled: boolean }>`
  overflow: hidden;

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${PALETTE.text.disable};
    `};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const DropdownIcon = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.875rem;

  transition: transform 0.3s;
  transform: ${(props) => (props.$isOpen ? 'rotate(0)' : 'rotate(180deg)')};
`;

export const DropdownList = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  left: 0;
  z-index: 1;
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
  overflow-y: auto;
  box-shadow: ${BOXSHADOW.toolTip};
`;

export const DropdownItem = styled.div<{ $isSelected: boolean }>`
  padding: 0.5rem 0.875rem;
  cursor: pointer;

  ${(props) =>
    props.$isSelected
      ? css`
          background-color: ${PALETTE.primary.light.normal};

          color: ${PALETTE.primary.normal};
          font-weight: 700;
        `
      : css`
          background-color: ${PALETTE.static.white};

          color: ${PALETTE.text.normal};
        `};

  &:hover {
    background-color: #ededed;
  }
`;
