import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import { IoEllipsisVertical } from 'react-icons/io5';
import styled from 'styled-components';

export const TooltipListItem = styled.button<{ $color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 14px;
  border: none;

  background-color: transparent;

  color: ${({ $color }) => $color};
  font-weight: 300;
  ${TYPOGRAPHY.body2};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #f3f4f4;
  }
`;

export const TooltipListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
  box-shadow: ${BOXSHADOW.toolTip};
`;

export const TooltipIcon = styled(IoEllipsisVertical)`
  width: 24px;
  height: 24px;
`;
