import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  width: 25rem;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`;
export const TimeRangeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
export const TimeRange = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 0.5rem;
`;
export const TimeRangeErrorMsg = styled.div`
  padding: 0.125rem 0;

  color: ${PALETTE.status.negative};
  ${TYPOGRAPHY.caption1};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;
export const SectionTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
export const SectionTitle = styled.div`
  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2}
  font-weight: 700;
`;
export const SectionDec = styled.div`
  ${TYPOGRAPHY.caption1}
  color: ${PALETTE.text.caption};
`;
