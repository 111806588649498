import { DeviceDetailResDto } from '@/apis';

import { CheckBoxItem, CheckboxList, Loading } from '@/components';

import { useInfiniteDeviceListQuery } from '@/hooks';

import { CenterLayout } from '@/layout';

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './index.style';

type DeviceCheckboxListProps = {
  search: string;
  setSelected: React.Dispatch<React.SetStateAction<DeviceDetailResDto[]>>;
  onFocus?: (device: DeviceDetailResDto) => void;
  onChecked?: (device: DeviceDetailResDto) => boolean;
  disabled?: (device: DeviceDetailResDto) => boolean;
};

export const DeviceCheckboxList = ({
  search,
  setSelected,
  onFocus,
  onChecked,
  disabled,
}: DeviceCheckboxListProps) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage, error } = useInfiniteDeviceListQuery({
    q: search,
  });
  const deviceList = data ?? [];

  const onSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: CheckBoxItem,
  ) => {
    const device = deviceList.find((v) => v.id === item.value);
    if (device) {
      setSelected((prev) =>
        event.target.checked
          ? [...prev, device]
          : prev.filter((v) => v.id !== device.id),
      );
    }
  };

  const focusHandler = (deviceId: string) => {
    const device = deviceList.find((value) => value.id === deviceId);
    if (device) {
      onFocus?.(device);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      onFocus?.(deviceList[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!error && inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      ) : (
        <S.Container>
          <CheckboxList
            title={'디바이스 이름'}
            items={deviceList.map((v) => {
              return {
                name: v.name,
                value: v.id,
                checked: !!onChecked?.(v),
                disabled: !!disabled?.(v),
              };
            })}
            infiniteRef={ref}
            onSelect={onSelect}
            onFocus={focusHandler}
          />
        </S.Container>
      )}
    </>
  );
};
