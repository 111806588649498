import { DeviceDetailResDto } from '@/apis';

import {
  Button,
  DeviceCheckboxList,
  DeviceConflictModal,
  DeviceContentsPreviewCard,
  DeviceInfoCard,
  DeviceRegisteredModal,
  Modal,
  PillsGroup,
  Search,
  useModal,
} from '@/components';

import { useAddPopupToDeviceMutation } from '@/hooks';

import { isArrEmpty } from '@/utils';

import { PATH } from '@/constants';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type PopupDeviceRegistrationModalProps = {
  popupId: string;
  closeModal: () => void;
};

export const PopupDeviceRegistrationModal = ({
  popupId,
  closeModal,
}: PopupDeviceRegistrationModalProps) => {
  const navigate = useNavigate();
  const [deviceSearch, onDeviceSearch] = useState('');
  const [focusDevice, setFocusDevice] = useState<DeviceDetailResDto>();
  const [selectedDevice, setSelectedDevice] = useState<
    Array<DeviceDetailResDto>
  >([]);
  const { mutate: addPopupToDevices } = useAddPopupToDeviceMutation();
  const deviceRegisteredModal = useModal(() => (
    <DeviceRegisteredModal
      closeModal={deviceRegisteredModal.closeModal}
      onSubmit={() => {
        deviceRegisteredModal.closeModal();
        navigate(PATH.DEVICE_PAGE);
      }}
    />
  ));
  const deviceConflictModal = useModal(() => (
    <DeviceConflictModal
      closeModal={deviceConflictModal.closeModal}
      onSubmit={() => {
        popupRegister();
      }}
    />
  ));

  const popupRegister = () => {
    addPopupToDevices(
      {
        deviceIds: selectedDevice.map((v) => v.id),
        popupId,
      },
      {
        onSuccess: () => {
          closeModal();
          deviceConflictModal.closeModal();
          deviceRegisteredModal.openModal();
        },
      },
    );
  };

  const removeSelectedDevice = (value: string) => {
    setSelectedDevice((prev) => prev.filter((v) => v.id !== value));
  };
  const onChecked = (device: DeviceDetailResDto) => {
    return selectedDevice.some((v) => v.id === device.id);
  };
  const registred = () => {
    const isPopupExist = selectedDevice.some((v) => !!v.popup?.id);
    if (isPopupExist) {
      deviceConflictModal.openModal();
      return;
    } else {
      popupRegister();
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header isBorderBottom>
        <S.HeaderContainer>
          <S.HeaderTitle>디바이스에 등록하기</S.HeaderTitle>
          <Search
            placeHolder={'디바이스 이름을 검색해 보세요.'}
            onSearch={onDeviceSearch}
          />
        </S.HeaderContainer>
      </Modal.Header>
      <S.ContentContainer>
        {!isArrEmpty(selectedDevice) && (
          <PillsGroup
            pills={selectedDevice.map((v) => ({
              label: v.name,
              iconClick: () => removeSelectedDevice(v.id),
            }))}
          />
        )}
        <S.ContentWrapper>
          <DeviceCheckboxList
            search={deviceSearch}
            setSelected={setSelectedDevice}
            onChecked={onChecked}
            onFocus={setFocusDevice}
          />

          <S.MainContent>
            {focusDevice && (
              <DeviceInfoCard
                name={focusDevice?.name}
                model={focusDevice?.model}
                store={focusDevice?.store}
                status={focusDevice?.status}
                popup={
                  focusDevice?.popup === null ? undefined : focusDevice?.popup
                }
                promotions={focusDevice?.promotions}
              />
            )}
            {focusDevice?.devicePlayLists && (
              <DeviceContentsPreviewCard
                playlist={focusDevice?.devicePlayLists ?? []}
              />
            )}
          </S.MainContent>
        </S.ContentWrapper>
      </S.ContentContainer>
      <Modal.Footer isBorderTop>
        <Button
          type="button"
          outlined
          colorType="secondary"
          onClick={closeModal}
        >
          취소
        </Button>
        <Button disabled={isArrEmpty(selectedDevice)} onClick={registred}>
          등록하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
