import { PageReqOptionDto, PlayContentReqDto, PlayListEntity } from '@/types';

import {
  PlayListAddReqDto,
  PlayListByIdResDto,
  PlayListsResDto,
  instance,
} from '@/apis';

import { API } from '@/constants';

type PlayListId = PlayListEntity['id'];

/**
 * 재생 목록 리스트 보기(paginated)
 *
 * @param pageOption -
 *
 *   - "page": "number", -> 현재 페이지
 *   - "take": "number", -> 페이지네이션 범위
 *   - "q": "string" -> 검색할 이름의 일부분
 *
 * @returns 200: 페이지, 검색어에 따른 재생 목록 리스트를 반환합니다(PopupListResDto)
 */
export const getPlayLists = async (pageOption?: PageReqOptionDto) => {
  const { data } = await instance.get<PlayListsResDto>(
    pageOption ? API.PLAYLIST_PAGE(pageOption) : API.PLAYLIST,
  );
  return data;
};

/**
 * 단일 재생 목록 정보 보기
 *
 * @param id 재생 목록 아이디
 * @returns 200: 해당 재생 목록의 상세 정보(PlayListByIdResDto)
 */
export const getPlayListById = async (id: PlayListId) => {
  const { data } = await instance.get<PlayListByIdResDto>(API.PLAYLIST_ID(id));
  return data;
};

/**
 * 새 재생목록 추가
 *
 * @param body - Name: "string" -> 등록할 팝업 레이어 이름
 * @returns 200: 요청 실행 성공
 */
export const postPlayList = async (body: PlayListAddReqDto) => {
  return await instance.post(API.PLAYLIST, body);
};

/**
 * /** 재생 목록 수정
 *
 * @param id 수정할 재생 목록 아이디
 * @param name 수정할 재생 목록 이름
 * @param playContents 수정할 재생 목록 컨텐츠 리스트
 * @returns 200: 요청 실행 성공
 */
export const putPlayListById = async ({
  id,
  name,
  playContents,
}: {
  id: PlayListId;
  name: string;
  playContents: PlayContentReqDto[];
}) => {
  return await instance.put(API.PLAYLIST_ID(id), {
    name,
    playContents,
  });
};

/**
 * 재생 목록 삭제
 *
 * @param id 삭제할 재생 목록 아이디
 * @returns 200: 요청 실행 성공
 */
export const deletePlayListById = async (id: PlayListId) => {
  const { data } = await instance.delete(API.PLAYLIST_ID(id));
  return data;
};

/**
 * 플레이리스트 이름 중복. 체크
 *
 * @param id 디바이스 아이디
 * @param name 체크할 이름
 * @returns 이름이 중복인지 boolean 값으로 반환합니다 ({"isNameDuplicated": true})
 */
export const checkDuplicatedPlaylistName = async ({
  id,
  name,
}: {
  id?: PlayListId;
  name: string;
}) => {
  const { data } = await instance.get<{ isNameDuplicated: boolean }>(
    API.PLAYLIST_CHECK_NAME,
    { params: { id, name } },
  );
  return data.isNameDuplicated;
};
