import { PromotionType } from '@/types';

import { DevicePlayListType } from '@/pages/deviceModify';

import { getRandomColorFromString } from '@/themes';

import {
  format,
  getDaysInMonth,
  isAfter,
  isBefore,
  lastDayOfMonth,
} from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import Calendar, { OnArgs } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

import * as S from './index.style';
import { PromotionBox } from './promotionBox';

type PromotionCalendarProps = {
  playlists: DevicePlayListType[];
};

export const PromotionCalendar = ({ playlists }: PromotionCalendarProps) => {
  const today = useMemo(() => new Date(), []);
  const [activeStartDate, setActiveStartDate] = useState(today);
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);

  // 프로모션 목록을 메모이제이션
  const promotionList = useMemo(() => mapPromotions(playlists), [playlists]);

  // 현재 월에 해당하는 날짜별 프로모션을 메모이제이션
  const promotionByDateMap = useMemo(() => {
    const daysInMonth = getDaysInMonth(activeStartDate);
    const map = new Map<string, string[]>();

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth(),
        day,
      );
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const promotionsForDate = getPromotionsForDate(
        currentDate,
        promotionList,
      );
      map.set(formattedDate, promotionsForDate);
    }

    return map;
  }, [activeStartDate, promotionList]);

  // 날짜 선택 시 핸들러
  const handleDateChange = useCallback(
    (date: Date | null | [Date | null, Date | null]) => {
      if (!Array.isArray(date)) setSelectedDate(date);
    },
    [],
  );

  // 달력에서 시작 날짜 변경 시 핸들러
  const handleActiveStartDateChange = useCallback(
    ({ activeStartDate: activeDate }: OnArgs) => {
      if (activeDate) setActiveStartDate(activeDate);
    },
    [],
  );

  // 선택된 날짜에 대한 프로모션 목록을 렌더링
  const renderPromotionList = useMemo(() => {
    const selectedFormattedDate = format(selectedDate || today, 'yyyy-MM-dd');

    // 해당 날짜에 대한 프로모션 목록 가져오기 및 필터링, 매핑을 한 번에 처리
    const promotionsForSelectedDate =
      promotionByDateMap.get(selectedFormattedDate)?.reduce(
        (acc, promotionId) => {
          const promotion = promotionList.find(
            (p) => p.promotionId === promotionId,
          );
          if (promotion) acc.push(promotion); // null이 아닌 경우만 추가
          return acc;
        },
        [] as (typeof promotionList)[number][],
      ) || [];

    // 프로모션을 시작 날짜 및 시간을 기준으로 정렬
    const sortedPromotions = promotionsForSelectedDate.sort((a, b) => {
      const startDateA = new Date(a.startPeriod).getTime();
      const startDateB = new Date(b.startPeriod).getTime();

      // 시작 날짜를 기준으로 먼저 정렬
      if (startDateA !== startDateB) {
        return startDateA - startDateB;
      }

      // 시작 날짜가 같으면 시작 시간을 기준으로 정렬 (문자열 비교로 변경하여 최적화)
      if (a.startTime !== b.startTime) {
        return a.startTime.localeCompare(b.startTime); // 시간 비교를 문자열로
      }

      return 0; // 날짜와 시간이 모두 같으면 변경 없음
    });

    // 정렬된 프로모션 목록을 캐싱한 상태로 반환
    return sortedPromotions.map((promotion) => (
      <PromotionBox
        key={promotion.promotionId}
        title={promotion.playlistName}
        color={getRandomColorFromString(promotion.promotionId)}
        startPeriod={promotion.startPeriod}
        endPeriod={promotion.endPeriod}
        startTime={promotion.startTime}
        endTime={promotion.endTime}
      />
    ));
  }, [selectedDate, promotionByDateMap, promotionList, today]);

  return (
    <S.Container>
      <S.CalendarContainer>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          defaultValue={selectedDate}
          formatDay={(_, date) => format(date, 'd')}
          minDetail="month"
          maxDetail="month"
          maxDate={lastDayOfMonth(today)}
          prevLabel={<IoChevronBackOutline size={20} />}
          nextLabel={<IoChevronForwardOutline size={20} />}
          onActiveStartDateChange={handleActiveStartDateChange}
          tileContent={({ date }) => {
            const formattedDate = format(date, 'yyyy-MM-dd');
            const promotionsForDate =
              promotionByDateMap.get(formattedDate) || [];

            return (
              <React.Fragment key={formattedDate}>
                {promotionsForDate.length > 0 && (
                  <S.TileCount>{promotionsForDate.length}</S.TileCount>
                )}
              </React.Fragment>
            );
          }}
        />
      </S.CalendarContainer>
      <S.Divider />
      <S.PlaylistsContainer>
        <S.PlaylistsScrollArea>{renderPromotionList}</S.PlaylistsScrollArea>
      </S.PlaylistsContainer>
    </S.Container>
  );
};

// 특정 날짜에 해당하는 프로모션 목록을 반환하는 함수
const getPromotionsForDate = (
  date: Date,
  promotionList: ReturnType<typeof mapPromotions>,
): string[] => {
  return promotionList
    .filter((promotion) => {
      const startDate = new Date(promotion.startPeriod);
      const endDate = promotion.endPeriod
        ? new Date(promotion.endPeriod)
        : null;

      if (isAfter(date, startDate) && (!endDate || isBefore(date, endDate))) {
        // 프로모션 유형에 따라 날짜를 검사
        if (promotion.type === PromotionType.WEEKLY) {
          return promotion.promotionDates.includes(date.getDay());
        } else if (promotion.type === PromotionType.MONTHLY) {
          return promotion.promotionDates.includes(date.getDate());
        } else {
          return true;
        }
      }
      return false;
    })
    .map((p) => p.promotionId);
};

// 플레이리스트에서 필요한 프로모션 데이터를 매핑하는 함수
const mapPromotions = (playlists: DevicePlayListType[]) => {
  return playlists
    .filter((v) => !v.isDefault)
    .flatMap((v) =>
      v.promotions.map((p) => ({
        playlistId: v.playList.id,
        playlistName: v.playList.name,
        promotionId: p.id,
        type: p.type,
        promotionDates: p.promotionDates.map((d) => d.date),
        startPeriod: p.startPeriod,
        endPeriod: p.endPeriod,
        startTime: p.startTime,
        endTime: p.endTime,
      })),
    );
};
