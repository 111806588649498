export enum PopupType {
  QR = 'qr',
  IMAGE = 'image',
}

export enum PopupPosition {
  LEFT = 'left',
  RIGHT = 'right',
  MOVE = 'move',
}
