import { createGlobalStyle } from 'styled-components';

import SUITFONT from '/fonts/SUIT-Variable.ttf';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: SUIT;
        font-style: normal;
        src: url(${SUITFONT}) format('truetype');
    }

    * {
        margin: 0;
        padding: 0;

        font-family: SUIT;
        box-sizing: border-box;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        appearance: textfield;
    }
`;
