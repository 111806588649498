import { ApiErrorResponse } from '@/types';

import { deleteStoreById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useDeleteStoreMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (_error: AxiosError<ApiErrorResponse>) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids: string) => deleteStoreById(ids),
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.STORE(),
      });
    },
  });
};
