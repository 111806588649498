import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  gap: 1.5rem;
`;
export const ImageNameContainer = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.875rem;
  border: 1px solid ${PALETTE.line.normal};
  border-radius: 6px;

  background-color: ${({ $disabled }) =>
    $disabled ? PALETTE.interaction.disable : PALETTE.static.white};
  flex-grow: 1;
  min-width: 0;
`;
export const ImageName = styled.div`
  ${TYPOGRAPHY.body2}
  overflow: hidden;

  color: ${PALETTE.text.normal};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
