import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Desc = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.label2};
  font-weight: 500;
`;

export const Title = styled.div`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body1};
  font-weight: 700;
`;
