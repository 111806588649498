import { CheckPromotionType } from '@/types';

import { checkDevicePromotions } from '@/apis';

import { useMutation } from '@tanstack/react-query';

export const useCheckDevicePromotionMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  return useMutation({
    mutationFn: (promotions: CheckPromotionType[]) =>
      checkDevicePromotions(promotions),
    onSuccess: () => onSuccess?.(),
    onError: () => onError?.(),
  });
};
