import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  gap: 10px;
`;
export const ProgressWithInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
export const NameWithSize = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;
export const FileName = styled.p`
  display: -webkit-box;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.text.normal};
`;
export const FileSize = styled.p`
  ${TYPOGRAPHY.label2}
`;
