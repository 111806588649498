import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const Radio = styled.input`
  position: relative;

  flex-shrink: 0;

  width: 24px;
  height: 24px;
  border: solid 3px ${PALETTE.component.normal};
  border-radius: 100px;

  transition: all 0.03s ease-in-out;

  cursor: pointer;
  appearance: none;
  box-sizing: border-box;

  &:checked {
    border: solid 3px ${PALETTE.primary.normal};

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;

      width: 12px;
      height: 12px;
      border-radius: 100px;

      background: ${PALETTE.primary.normal};
      transform: translate(-50%, -50%);

      content: '';
    }
  }
`;

export const Label = styled.label`
  width: calc(100% - 10px);

  overflow: hidden;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.static.black};
  user-select: none;
  cursor: pointer;

  white-space: nowrap;
  text-overflow: ellipsis;
`;
