import { PlayListEntity } from '@/types';

import { getPlayListById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useQuery } from '@tanstack/react-query';

/**
 * GET /playlist/${playlistId}
 *
 * PlaylistId 플레이리스트를 조회하는 api입니다.
 *
 * 플레이리스트 상세정보를 가져오는 모달, 수정 페이지 등에서 사용됩니다.
 */
export const usePlayListQuery = ({
  playlistId,
  enabled = true,
}: {
  playlistId: PlayListEntity['id'];
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: QUERY_KEY.PLAYLIST_ID(playlistId),
    queryFn: () => getPlayListById(playlistId),
    enabled,
  });
};
