import { TextField } from '@/components';

import { ChangeEvent, FocusEvent } from 'react';

import * as S from './index.style';

export type SizeType = {
  width: number;
  height: number;
};

type SizeInfoBoxProps = {
  size: SizeType;
  setSize: React.Dispatch<React.SetStateAction<SizeType>>;
};
const regex = /[^0-9]/g;

export const SizeInfoBox = ({ size, setSize }: SizeInfoBoxProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const name = e.target.name;
    if (inputValue === '' || !Number.isNaN(Number(inputValue))) {
      setSize((prev) => ({ ...prev, [name]: inputValue }));
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replaceAll(regex, '');
    const name = e.target.name;
    if (
      inputValue === '' ||
      Number.isNaN(Number(inputValue)) ||
      Number(inputValue) < 200
    ) {
      setSize((prev) => ({ ...prev, [name]: 200 }));
    } else {
      setSize((prev) => ({ ...prev, [name]: Number(inputValue) }));
    }
  };

  return (
    <S.Container>
      <S.Wrapper>
        <S.SizeDisplay>
          W:
          <TextField
            value={size.width}
            name="width"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </S.SizeDisplay>
        <S.SizeDisplay>
          H:
          <TextField
            value={size.height}
            name="height"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </S.SizeDisplay>
      </S.Wrapper>
    </S.Container>
  );
};
