import { FileType } from '@/types';

import {
  Card,
  DragHandle,
  OrderId,
  Placeholder,
  TimePicker,
  Tooltip,
} from '@/components';

import { secondToTimeFormat } from '@/utils';

import { PALETTE } from '@/themes';

import { IoCopyOutline, IoTrash } from 'react-icons/io5';

import * as S from './index.style';

type PlaylistModifyContentCardProps = {
  orderId: OrderId;
  contentName: string;
  playTime: number;
  thumbnailUrl: string;
  type: FileType;
  setPlayTime: (time: string) => void;
  copyContent: () => void;
  deleteContent: () => void;
};

export const PlaylistModifyContentCard = ({
  orderId,
  contentName,
  playTime,
  thumbnailUrl,
  type,
  setPlayTime,
  copyContent,
  deleteContent,
}: PlaylistModifyContentCardProps) => {
  const itemList = [
    {
      name: '복사하기',
      icon: <IoCopyOutline size={24} />,
      color: PALETTE.text.normal,
      handle: copyContent,
    },
    {
      name: '삭제하기',
      icon: <IoTrash size={24} />,
      color: PALETTE.status.negative,
      handle: deleteContent,
    },
  ];
  return (
    <Card
      header={
        <S.ContentCardContainer>
          <DragHandle id={orderId} />
          <S.ContentBox>
            {thumbnailUrl ? (
              <S.Thumbnail
                src={thumbnailUrl}
                alt={`image_${contentName}`}
                onLoad={() => <Placeholder type={type} size={80} />}
                onError={() => <Placeholder type={type} size={80} />}
              />
            ) : (
              <Placeholder type={type} size={80} />
            )}
            <S.ContentWrapper>
              <S.Title>{contentName}</S.Title>
              <S.Description>
                {type === 'image' ? '이미지' : '동영상'}
              </S.Description>
              <TimePicker
                name={`playlistmodify-timepicker-${orderId}`}
                disabled={type === 'video'}
                type={'time'}
                buttonValue={secondToTimeFormat(playTime)}
                setButtonValue={(v) => setPlayTime(v || '')}
                tooltipMessage="이미지가 재생될 시간을 입력해 주세요."
              />
            </S.ContentWrapper>
          </S.ContentBox>
        </S.ContentCardContainer>
      }
      controls={[<Tooltip itemList={itemList} />]}
    />
  );
};
