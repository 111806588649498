import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const GuidePre = styled.pre`
  margin: 0;
  padding: 0;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.label2}
`;

export const GuideInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
`;

export const GuideContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;

  width: 100%;
  padding: 0.375rem 1rem;
  border-radius: 6px;

  background-color: ${PALETTE.primary.light.normal};
`;
