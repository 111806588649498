import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isBorderBottom: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1rem;
  ${({ $isBorderBottom }) =>
    $isBorderBottom &&
    css`
      border-bottom: 1px solid ${PALETTE.line.normal};
    `}
`;
export const Title = styled.div`
  ${TYPOGRAPHY.title4};
  font-weight: bold;
`;
