import { DeviceEntity } from '@/types';

import { getDeviceById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useQuery } from '@tanstack/react-query';

/**
 * GET /device/${deviceId}
 *
 * DeviceId 플레이리스트를 조회하는 api입니다.
 *
 * 플레이리스트 상세정보를 가져오는 모달, 수정 페이지 등에서 사용됩니다.
 */
export const useDeviceQuery = ({
  deviceId,
}: {
  deviceId: DeviceEntity['id'];
}) => {
  return useQuery({
    queryKey: QUERY_KEY.DEVICE_ID(deviceId),
    queryFn: () => getDeviceById(deviceId),
  });
};
