import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  background-color: ${PALETTE.background.normal};

  min-height: 30rem;

  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;

  & > div {
    position: static;
  }
`;
export const Title = styled.div`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body1}
  font-weight: 700;
`;
