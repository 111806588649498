import { stringToHash } from '@/utils';

export const PALETTE = {
  static: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    normal: '#DB3D65',
    hover: '#AF3151',
    light: {
      normal: '#FEEFF3',
      hover: '#F4C3CF',
    },
  },
  status: {
    positive: '#4F9F59',
    cautionary: '#E09100',
    negative: '#E23434',
    negative10: '#B62F2F',
  },
  text: {
    normal: '#171A1F',
    placeholder: '#999999',
    caption: '#737373',
    disable: '#8E8E8E',
  },
  interaction: {
    disable: '#E5E5E5',
    hover: {
      light: '#F3F3F3',
      dark: '#171a1f66',
    },
  },
  background: {
    normal: '#F7F7F8',
    light: '#FCFCFC',
  },
  line: {
    normal: '#70737238',
  },
  component: {
    normal: '#CECECE',
    light: '#EFEFF0',
    strong: '#939393',
    dimmer: '#1717192A',
    heavy: '#55585D',
    sidebar: '#2F3134',
  },
  grayscale: {
    gray_0: '#FFFFFF',
    gray_5: '#F1F1F166',
    gray_10: '#F1F1F1',
    gray_100: '#171A1F',
  },
} as const;

const RANDOMCOLOR = [
  '#FCEFCE',
  '#DAF4E5',
  '#E7EBFD',
  '#FFE0E0',
  '#FFD8C1',
] as const;

export const getRandomColorFromString = (str: string) =>
  RANDOMCOLOR[stringToHash(str) % RANDOMCOLOR.length];
