import { PALETTE } from '@/themes';

import styled from 'styled-components';

type BoxProps = {
  $fillcolor: string;
  $markcolor: string;
  $disabled: boolean;
};

export const ColorWrapper = styled.div`
  display: flex;
  gap: 14px;
`;
export const ColorBox = styled.div<BoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;
  border: ${(props) =>
    props.$fillcolor === PALETTE.static.white
      ? '1px solid rgba(125, 131, 152, 0.30)'
      : ''};
  border-radius: 6px;

  background-color: ${(props) =>
    props.$disabled ? PALETTE.interaction.disable : props.$fillcolor};

  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;

    color: ${(props) => props.$fillcolor};
  }
`;

export const ColorButton = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  background-color: ${PALETTE.static.white};
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
