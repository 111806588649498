/**
 * 비디오 URL을 받아서 비디오의 썸네일 이미지를 생성합니다.
 *
 * @param url - 비디오 파일의 URL.
 * @returns - 생성된 썸네일 이미지의 Data URL.
 * @throws - 2D 컨텍스트를 가져올 수 없는 경우 에러를 던집니다.
 */
export async function getVideoThumbnail({
  url,
  toBase64 = false,
}: {
  url: string;
  toBase64?: boolean;
}) {
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  video.style.display = 'none';
  canvas.style.display = 'none';

  await new Promise((resolve) => {
    video.addEventListener('loadedmetadata', () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1;
    });
    video.addEventListener('seeked', resolve);
    video.src = url;
  });

  const context = canvas.getContext('2d');

  if (context) {
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    if (toBase64) {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob));
          } else {
            console.error('썸네일 만들기 실패', {
              data: {
                path: '/utils/video.ts',
                function: 'getVideoThumbnail',
              },
            });
            resolve('');
          }
        }, 'image/png');
      });
    } else {
      canvas.toDataURL('image/png');
    }
  } else {
    console.error('Unable to get 2D context', {
      path: '/utils/video.ts',
      function: 'getVideoThumbnail',
    });
    throw new Error('Unable to get 2D context');
  }
}

/**
 * 비디오 파일의 URL을 받아 비디오의 재생 시간을 반환합니다.
 *
 * @param src - 비디오 파일의 URL.
 * @returns - 비디오의 재생 시간(초)입니다.
 */
export async function getVideoDuration(src: string) {
  const video = document.createElement('video');
  let duration = '';
  await new Promise<void>((resolve) => {
    video.src = src;

    const getDuration = () => {
      video.removeEventListener('loadedmetadata', getDuration);
      duration = video.duration.toFixed(0);
      resolve();
    };
    video.addEventListener('loadedmetadata', getDuration);
  });
  return duration;
}
