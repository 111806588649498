import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const AddedContents = styled.div`
  width: fit-content;
  padding: 0 6px;
  border-radius: 4px;

  ${TYPOGRAPHY.label2}
  background-color: ${PALETTE.grayscale.gray_10};

  color: ${PALETTE.text.caption};
  font-weight: 400;
`;

export const DefaultContentsName = styled.div<{ $isOneContents: boolean }>`
  width: fit-content;
  max-width: ${({ $isOneContents }) => ($isOneContents ? 100 : 80)}%;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ContentsPlaylists = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  width: 100%;
`;

export const ContentsTdContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  padding-right: 10px;

  box-sizing: border-box;

  ${TYPOGRAPHY.body2};
  font-weight: 400;
`;
