import { Button, Modal } from '@/components';

import { useFileManager } from '@/hooks';
import { useSSE } from '@/hooks/useSSE';

import { useEffect } from 'react';

import { FileCard } from './fileCard';
import * as S from './index.style';

type UploadStateModalProps = {
  closeModal: () => void;
};
export const UploadStateModal = ({ closeModal }: UploadStateModalProps) => {
  const files = useFileManager((state) => state.files);
  const removeAll = useFileManager((state) => state.removeAll);
  const updateUploadStatus = useFileManager(
    (state) => state.updateUploadStatus,
  );
  useEffect(() => {
    const unsub = useSSE.subscribe((state) => {
      if (state.message?.event === 'COMPLETE_ENCODING') {
        const file = files.find((f) => f.uuid === state.message?.data);
        if (!file) return;
        updateUploadStatus({ id: file.id, uploadStatus: 'success' });
      }
    });
    return unsub;
  }, [files]);

  useEffect(() => {
    const allFilesUploaded =
      files.length > 0 &&
      files.every((file) => file.uploadStatus === 'success');
    if (allFilesUploaded) handleClose();
  }, [files, removeAll]);

  const handleClose = () => {
    removeAll();
    closeModal();
  };

  return (
    <Modal closeModal={handleClose}>
      <S.Container>
        <Modal.Header>파일 업로드</Modal.Header>
        <Modal.Content>
          <S.ContentsContainer>
            {files.map((file) => {
              return (
                <FileCard key={file.id} {...file} />
                // <UploadProgressBox key={file.id} {...file} />
              );
            })}
          </S.ContentsContainer>
        </Modal.Content>
        <Modal.Footer>
          <Button outlined colorType={'secondary'} onClick={handleClose}>
            취소
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
};
