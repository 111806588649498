import {
  BASE_URL,
  CheckSimultaneousResDto,
  LoginReqDto,
  LoginResDto,
  SignupReqDto,
  instance,
} from '@/apis';

import { API } from '@/constants';

import axios, { AxiosResponse } from 'axios';

/**
 * 회원가입 시 사용되는 함수
 *
 * @param body - { "loginId": "string", "password": "string", "email": "string"
 *   }
 * @returns 다음 항목을 반환합니다
 *
 *   - IsSuccess: Api 성공 여부,
 *   - IsInvalidId: 이미 존재하거나(errorCode: 10_101) 올바르지 않은 양식의 Id,
 */
export const postSignup = async (body: SignupReqDto) => {
  return await axios.post(`${BASE_URL}${API.SIGNUP}`, body);
};

/**
 * 로그인 전에 해당 계정으로 로그인되어 있는 디바이스가 있는지 확인하는 함수
 *
 * @param body - { "loginId": "string", "password": "string" }
 * @returns - 성공 시 : { "isSuccess": true, }
 */
export const getCheckSimultaneous = async (body: LoginReqDto) => {
  const { data } = await axios.get<CheckSimultaneousResDto>(
    `${BASE_URL}${API.CHECK(body.loginId)}`,
  );
  return data;
};

/**
 * 로그인 시 사용되는 함수
 *
 * @param body - { "loginId": "string", "password": "string" }
 * @returns - 성공 시 : { isSuccess: true, data: { accessToken: string,
 *   refreshToken: string, user : { loginId: string, code: string } } }
 *
 *   - 오류 발생 시 : { isSuccess: false, error: ErrorType, isUnregistered: true }
 */
export const postLogin = async (body: LoginReqDto) => {
  return await axios.post<LoginResDto>(`${BASE_URL}${API.LOGIN}`, body);
};

/**
 * 로그아웃 시 사용되는 함수입니다
 *
 * @param body - { "refreshToken": "string" }
 */
export const postLogout = async () => {
  return await instance.post(API.LOGOUT);
};

/**
 * 리프레시 토큰을 요청할 시 사용되는 함수
 *
 * @param refreshToken : string
 * @returns 새 토큰 적용 { "accessToken": "string", "refreshToken": "string" }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let refreshPromise: Promise<AxiosResponse<any, any>> | null = null;
export const refreshAccessToken = async (refreshToken: string) => {
  if (refreshPromise === null) {
    const promise = axios.post(`${BASE_URL}${API.REFRESH}`, undefined, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    refreshPromise = promise;
  }
  const res = await refreshPromise;
  refreshPromise = null;
  return res;
};
