import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 100%;
  height: 2.125rem;
  margin-left: 0.875rem;
  padding: 0 0.875rem;
  border-left: 1px solid #bfbfbf;

  color: ${PALETTE.text.placeholder};
  ${TYPOGRAPHY.body2}
  font-weight: 500;
`;

export const Input = styled.input`
  flex-grow: 1;

  min-width: 23.3125rem;
  border: none;

  outline: none;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2}
  font-weight: 500;

  &::placeholder {
    color: ${PALETTE.text.placeholder};
  }
`;
