import { PALETTE } from '@/themes';

import styled, { css } from 'styled-components';

// ------------------------------------------- css -------------------------------------------

const TableCheckbox = css`
  &:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding-inline: 0.75rem;

    > * {
      gap: 0;
    }
  }
`;

const TableTdDisplay = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const TableTrDisplay = css`
  display: grid;
  grid-template-columns: 60px 170px 174px auto 60px;
`;

// ------------------------------------------- component -------------------------------------------

export const TableBodyTd = styled.td`
  ${TableTdDisplay}

  border-bottom: 1px solid rgb(112 115 124 / 22%);

  ${TableCheckbox}
`;

export const TableBodyTr = styled.tr<{ $isChecked: boolean }>`
  ${TableTrDisplay}
  height: 4rem;

  > ${TableBodyTd} {
    background-color: ${({ $isChecked }) =>
      $isChecked ? PALETTE.primary.light.normal : 'transparents'};
  }

  &:hover {
    > ${TableBodyTd} {
      background-color: rgb(23 26 31 / 5%);
    }
  }
`;

export const TableHeaderTd = styled.td`
  ${TableTdDisplay}

  width: 100%;
  border-bottom: 1px solid ${PALETTE.text.caption};

  ${TableCheckbox}
`;

export const TableHeaderTr = styled.tr`
  ${TableTrDisplay}
  height: 2.875rem;
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-top: 1px solid ${PALETTE.text.caption};

  border-collapse: collapse;
`;
