import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
`;
export const Title = styled.div`
  display: inline-block;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.text.normal};
  font-weight: bold;
`;
export const Required = styled.div`
  display: inline-block;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.primary.normal};
  font-weight: 400;
`;
export const Optional = styled.div`
  display: inline-block;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.text.caption};
  font-weight: 400;
`;
