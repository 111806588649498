import { DeviceListItemResDto } from '@/apis';

import { RowSelectionState, Table, flexRender } from '@tanstack/react-table';

import * as S from './index.style';

type DeviceTableProps = {
  table: Table<DeviceListItemResDto>;
  rowSelection: RowSelectionState;
};

export const DeviceTable = ({ table, rowSelection }: DeviceTableProps) => {
  return (
    <S.TableWrapper>
      <thead>
        {table.getHeaderGroups().map((headerGruop) => (
          <S.TableHeaderTr key={headerGruop.id}>
            {headerGruop.headers.map((header) => (
              <S.TableHeaderTd key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </S.TableHeaderTd>
            ))}
          </S.TableHeaderTr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <S.TableBodyTr
            key={row.id}
            $isChecked={Object.keys(rowSelection).includes(row.id)}
          >
            {row.getVisibleCells().map((cell) => (
              <S.TableBodyTd key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </S.TableBodyTd>
            ))}
          </S.TableBodyTr>
        ))}
      </tbody>
    </S.TableWrapper>
  );
};
