import { PageMetaDto } from '@/types';

import { Button } from '@/components';

import { PALETTE } from '@/themes';

import { Table } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

import * as S from './index.style';

type PaginationType<T> = Table<T> & PageMetaDto;

export const Pagination = <T,>({
  take,
  page,
  pageCount,
  itemCount,
  hasPreviousPage,
  hasNextPage,
  ...table
}: PaginationType<T>) => {
  const { setPageIndex } = table;

  const numPages = Math.ceil(itemCount / take);
  const RANGE = 10; // 한 번에 표시할 페이지 버튼 수
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(Math.min(pageCount, numPages));
  const takeState = useRef<number>(0);

  useEffect(() => {
    if (takeState.current !== take) {
      takeState.current = take;
      adjustPageRange(page);
    }
  }, [page, take]);

  useEffect(() => {
    if (takeState.current !== take) {
      takeState.current = take;
      setPageIndex(1);
    }
  }, [take]);

  const adjustPageRange = (currentPage: number) => {
    const newStartPage = Math.max(1, currentPage - Math.floor(RANGE / 2));
    const newEndPage = Math.min(newStartPage + RANGE - 1, pageCount);

    setStartPage(
      newEndPage - newStartPage + 1 < RANGE
        ? Math.max(1, newEndPage - RANGE + 1)
        : newStartPage,
    );
    setEndPage(newEndPage);
  };

  const handleSetPage = (newPage: number) =>
    setPageIndex((prev) => (page === newPage ? prev : newPage));

  const handlePreviousPage = () =>
    handleSetPage(hasPreviousPage ? page - 1 : page);

  const handleNextPage = () => handleSetPage(hasNextPage ? page + 1 : page);

  const buttonColor = (isDisabled: boolean): string =>
    isDisabled ? ' rgba(23, 23, 25, 42%) ' : PALETTE.text.normal;

  return (
    <S.PaginationWrapper>
      <S.ArrowBtn onClick={handlePreviousPage}>
        <Button.Svg disabled={!hasPreviousPage}>
          <IoChevronBack size={24} color={buttonColor(!hasPreviousPage)} />
        </Button.Svg>
      </S.ArrowBtn>
      {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
        <S.Button
          key={startPage + i}
          onClick={() => setPageIndex(startPage + i)}
          $isfocus={page === startPage + i}
        >
          {startPage + i}
        </S.Button>
      ))}
      <S.ArrowBtn onClick={handleNextPage}>
        <Button.Svg disabled={!hasNextPage}>
          <IoChevronForward size={24} color={buttonColor(!hasNextPage)} />
        </Button.Svg>
      </S.ArrowBtn>
    </S.PaginationWrapper>
  );
};
