import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: calc(100vw - 220px);

  background-color: ${PALETTE.background.normal};
`;

export const InfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  background-color: ${PALETTE.background.light};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  box-sizing: border-box;
`;

export const PlaylistsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin-top: 0.75rem;

  gap: 1.5rem;
`;

/* --------------- info top --------------- */
export const PopupName = styled.div<{ $enable: boolean }>`
  width: 100%;
  height: 42px;
  padding: 10px 14px;
  border: solid 1px ${PALETTE.line.normal};
  border-radius: 6px;

  ${TYPOGRAPHY.body1}
  background-color: white;

  color: ${({ $enable }) =>
    $enable ? PALETTE.text.normal : PALETTE.text.caption};
`;

export const PopupLabel = styled.label`
  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2}
  font-weight: 700;
`;

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

export const InfoTopPopupButton = styled.div`
  display: grid;
  grid-template-columns: auto 4rem;
  align-items: flex-end;
  gap: 0.875rem;

  > button {
    height: 42px;

    ${TYPOGRAPHY.body1}
  }
`;

export const InfoTopInput = styled.div<{ $hasErrorMsg: boolean }>`
  display: grid;
  grid-template-columns: 1fr 9.0625rem;
  gap: 0.875rem;

  margin-bottom: ${({ $hasErrorMsg }) =>
    $hasErrorMsg ? '0.5rem' : '2.125rem'};

  > * > div > input {
    ${TYPOGRAPHY.body1}
    &:disabled {
      background-color: ${PALETTE.interaction.disable};

      color: ${PALETTE.text.disable};
    }
  }
`;

export const InfoTopContainer = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${PALETTE.line.normal};
`;
