import { Button } from '@/components';

import { PALETTE } from '@/themes';

import { CgClose } from 'react-icons/cg';

import * as S from './index.style';

export type ModalHeaderProps = {
  children?: React.ReactNode;
  closeButtonVisible?: boolean;
  isBorderBottom?: boolean;
  closeModal?: () => void;
};

export const ModalHeader = ({
  children,
  closeButtonVisible = false,
  isBorderBottom = false,
  closeModal,
}: ModalHeaderProps) => {
  return (
    <S.Container $isBorderBottom={isBorderBottom}>
      <S.Title>{children}</S.Title>
      {closeButtonVisible && (
        <Button.Svg>
          <CgClose size={24} color={PALETTE.text.normal} onClick={closeModal} />
        </Button.Svg>
      )}
    </S.Container>
  );
};
