import { Card, Popup } from '@/components';

import * as S from './index.style';

type PopupPreviewCardProps = {
  type: string;
  url: string;
  qrColor: string;
  fileUrl: string;
  description: string;
  descriptionColor: string;
};

export const PopupPreviewCard = ({
  type,
  url,
  qrColor,
  fileUrl,
  description,
  descriptionColor,
}: PopupPreviewCardProps) => {
  return (
    <Card isShadow header={<S.Title>팝업 레이어 미리보기</S.Title>}>
      <S.Container>
        <Popup
          size={{
            width: 1000,
            height: 800,
          }}
          url={type === 'qr' ? url : ''}
          position={'center'}
          qrColor={qrColor ?? ''}
          fileUrl={fileUrl ?? ''}
          description={description ?? ''}
          descriptionColor={descriptionColor ?? ''}
        />
      </S.Container>
    </Card>
  );
};
