import {
  Button,
  ButtonLoadingTypes,
  Header,
  LeaveCurrentPageModal,
  useModal,
} from '@/components';

import { PATH } from '@/constants';

import { PropsWithChildren } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as S from './index.style';

type PopupModifyLayoutProps = {
  isSaveDisabled?: boolean;
  buttonState?: ButtonLoadingTypes;
  savePopup: () => Promise<void>;
} & PropsWithChildren;

export const PopupModifyLayout = ({
  children,
  isSaveDisabled = true,
  buttonState = 'normal',
  savePopup,
}: PopupModifyLayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const popupId = params.popupId ?? '';
  const isNew = popupId === 'new';
  const title = isNew ? '팝업 레이어 추가' : '팝업 레이어 수정';
  const prevUrl = location.state?.prevUrl ?? PATH.POPUP_PAGE;

  const leavePageAlertModal = useModal(() => (
    <LeaveCurrentPageModal
      closeModal={leavePageAlertModal.closeModal}
      onSubmit={() => {
        navigate(prevUrl, { replace: true });
        leavePageAlertModal.closeModal();
      }}
    />
  ));

  return (
    <>
      <Header title={title} moveHandler={leavePageAlertModal.openModal}>
        <Button.Loading
          disabled={isSaveDisabled}
          buttonState={buttonState}
          handleSave={savePopup}
        />
      </Header>
      <S.Main>{children}</S.Main>
    </>
  );
};
