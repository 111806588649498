import { Button, Modal } from '@/components';

import { ReactNode } from 'react';

import * as S from './index.style';

type AlertModalProps = {
  title: ReactNode;
  description: ReactNode;
  submitButtonLabel: ReactNode;
  onSubmit: () => void;
  closeModal: () => void;
  closeButtonEnable?: boolean;
  closeButtonLabel?: ReactNode;
  onClose?: () => void;
};

export const AlertModal = ({
  title,
  description,
  submitButtonLabel,
  onSubmit,
  closeModal,
  closeButtonEnable = false,
  closeButtonLabel,
  onClose,
}: AlertModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <S.ContentContainer>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <S.Description>{description}</S.Description>
        </Modal.Content>
        <Modal.Footer>
          {closeButtonEnable && (
            <Button
              type="button"
              outlined
              colorType={'secondary'}
              onClick={onClose}
            >
              {closeButtonLabel}
            </Button>
          )}
          <Button onClick={onSubmit} fontWeight={700}>
            {submitButtonLabel}
          </Button>
        </Modal.Footer>
      </S.ContentContainer>
    </Modal>
  );
};
