import { BOXSHADOW, PALETTE, TYPOGRAPHY, ZINDEX } from '@/themes';

import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  z-index: ${ZINDEX.popover};
  flex-direction: column;
  align-items: stretch;
  padding: 1.5rem 1.25rem;
  border-radius: 0.375rem;

  background-color: ${PALETTE.static.white};
  box-shadow: ${BOXSHADOW.calendar};
  gap: 0.25rem;

  .react-calendar {
    position: relative;
    width: 320px;
    border: 0;
  }

  .react-calendar button {
    margin: 0;
    border: 0;

    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    min-height: 3.25rem;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button
    ~ .react-calendar__navigation__next-button {
    margin-left: 0.625rem;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid ${PALETTE.line.normal} !important;

    background-color: ${PALETTE.static.white} !important;
    order: 3;

    &:hover {
      background-color: ${PALETTE.grayscale.gray_5} !important;
    }
  }

  .react-calendar__navigation__label {
    order: 1;
    margin-right: auto !important;
  }

  .react-calendar__navigation__label__labelText {
    padding: 0.625rem;

    ${TYPOGRAPHY.title2}
    font-weight: 700;
  }

  .react-calendar__navigation button {
    flex-grow: unset !important;
    min-width: unset;

    border-radius: 8px;

    &:hover {
      background-color: ${PALETTE.primary.light.normal};
    }

    &:disabled {
      background-color: ${PALETTE.static.white};

      color: ${PALETTE.text.normal};
    }
  }

  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }

  .react-calendar__year-view__months__month {
    border-radius: 10px;
    padding: 15px 32px;

    ${TYPOGRAPHY.body2}
    font-weight: 500;
  }

  .react-calendar__year-view__months__month:enabled:hover,
  .react-calendar__year-view__months__month:enabled:focus {
    background-color: ${PALETTE.primary.light.normal};
  }

  .react-calendar__year-view__months__month.react-calendar__tile--active {
    background-color: ${PALETTE.primary.light.normal};

    color: ${PALETTE.text.normal};

    &:enabled:hover {
      background-color: ${PALETTE.primary.light.hover};

      color: ${PALETTE.text.normal};
    }
  }

  .react-calendar__month-view__days {
    grid-row-gap: 6px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${PALETTE.text.normal};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${PALETTE.text.caption} !important;
  }

  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__tile--active {
    background: ${PALETTE.static.white};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    color: ${PALETTE.static.white};
  }

  .react-calendar__tile--hasActive {
    background-color: ${PALETTE.primary.normal};

    color: ${PALETTE.static.white};

    &:enabled:hover,
    &:enabled:focus {
      background-color: ${PALETTE.primary.hover};
    }
  }

  .react-calendar__month-view__days__day {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    & > abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }
  }

  .react-calendar__month-view__days__day {
    background-color: ${PALETTE.static.white};
  }

  .react-calendar__month-view__days__day:not(
      .react-calendar__tile--range,
      .react-calendar__tile--active
    ):hover {
    background-color: ${PALETTE.static.white};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--active {
    color: ${PALETTE.static.white};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--active:enabled:hover
    > abbr {
    background-color: ${PALETTE.primary.light.hover};
  }

  .react-calendar__month-view__days__day:enabled:hover,
  .react-calendar__month-view__days__day:enabled:focus {
    & > abbr {
      background-color: ${PALETTE.primary.light.normal};

      color: ${PALETTE.text.normal};
    }
  }

  .react-calendar__month-view__days__day.react-calendar__tile--range {
    background-color: ${PALETTE.primary.light.normal};

    color: ${PALETTE.text.normal};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--rangeStart {
    background: linear-gradient(
      90deg,
      ${PALETTE.static.white} 50%,
      ${PALETTE.primary.light.normal} 50%
    );

    & > abbr {
      background-color: ${PALETTE.primary.normal} !important;

      color: ${PALETTE.static.white} !important;

      &:hover {
        background-color: ${PALETTE.primary.hover} !important;

        color: ${PALETTE.static.white} !important;
      }
    }
  }

  .react-calendar__month-view__days__day.react-calendar__tile--rangeEnd {
    background: linear-gradient(
      90deg,
      ${PALETTE.primary.light.normal} 50%,
      ${PALETTE.static.white} 50%
    );

    & > abbr {
      background-color: ${PALETTE.primary.normal} !important;

      color: ${PALETTE.static.white} !important;

      &:hover {
        background-color: ${PALETTE.primary.hover} !important;

        color: ${PALETTE.static.white} !important;
      }
    }
  }

  .react-calendar__month-view__days__day.react-calendar__tile--hoverStart.react-calendar__tile--hoverEnd,
  .react-calendar__month-view__days__day.react-calendar__tile--rangeBothEnds:not(
      .react-calendar__tile--hover
    ) {
    background: none !important;
  }

  .react-calendar__month-view__days__day.react-calendar__tile--hoverStart {
    background: linear-gradient(
      90deg,
      ${PALETTE.static.white} 50%,
      ${PALETTE.primary.light.normal} 50%
    );
  }

  .react-calendar__month-view__days__day.react-calendar__tile--hover {
    background-color: ${PALETTE.primary.light.normal};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--hoverEnd {
    background: linear-gradient(
      90deg,
      ${PALETTE.primary.light.normal} 50%,
      ${PALETTE.static.white} 50%
    );
  }
`;
export const AlertText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;

  color: ${PALETTE.primary.normal};
  ${TYPOGRAPHY.body2}
  font-weight: 700;
`;
