import { PropsWithChildren } from 'react';

import * as S from './index.style';

export const ModalLayout = ({ children, ...args }: PropsWithChildren) => {
  return (
    <S.Container $isOpen={true}>
      <S.ModalBox {...args}>{children}</S.ModalBox>
    </S.Container>
  );
};
