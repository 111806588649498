import { type IColor } from 'react-color-palette';

export const float = (value: number, decimalPlaces: number) =>
  Math.round(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;

export const isFieldHide = (
  hideInput: (keyof IColor)[] | boolean,
  field: keyof IColor,
) => (Array.isArray(hideInput) ? hideInput.includes(field) : hideInput);

export const formatRgb = ({ r, g, b, a }: IColor['rgb']) => {
  const rgb: number[] = [Math.round(r), Math.round(g), Math.round(b)];
  const alpha = float(a, 3);

  if (alpha < 1) {
    rgb.push(alpha);
  }

  return rgb.join(', ');
};

export const formatHsv = ({ h, s, v, a }: IColor['hsv']) => {
  const hsv: (string | number)[] = [
    `${Math.round(h)}°`,
    `${Math.round(s)}%`,
    `${Math.round(v)}%`,
  ];
  const alpha = float(a, 3);

  if (alpha < 1) {
    hsv.push(alpha);
  }

  return hsv.join(', ');
};
