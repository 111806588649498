import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.title3};
  font-weight: 700;
  white-space: nowrap;
`;

export const Container = styled.div<{ $isBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 127px;
  padding: 3.875rem 1.5rem 1.5rem;

  background-color: ${PALETTE.static.white};

  ${({ $isBorder }) =>
    $isBorder &&
    css`
      border-bottom: 1px solid ${PALETTE.line.normal};
    `}
`;
export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;
