import { ButtonLoadingTypes } from '@/components';

import { PALETTE } from '@/themes';

import {
  IoCheckmarkCircleOutline,
  IoEllipsisHorizontalSharp,
  IoSaveOutline,
} from 'react-icons/io5';

import { Button } from '../default';

type LoadingProps = {
  disabled: boolean;
  buttonState: ButtonLoadingTypes;
  handleSave: () => void;
};

export const Loading = ({
  disabled,
  buttonState,
  handleSave,
}: LoadingProps) => {
  return (
    <Button
      type="button"
      icon={
        buttonState === 'loading' ? (
          <div
            style={{
              flexShrink: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 17.5px',
            }}
          >
            <IoEllipsisHorizontalSharp size={24} color={PALETTE.text.disable} />
          </div>
        ) : buttonState === 'complete' ? (
          <IoCheckmarkCircleOutline size={24} color={PALETTE.text.disable} />
        ) : (
          <IoSaveOutline size={24} />
        )
      }
      size="medium"
      disabled={
        disabled || buttonState === 'loading' || buttonState === 'complete'
      }
      onClick={handleSave}
    >
      {buttonState === 'loading'
        ? ''
        : buttonState === 'complete'
          ? '완료'
          : '저장'}
    </Button>
  );
};
