import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;

  margin-top: 14px;
`;

export const TimeInput = styled.input`
  width: 42px;
  height: 36px;
  border: solid 1px ${PALETTE.line.normal};
  border-radius: 6px;

  text-align: center;
`;

export const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  > div {
    color: ${PALETTE.static.black};
    ${TYPOGRAPHY.body2}
  }
`;

export const TimeLabel = styled.label`
  ${TYPOGRAPHY.label2}
  color:${PALETTE.text.caption};
  font-weight: 700;
`;

export const TimeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

export const SettingWrapper = styled.div`
  width: 13.375rem;
  padding: 1rem 0.875rem;
  border: solid 1px ${PALETTE.line.normal};
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
`;

export const ButtonValue = styled.div`
  ${TYPOGRAPHY.body2}
  font-weight: 400;
`;

export const ButtonWrapper = styled.div<{
  $color: string;
  $disabled: boolean;
  $width: number;
  $isValid: boolean;
}>`
  position: relative;
  display: flex;
  grid-template-columns: 1.25rem auto;
  align-items: center;
  gap: 10px;

  width: ${({ $width }) => $width}px;
  height: 38px;
  padding: 10px 14px;
  border: solid 1px
    ${({ $disabled, $isValid }) =>
      !$disabled && $isValid ? PALETTE.status.negative : PALETTE.line.normal};
  border-radius: 6px;

  background-color: ${({ $disabled }) =>
    $disabled ? PALETTE.interaction.disable : PALETTE.static.white};

  box-sizing: border-box;

  * {
    color: ${({ $color }) => $color};
  }
`;
export const ToolTipText = styled.span`
  ${TYPOGRAPHY.label2}
  color: ${PALETTE.text.normal};
`;
