import { PropsWithChildren } from 'react';

import * as S from './index.style';

type PopupEditorItemProps = {
  title: React.ReactNode;
  required?: boolean;
  optional?: boolean;
} & PropsWithChildren;

export const PopupEditorItem = ({
  children,
  title,
  required = false,
  optional = false,
}: PopupEditorItemProps) => {
  return (
    <S.Container>
      <S.Title>
        {title}{' '}
        {required ? (
          <S.Required>(필수)</S.Required>
        ) : (
          optional && <S.Optional>(선택)</S.Optional>
        )}
      </S.Title>
      {children}
    </S.Container>
  );
};
