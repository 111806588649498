import { PlayContentResDto } from '@/types';

import { Button } from '@/components';

import { secondToTimeFormat } from '@/utils';

import { useState } from 'react';
import { IoEllipsisVertical } from 'react-icons/io5';
import { Popover } from 'react-tiny-popover';

import { DevicePlaylistCardMenu } from './devicePlaylistCardMenu';
import * as S from './index.style';

type DevicePlaylistCardProps = {
  isDefault?: boolean;
  playlistId: string;
  title: string;
  playTime: number;
  playContents: PlayContentResDto[];
  setDefaultPlaylist: (playlistId: string) => void;
  deletePlaylist: (playlistId: string) => void;
};

export const DevicePlaylistCard = ({
  isDefault = true,
  playlistId,
  title,
  playTime,
  playContents,
  setDefaultPlaylist,
  deletePlaylist,
}: DevicePlaylistCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.Container $isDefault={isDefault}>
      <S.InfoBox>
        <S.InfoBoxTitle>
          {isDefault && <DefaultPlaylistBadge />}
          <S.Title>{title}</S.Title>
        </S.InfoBoxTitle>
        <S.InfoBoxPlayTime>
          재생 시간: {secondToTimeFormat(playTime)}
        </S.InfoBoxPlayTime>
      </S.InfoBox>
      <S.ControlBox>
        <Popover
          isOpen={isOpen}
          padding={4}
          positions={['bottom', 'left', 'top']}
          align="end"
          onClickOutside={() => setIsOpen(false)}
          content={
            <DevicePlaylistCardMenu
              playlistId={playlistId}
              playContents={playContents}
              setDefaultPlaylist={setDefaultPlaylist}
              deletePlaylist={deletePlaylist}
              isDefault={isDefault}
              setIsOpen={setIsOpen}
            />
          }
        >
          <Button.Svg onClick={() => setIsOpen((prev) => !prev)}>
            <IoEllipsisVertical size={24} />
          </Button.Svg>
        </Popover>
      </S.ControlBox>
    </S.Container>
  );
};

export const DefaultPlaylistBadge = () => {
  return <S.Badge>기본 재생</S.Badge>;
};
