import { PALETTE } from '@/themes';

import { useCallback } from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';

import * as S from './index.style';

type DaysPickerProps = {
  value: number[];
  onChange: (value: number[]) => void;
};

const DAYS = 31;

export const DaysPicker = ({ value, onChange }: DaysPickerProps) => {
  const handleDayClick = useCallback(
    (day: number) => {
      onChange(
        value.includes(day) ? value.filter((v) => v !== day) : [...value, day],
      );
    },
    [value, onChange],
  );

  const isSelected = useCallback(
    (day: number) => {
      return value.includes(day);
    },
    [value],
  );

  return (
    <>
      <S.Container>
        {Array.from({ length: DAYS }, (_, idx) => idx + 1).map((day) => (
          <S.DayButton
            key={day}
            type="button"
            $isSelected={isSelected(day)}
            onClick={() => handleDayClick(day)}
          >
            {day}
          </S.DayButton>
        ))}
      </S.Container>
      <S.DayPickerAlertText>
        <IoAlertCircleOutline size={18} color={PALETTE.primary.normal} />
        30일과 31일이 없는 달에는 콘텐츠가 재생되지 않아요.
      </S.DayPickerAlertText>
    </>
  );
};
