import { AlertModal, Button, useModal } from '@/components';

import { useLogoutMutation } from '@/hooks';
import { useAuth } from '@/hooks';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { MouseEvent, useRef, useState } from 'react';
import {
  IoCaretDownOutline,
  IoCaretUpOutline,
  IoCheckmarkCircle,
  IoLogOutOutline,
  IoPersonCircleOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import { Tooltip } from 'react-tooltip';

import * as S from './index.style';

export const Account = () => {
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const resetAuth = useAuth((state) => state.resetAuth);
  const user = useAuth((state) => state.user);
  const tooltipTimeout = useRef<NodeJS.Timeout>();

  const { mutate: logoutMutation } = useLogoutMutation({
    onSuccess: () => {
      resetAuth();
      logoutModal.closeAllModal();
      navigate(PATH.LOGIN_PAGE);
    },
    onError: () => {
      resetAuth();
      logoutModal.closeAllModal();
      navigate(PATH.LOGIN_PAGE);
    },
  });

  const logoutModal = useModal(() => (
    <AlertModal
      title={'로그아웃 하시겠습니까?'}
      description={'변경 사항이 저장되지 않습니다.'}
      submitButtonLabel={'로그아웃 하기'}
      closeButtonLabel={'취소'}
      closeButtonEnable={true}
      onSubmit={() => {
        logoutMutation();
      }}
      onClose={logoutModal.closeModal}
      closeModal={logoutModal.closeModal}
    />
  ));

  const handleCopyClipBoard = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    clearTimeout(tooltipTimeout.current);
    try {
      await navigator.clipboard.writeText(user?.code ?? '');
      setIsTooltipOpen(true);
      tooltipTimeout.current = setTimeout(() => {
        setIsTooltipOpen(false);
        clearTimeout(tooltipTimeout.current);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    user && (
      <>
        <Popover
          isOpen={isPopoverOpen}
          positions={['top']}
          align="center"
          padding={4}
          content={
            <S.LogoutButton onClick={logoutModal.openModal}>
              <IoLogOutOutline size={24} />
              로그아웃
            </S.LogoutButton>
          }
        >
          <S.Container>
            <IoPersonCircleOutline size={24} color={PALETTE.static.white} />
            <S.Wrapper>
              <S.Section>
                <S.Name>{user.loginId ?? ''}</S.Name>
                <Button.Svg onClick={() => setIsPopoverOpen((prev) => !prev)}>
                  {isPopoverOpen ? (
                    <IoCaretDownOutline
                      size={18}
                      color={PALETTE.component.normal}
                    />
                  ) : (
                    <IoCaretUpOutline
                      size={18}
                      color={PALETTE.component.normal}
                    />
                  )}
                </Button.Svg>
              </S.Section>
              <S.Section $justifyContent="center">
                <S.SimpleCodeLabel>간편 등록 코드:</S.SimpleCodeLabel>
                <S.CopyButton id="copy-tooltip" onClick={handleCopyClipBoard}>
                  <S.CopyLabel>{user.code ?? ''}</S.CopyLabel>
                  <S.CopyBadge>복사</S.CopyBadge>
                </S.CopyButton>
              </S.Section>
            </S.Wrapper>
          </S.Container>
        </Popover>
        <Tooltip
          isOpen={isTooltipOpen}
          anchorSelect="#copy-tooltip"
          style={{
            backgroundColor: PALETTE.primary.normal,
            color: PALETTE.static.white,
            padding: '4px',
          }}
        >
          <S.CopyTooltipBox>
            <IoCheckmarkCircle size={16} color={PALETTE.static.white} />
            복사 완료
          </S.CopyTooltipBox>
        </Tooltip>
      </>
    )
  );
};
