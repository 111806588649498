import { PlaylistLayout, Playlists } from '@/components';

import { useState } from 'react';

export const PlayList = () => {
  const [search, setSearch] = useState('');

  return (
    <PlaylistLayout onSearch={setSearch}>
      <Playlists search={search} />
    </PlaylistLayout>
  );
};
