import { PlayListItemResDto } from '@/apis';

import { DevicePlayListType } from '@/pages/deviceModify';

import {
  Button,
  DeviceModiftyPlaylistCard,
  Modal,
  PillsGroup,
  PlaylistCheckboxList,
  PlaylistInfoCard,
  Search,
} from '@/components';

import { isArrEmpty } from '@/utils';

import { useState } from 'react';

import * as S from './index.style';

type AddPlaylistModalProps = {
  playlists: DevicePlayListType[];
  maxSelectCount: number;
  setPlaylists: (playlists: DevicePlayListType[]) => void;
  closeModal: () => void;
};

export const AddPlaylistModal = ({
  playlists,
  setPlaylists,
  maxSelectCount,
  closeModal,
}: AddPlaylistModalProps) => {
  const [playlistSearch, onPlaylistSearch] = useState('');
  const [selectedPlaylists, setSelectedPlaylists] = useState<
    PlayListItemResDto[]
  >([]);
  const [focusPlaylist, setFocusPlaylist] = useState<PlayListItemResDto>();

  const isMaxSelectCount = selectedPlaylists.length > maxSelectCount;

  const addOnClick = () => {
    if (!isArrEmpty(selectedPlaylists)) {
      if (isMaxSelectCount) {
        return;
      }
      setPlaylists([
        ...playlists,
        ...selectedPlaylists.map((v) => ({
          order: Math.floor(Math.random() * 10 + 100),
          isDefault: false,
          playList: v,
          promotions: [],
        })),
      ]);
      closeModal();
    }
  };

  const removeSelectedPlaylist = (value: string) => {
    setSelectedPlaylists((prev) => prev.filter((v) => v.id !== value));
  };

  const onDisabled = (playlist: PlayListItemResDto) => {
    return playlists.some((v) => v.playList.id === playlist.id);
  };
  const onChecked = (playlist: PlayListItemResDto) => {
    return selectedPlaylists.some((v) => v.id === playlist.id);
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header isBorderBottom>
        <S.HeaderContainer>
          <S.HeaderTitle>디바이스에 등록하기</S.HeaderTitle>
          <Search
            placeHolder={'디바이스 이름을 검색해 보세요.'}
            onSearch={onPlaylistSearch}
          />
        </S.HeaderContainer>
      </Modal.Header>
      <S.ContentContainer>
        {!isArrEmpty(selectedPlaylists) && (
          <PillsGroup
            pills={selectedPlaylists.map((v) => ({
              label: v.name,
              iconClick: () => removeSelectedPlaylist(v.id),
            }))}
          />
        )}
        <S.ContentWrapper>
          <PlaylistCheckboxList
            search={playlistSearch}
            setPlaylists={setSelectedPlaylists}
            maxSelectCount={maxSelectCount}
            onDisabled={onDisabled}
            onChecked={onChecked}
            onFocus={setFocusPlaylist}
          />
          <S.MainContent>
            {focusPlaylist && (
              <PlaylistInfoCard
                name={focusPlaylist.name}
                playTime={focusPlaylist.totalPlayTime}
                createdAt={focusPlaylist.createdAt}
                updatedAt={focusPlaylist.updatedAt}
              />
            )}
            {focusPlaylist?.playContents && (
              <DeviceModiftyPlaylistCard.ContentsPreview
                contents={focusPlaylist.playContents}
              />
            )}
          </S.MainContent>
        </S.ContentWrapper>
      </S.ContentContainer>
      <Modal.Footer isBorderTop>
        <Button outlined colorType="secondary" onClick={closeModal}>
          취소
        </Button>
        <Button disabled={isArrEmpty(selectedPlaylists)} onClick={addOnClick}>
          추가하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
