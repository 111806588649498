import { postCopyPopup } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCopyPopupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postCopyPopup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.POPUPLAYER_INFINITY(),
      });
    },
  });
};
