import {
  Button,
  ButtonLoadingTypes,
  Header,
  LeaveCurrentPageModal,
  useModal,
} from '@/components';

import { useFileUpload } from '@/hooks';

import { PATH } from '@/constants';

import { PropsWithChildren } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as S from './index.style';

type PlaylistModifyLayoutProps = {
  isPreviewDisabled?: boolean;
  isSaveDisabled?: boolean;
  buttonState?: ButtonLoadingTypes;
  addContents: (files: File[]) => Promise<void>;
  saveContents: () => Promise<void>;
} & PropsWithChildren;

export const PlaylistModifyLayout = ({
  children,
  isSaveDisabled = true,
  buttonState = 'normal',
  addContents,
  saveContents,
}: PlaylistModifyLayoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const playlistId = params.playlistId ?? '';
  const isNew = playlistId === 'new';
  const title = isNew ? '재생 목록 추가' : '재생 목록 수정';
  const prevUrl = location.state?.prevUrl ?? PATH.PLAYLIST_PAGE;

  const { handleFileSelection } = useFileUpload();
  const leavePageAlertModal = useModal(() => (
    <LeaveCurrentPageModal
      closeModal={leavePageAlertModal.closeModal}
      onSubmit={() => {
        navigate(prevUrl, { replace: true });
        leavePageAlertModal.closeModal();
      }}
    />
  ));

  const uploadAddContents = async () => {
    const files = await handleFileSelection();
    addContents(files);
  };

  return (
    <>
      <Header title={title} moveHandler={leavePageAlertModal.openModal}>
        <Button
          size="medium"
          icon={<IoIosAddCircleOutline />}
          colorType="secondary"
          onClick={uploadAddContents}
        >
          콘텐츠 추가
        </Button>
        <Button.Loading
          disabled={isSaveDisabled}
          buttonState={buttonState}
          handleSave={saveContents}
        />
      </Header>
      <S.Main>
        <S.ScrollArea>{children}</S.ScrollArea>
      </S.Main>
    </>
  );
};
