import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1.5rem;

  ${TYPOGRAPHY.body2};
  color: ${PALETTE.text.normal};
`;
