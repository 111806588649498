import { Loading, PlaylistCard, PlaylistEmpty } from '@/components';

import { useInfinitePlayListsQuery } from '@/hooks';

import { isArrEmpty } from '@/utils';

import { CenterLayout } from '@/layout';

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './index.style';

type PlaylistsProps = {
  search: string;
};

export const Playlists = ({ search }: PlaylistsProps) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage } = useInfinitePlayListsQuery({
    q: search,
  });
  const playlists = data ?? [];

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      ) : isArrEmpty(playlists) ? (
        <PlaylistEmpty search={search} />
      ) : (
        <S.Container>
          {playlists.map((playlist, i) => (
            <PlaylistCard
              key={i}
              search={search}
              playlistId={playlist.id}
              name={playlist.name}
              createdAt={playlist.createdAt}
              updatedAt={playlist.updatedAt}
              totalPlayTime={playlist.totalPlayTime}
              contentCount={playlist.contentCount}
              playContents={playlist.playContents}
            />
          ))}
          <div ref={ref} />
        </S.Container>
      )}
    </>
  );
};
