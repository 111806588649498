import {
  FileType,
  PageReqOptionDto,
  PopupEntity,
  PopupPosition,
  PopupType,
} from '@/types';

import { PopupByIdResDto, PopupListResDto, instance } from '@/apis';

import { API } from '@/constants';

type PopupId = PopupEntity['id'];

export type PopupDataType = {
  type: PopupType;
  name: string;
  position: PopupPosition;
  description: string;
  descriptionColor: string;
  url: string;
  qrColor: string;
  file?: PopupExisitFile | PopupNewFile | PopupMaintainFile;
};

export type PopupExisitFile = {
  id: string;
  type: FileType;
  uuid: string;
  originalName: string;
  originalUrl: string;
};

export type PopupNewFile = {
  type: FileType;
  uuid: string;
  originalName: string;
  originalUrl: string;
};

export type PopupMaintainFile = {
  id: string;
};

/**
 * 팝업 레이어 리스트 보기(paginated)
 *
 * @param pageOption -
 *
 *   - "page": "number", -> 현재 페이지
 *   - "take": "number", -> 페이지네이션 범위
 *   - "q": "string" -> 검색할 이름의 일부분
 *
 * @returns 200: 페이지, 검색어에 따른 팝업 레이어 리스트를 반환합니다(PopupListResDto)
 */
export const getPopups = async (pageOption?: PageReqOptionDto) => {
  const { data } = await instance.get<PopupListResDto>(
    pageOption ? API.POPUP_PAGE(pageOption) : API.POPUP,
  );
  return data;
};

/**
 * 단일 팝업 레이어 정보 보기
 *
 * @param id 팝업 레이어 아이디
 * @returns 200: 해당 팝업 레이어의 상세 정보(PopupByIdResDto)
 */
export const getPopupById = async (id: PopupId) => {
  const { data } = await instance.get<PopupByIdResDto>(API.POPUP_ID(id));
  return data;
};

/**
 * 새 팝업 레이어 등록
 *
 * @param body - Name: "string" -> 등록할 팝업 레이어 이름
 * @returns 200: 요청 실행 성공
 */
export const postPopup = async (body: PopupDataType) => {
  return await instance.post(API.POPUP, body);
};

/**
 * 팝업 레이어 복사
 *
 * @param id 복사할 팝업 레이어 아이디
 * @returns 200: 요청 실행 성공
 */
export const postCopyPopup = async (id: PopupId) => {
  return await instance.post(API.POPUP_ID(id));
};

/**
 * 팝업 레이어 수정
 *
 * @param id 수정할 팝업 레이어 아이디
 * @returns 200: 요청 실행 성공
 */
export const putPopupById = async (id: PopupId, body: PopupDataType) => {
  return await instance.put(API.POPUP_ID(id), body);
};

/**
 * 팝업 레이어 삭제
 *
 * @param id 삭제할 팝업 레이어 아이디
 * @returns 200: 요청 실행 성공
 */
export const deletePopupById = async (id: PopupId) => {
  return await instance.delete(API.POPUP_ID(id));
};
