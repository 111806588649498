import { TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  min-width: 0;
`;

// 다른 곳에서 사용
export const QRCode = styled.div<{ $borderColor: string }>`
  padding: 0.5rem;
  border: 0.375rem solid ${({ $borderColor }) => $borderColor};

  background-color: white;
`;
export const Description = styled.div<{ $color: string }>`
  ${TYPOGRAPHY.title2}
  color: ${({ $color }) => $color};
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
`;
