import { QRCodeSVG } from 'qrcode.react';
import { CSSProperties, useMemo } from 'react';

import { SizeType } from '../sizeInfoBox';
import * as S from './index.style';

type PopupProps = {
  style?: CSSProperties;
  size: SizeType;
  url: string;
  position: string;
  qrColor: string;
  fileUrl: string;
  description: string;
  descriptionColor: string;
};

const clamp = (val: number, min: number, max: number) =>
  Math.min(Math.max(val, min), max);

const calculateWidth = (
  currentWidth: number,
  minValue: number,
  maxValue: number,
) => {
  const initialBase = 1920;

  const V = maxValue * (currentWidth / initialBase);
  const finalValue = clamp(V, minValue, maxValue);

  return finalValue;
};

export const Popup = ({
  style,
  size,
  url,
  position,
  qrColor,
  fileUrl,
  description,
  descriptionColor,
}: PopupProps) => {
  const totalWidth = useMemo(
    () => calculateWidth(size.width, 150, 320),
    [size.width],
  );
  const qrWidth = useMemo(
    () => calculateWidth(size.width, 90, 250),
    [size.width],
  );
  const qrPadding = useMemo(
    () => calculateWidth(size.width, 4, 10),
    [size.width],
  );
  const qrBorderWidth = useMemo(
    () => calculateWidth(size.width, 4, 8),
    [size.width],
  );
  const qrDescriptionGap = useMemo(
    () => calculateWidth(size.width, 14, 24),
    [size.width],
  );
  const fontSize = useMemo(
    () => calculateWidth(size.width, 20, 42),
    [size.width],
  );
  const qrEnable = useMemo(() => url.length > 0, [url]);

  return (
    <S.Container
      $width={totalWidth}
      $position={position}
      style={{ width: `${totalWidth}px`, ...style }}
    >
      <S.QRWithImage
        style={{
          width: `${totalWidth}px`,
          marginBottom: `${qrDescriptionGap}px`,
        }}
      >
        {fileUrl.length > 0 && (
          <S.Image
            src={fileUrl}
            alt={fileUrl}
            style={{
              width: `${totalWidth}px`,
              height: `${totalWidth}px`,
            }}
          />
        )}
        {qrEnable && (
          <QRCodeSVG
            value={url}
            size={qrWidth}
            id="qr-gen"
            level={'H'}
            bgColor={'white'}
            style={{
              border: `${qrBorderWidth}px solid ${qrColor}`,
              backgroundColor: 'white',
              padding: `${qrPadding}px`,
            }}
          />
        )}
      </S.QRWithImage>
      {description.length > 0 && (
        <S.Description
          style={{
            color: `${descriptionColor}`,
            fontSize: `${fontSize}px`,
          }}
        >
          {description}
        </S.Description>
      )}
    </S.Container>
  );
};
