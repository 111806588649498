import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 28.75rem;

  background-color: ${PALETTE.background.normal};
`;
