import { Button, Pill } from '@/components';

import { ReactNode, useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';

import * as S from './index.style';

type PillsGroupProps = {
  pills: Array<{
    label: ReactNode;
    iconClick?: () => void;
  }>;
};

export const PillsGroup = ({ pills }: PillsGroupProps) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const updateArrows = () => {
      setShowLeftArrow(container.scrollLeft > 24);
      setShowRightArrow(
        container.scrollLeft <
          container.scrollWidth - container.clientWidth - 24,
      );
    };

    updateArrows();

    container.addEventListener('scroll', updateArrows);
    window.addEventListener('resize', updateArrows);

    return () => {
      container.removeEventListener('scroll', updateArrows);
      window.removeEventListener('resize', updateArrows);
    };
  }, [pills]);

  const scrollLeft = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const scrollAmount = Math.min(container.scrollLeft - 1, 200);
    container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    const scrollAmount = Math.min(
      maxScrollLeft - container.scrollLeft - 1,
      200,
    );
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  return (
    <S.Container>
      {showLeftArrow && (
        <S.ArrowButtonContainer $direction={'left'}>
          <Button.Arrow
            direction={'left'}
            color={'rgba(23, 23, 25, 0.42)'}
            handler={scrollLeft}
          />
        </S.ArrowButtonContainer>
      )}
      <S.PillsContainer ref={containerRef}>
        {pills.map((v, idx) => (
          <Pill
            key={idx}
            label={v.label}
            iconClick={v.iconClick}
            maxWidth={200}
            buttonIcon={IoClose}
          />
        ))}
      </S.PillsContainer>
      {showRightArrow && (
        <S.ArrowButtonContainer $direction={'right'}>
          <Button.Arrow
            direction={'right'}
            color={'rgba(23, 23, 25, 0.42)'}
            handler={scrollRight}
          />
        </S.ArrowButtonContainer>
      )}
    </S.Container>
  );
};
