import { PromotionResDto, StatusType } from '@/types';

import { Card, DeviceIcon, Popup, Status } from '@/components';

import { useDebounce } from '@/hooks';

import { ZINDEX } from '@/themes';

import { useState } from 'react';
import { Popover } from 'react-tiny-popover';

import * as S from './index.style';

type DeviceInfoCardProps = {
  name: string;
  model: string;
  store: {
    id: string;
    name: string;
  };
  status: keyof typeof StatusType;
  popup?: {
    id: string;
    name: string;
    image: string;
    url?: string;
    qrColor: string;
    description: string;
    descriptionColor: string;
  };
  promotions: Array<PromotionResDto>;
};

export const DeviceInfoCard = ({
  name,
  model,
  store,
  status,
  popup,
  // promotions,
}: DeviceInfoCardProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const debounceIsPopover = useDebounce(isPopoverOpen, 100);

  return (
    <Card
      isShadow
      header={
        <S.Container>
          <S.Item>
            <S.Key>이름</S.Key>
            <S.Value>
              <DeviceIcon os={model} />
              {name}
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Key>매장</S.Key>
            <S.Value>{store.name}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>상태</S.Key>
            <Status type={status} />
          </S.Item>
          <S.Item>
            <S.Key>팝업 레이어</S.Key>
            <S.Value>
              {popup && (
                <Popover
                  isOpen={debounceIsPopover}
                  padding={10}
                  positions={['bottom', 'right', 'top']}
                  align="start"
                  content={
                    <S.PopupPreview
                      onMouseOver={() => setIsPopoverOpen(true)}
                      onMouseLeave={() => setIsPopoverOpen(false)}
                    >
                      <Popup
                        size={{
                          width: 150,
                          height: 800,
                        }}
                        style={{
                          position: 'static',
                          left: 'auto',
                          top: 'auto',
                          bottom: 'auto',
                        }}
                        url={popup.url ?? ''}
                        position={'left'}
                        qrColor={popup.qrColor}
                        fileUrl={popup?.image ?? ''}
                        description={popup.description}
                        descriptionColor={popup.descriptionColor}
                      />
                    </S.PopupPreview>
                  }
                  containerStyle={{
                    zIndex: `${ZINDEX.popover}`,
                  }}
                >
                  <S.PopupPreviewBtn
                    onMouseOver={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                  >
                    {popup?.name}
                  </S.PopupPreviewBtn>
                </Popover>
              )}
            </S.Value>
          </S.Item>
        </S.Container>
      }
    />
  );
};
