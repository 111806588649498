import { PageReqOptionDto } from '@/types';

import { getStores } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useQuery } from '@tanstack/react-query';

export const useStoreListQuery = (pageOption?: PageReqOptionDto) => {
  return useQuery({
    queryKey: QUERY_KEY.STORE(pageOption),
    queryFn: () => getStores(pageOption),
  });
};
