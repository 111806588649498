import { AlertModal } from '@/components';

type DeviceRegisteredModalProps = {
  closeModal: () => void;
  onSubmit: () => void;
};

export const DeviceRegisteredModal = ({
  closeModal,
  onSubmit,
}: DeviceRegisteredModalProps) => {
  return (
    <AlertModal
      title="디바이스에 등록됐어요."
      description="디바이스 메뉴로 이동할까요?"
      submitButtonLabel="디바이스 메뉴로 이동"
      closeButtonLabel="닫기"
      closeButtonEnable={true}
      onSubmit={onSubmit}
      onClose={closeModal}
      closeModal={closeModal}
    />
  );
};
