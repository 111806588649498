import { Button } from '@/components';

import { Alpha, Hue, type IColor, Saturation } from 'react-color-palette';
import 'react-color-palette/css';

import { Fields } from './field';
import { isFieldHide } from './format';
import * as S from './index.style';

type ColorSelectProps = {
  hideAlpha?: boolean;
  hideInput?: (keyof IColor)[] | boolean;
  color: IColor;
  onChange: (color: IColor) => void;
  onChangeComplete?: (color: IColor) => void;
  closeColorSelect: () => void;
  containerStyle?: React.CSSProperties;
};

export const ColorSelect = ({
  hideAlpha = false,
  hideInput = false,
  color,
  onChange,
  onChangeComplete,
  closeColorSelect,
  containerStyle,
}: ColorSelectProps) => {
  return (
    <S.Container style={containerStyle}>
      <Saturation
        height={200}
        color={color}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
      />
      <S.Body>
        <S.Section>
          <Hue
            color={color}
            onChange={onChange}
            onChangeComplete={onChangeComplete}
          />
          {!hideAlpha && (
            <Alpha
              color={color}
              onChange={onChange}
              onChangeComplete={onChangeComplete}
            />
          )}
        </S.Section>
        {(!isFieldHide(hideInput, 'hex') ||
          !isFieldHide(hideInput, 'rgb') ||
          !isFieldHide(hideInput, 'hsv')) && (
          <S.Section>
            <Fields
              hideInput={hideInput}
              color={color}
              onChange={onChange}
              onChangeComplete={onChangeComplete}
            />
          </S.Section>
        )}
        <Button onClick={closeColorSelect}>확인</Button>
      </S.Body>
    </S.Container>
  );
};
