import { PALETTE } from '@/themes';

import { useSortable } from '@dnd-kit/sortable';
import { PropsWithChildren } from 'react';
import { IoMenuOutline } from 'react-icons/io5';

import { OrderId } from '.';
import * as S from './index.style';

type DragHandleProps = {
  id: OrderId;
} & PropsWithChildren;

export const DragHandle = ({ id, children }: DragHandleProps) => {
  const { listeners } = useSortable({ id });
  return (
    <S.DragHandleContainer {...listeners}>
      {children || <IoMenuOutline size={24} color={PALETTE.static.black} />}
    </S.DragHandleContainer>
  );
};
