import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const ToRegisterNavigate = styled.div`
  ${TYPOGRAPHY.body2}
  text-decoration: underline;
  cursor: pointer;
`;

export const ToRegisterExplain = styled.div`
  ${TYPOGRAPHY.body2}
  color:  ${PALETTE.text.caption};
  cursor: default;
`;

export const ToRegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  width: 100%;
  margin-top: 2rem;
`;

export const RegisterSummitWrapper = styled.div`
  width: 100%;
  margin-top: 2.125rem;

  > button {
    width: 100%;
    height: 3rem;
  }
`;

export const RegisterInputWrapper = styled.div`
  width: 100%;

  > div:nth-of-type(1) > div > input,
  div:nth-of-type(2) > div > input {
    ${TYPOGRAPHY.body2}
  }

  > div:nth-of-type(1) {
    margin-bottom: 1rem;
  }

  > div:nth-of-type(2) {
    margin-bottom: 1.125rem;
  }
`;

export const RegisterWrapper = styled.form`
  width: 90%;
  max-width: 28.75rem;
`;

export const LogoContainer = styled.div`
  margin-bottom: 2rem;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.logo1}
  font-weight: 700;
  letter-spacing: -1;

  > span {
    color: ${PALETTE.primary.normal};
  }
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100dvw;
  height: 100dvh;
`;
