import {
  AlertModal,
  Button,
  Checkbox,
  TextField,
  useModal,
} from '@/components';

import { useCheckSimultaneousMutation, useLoginMutation } from '@/hooks';
import { useAuth } from '@/hooks';

import { API_ERROR_CODE, INVALID_MASSAGE, PATH } from '@/constants';

import { jwtDecode } from 'jwt-decode';
import { useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

export type LoginFormType = {
  loginId: string;
  password: string;
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<LoginFormType>({
    defaultValues: { loginId: '', password: '' },
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const setToken = useAuth((state) => state.setToken);
  const setUser = useAuth((state) => state.setUser);
  const [isLoginContinue, toggleLoginContinue] = useReducer(
    (prev) => !prev,
    false,
  );
  const { mutate: loginMutation } = useLoginMutation({
    onSuccess: (res) => {
      setToken({
        accessToken: res.data.accessToken,
        refreshToken: res.data.refreshToken,
      });
      setUser(res.data.user);
      navigate(PATH.DASHBOARD_PAGE);
    },
    onError: (error) => {
      if (error.response?.data.errorCode === API_ERROR_CODE.INVALID_LOGINID) {
        setError('loginId', { message: '등록되지 않은 아이디예요.' });
      }
      if (error.response?.data.errorCode === API_ERROR_CODE.INVALID_PASSWORD) {
        setError('password', { message: '비밀번호가 일치하지 않아요.' });
      }
    },
  });

  const checkLoginModal = useModal(() => (
    <AlertModal
      title="동시 접속 제한 안내"
      description={
        <div>
          다른 기기에서 이 계정을 사용하고 있어요. 이 기기에서 로그인 하면 다른
          기기는 로그아웃 돼요. 로그인 할까요?
          <br />
          <br />
          <S.Warning>
            {'※ 다른 기기에서 작업 중인 내용은 저장되지 않아요.'}
          </S.Warning>
          <S.Warning>
            {'※ 자동 로그인 설정된 기기도 모두 로그아웃 돼요.'}
          </S.Warning>
        </div>
      }
      closeButtonEnable
      closeButtonLabel="취소"
      onClose={checkLoginModal.closeModal}
      submitButtonLabel="로그인"
      onSubmit={() => {
        loginMutation({
          ...watch(),
          isLoginKeep: isLoginContinue,
        });
        checkLoginModal.closeModal();
      }}
      closeModal={checkLoginModal.closeModal}
    />
  ));

  const { mutate: checkSimultaneousMutation } = useCheckSimultaneousMutation({
    onSuccess: (res) => {
      res.isAlreadyLogin
        ? checkLoginModal.openModal()
        : loginMutation({
            ...watch(),
            isLoginKeep: isLoginContinue,
          });
    },
    onError: (error) => {
      if (error.response?.data.errorCode === API_ERROR_CODE.INVALID_USER) {
        setError('loginId', { message: '등록되지 않은 아이디에요.' });
      }
    },
  });

  const isAbleSubmit = (): boolean => {
    const loginId = watch('loginId');
    const pw = watch('password');

    return (
      typeof loginId === 'string' &&
      typeof pw === 'string' &&
      loginId.length > 0 &&
      pw.length > 0 &&
      !errors.loginId &&
      !errors.password
    );
  };

  const checkLoggedIn = () => {
    const auth = JSON.parse(localStorage.getItem('auth') ?? '{}');
    const accessToken = auth?.state?.accessToken;
    const refreshToken = auth?.state?.refreshToken;
    const user = auth?.state?.user;
    if (accessToken) {
      setToken({
        accessToken,
        refreshToken,
        isAuthenticated: true,
        tokenExpires: jwtDecode(accessToken).exp
          ? jwtDecode(accessToken).exp || 0 * 1000
          : null,
      });
      setUser(user);
      return true;
    }
    return false;
  };

  const formSubmit = () => {
    if (checkLoggedIn()) {
      navigate(PATH.DASHBOARD_PAGE, { replace: true });
      return;
    }
    checkSimultaneousMutation({
      ...watch(),
      isLoginKeep: isLoginContinue,
    });
  };

  return (
    <S.LoginContainer>
      <S.LogoContainer>
        evryth<span>.</span>in
      </S.LogoContainer>
      <S.LoginWrapper autoComplete={'off'} onSubmit={handleSubmit(formSubmit)}>
        {/* 로그인 입력창 */}
        <S.LoginInputWrapper>
          <TextField
            {...register('loginId', {
              required: INVALID_MASSAGE.WRONG_ID,
              pattern: {
                value: /^[a-zA-Z0-9_]*$/,
                message: INVALID_MASSAGE.WRONG_ID,
              },
              minLength: { value: 6, message: INVALID_MASSAGE.WRONG_ID },
              maxLength: { value: 12, message: INVALID_MASSAGE.WRONG_ID },
              onChange: (e) =>
                (e.target.value = e.target.value.replaceAll(
                  /[^a-zA-Z0-9_]/g,
                  '',
                )),
            })}
            errorMessage={errors.loginId?.message}
            label="아이디"
            placeholder="아이디를 입력해 주세요."
            minLength={6}
            maxLength={12}
            autoComplete="username"
          />
          <TextField
            {...register('password', {
              required: INVALID_MASSAGE.WRONG_PASSWORD,
              pattern: {
                value:
                  /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,20}$/,
                message: INVALID_MASSAGE.WRONG_PASSWORD,
              },
              minLength: { value: 8, message: INVALID_MASSAGE.WRONG_PASSWORD },
              maxLength: { value: 20, message: INVALID_MASSAGE.WRONG_PASSWORD },
              onChange: (e) =>
                (e.target.value = e.target.value.replaceAll(
                  /[^a-zA-Z0-9!@#$%^*+=-]/g,
                  '',
                )),
            })}
            errorMessage={errors.password?.message}
            label="비밀번호"
            type="password"
            placeholder="비밀번호를 입력해 주세요."
            minLength={8}
            maxLength={20}
            isPassword
            autoComplete="current-password"
          />
          <Checkbox
            readOnly
            label="로그인 상태 유지하기"
            checked={isLoginContinue}
            onClick={toggleLoginContinue}
          />
        </S.LoginInputWrapper>
        {/* 로그인 버튼 */}
        <S.LoginSummitWrapper>
          <Button type="submit" size="large" disabled={!isAbleSubmit()}>
            로그인
          </Button>
          <S.ToRegisterContainer>
            <S.ToRegisterExplain>아직 회원이 아니신가요?</S.ToRegisterExplain>
            <S.ToRegisterNavigate
              type="button"
              onClick={() => navigate(PATH.REGISTER_PAGE)}
            >
              회원 가입
            </S.ToRegisterNavigate>
          </S.ToRegisterContainer>
        </S.LoginSummitWrapper>
      </S.LoginWrapper>
    </S.LoginContainer>
  );
};
