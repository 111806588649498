/* eslint-disable @typescript-eslint/no-explicit-any */
import { PALETTE } from '@/themes';

import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';

import * as S from './index.style';

type ButtonProps = {
  outlined?: boolean;
  colorType?: 'primary' | 'secondary' | 'color' | 'delete';
  size?: 'small' | 'medium' | 'large';
  icon?: ReactNode;
  fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const buttonColors = {
  fill: {
    primary: {
      normal: `color: ${PALETTE.static.white}; background-color: ${PALETTE.primary.normal}; border: none;`,
      hover: `color: ${PALETTE.static.white}; background-color: ${PALETTE.primary.hover}; border: none;`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: none;`,
    },
    secondary: {
      normal: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.primary.light.normal}; border: none;`,
      hover: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.primary.light.hover}; border: none;`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: none;`,
    },
    color: {
      normal: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.primary.light.normal}; border: none;`,
      hover: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.primary.light.hover}; border: none;`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: none;`,
    },
    delete: {
      normal: `color: ${PALETTE.static.white}; background-color: ${PALETTE.status.negative}; border: none;`,
      hover: `color: ${PALETTE.static.white}; background-color: ${PALETTE.status.negative10}; border: none;`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: none;`,
    },
  },
  outline: {
    primary: {
      normal: `color: ${PALETTE.text.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.text.normal};`,
      hover: `color: ${PALETTE.text.normal}; background-color: #f3f4f4; border: 1px solid ${PALETTE.text.normal};`,
      active: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.primary.normal};`,
      disable: `color: ${PALETTE.text.disable}; background-color: #f5f5f5; border: 1px solid ${PALETTE.line.normal};`,
    },
    secondary: {
      normal: `color: ${PALETTE.text.caption}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.line.normal};`,
      hover: `color: ${PALETTE.text.caption}; background-color: #f8f8f8; border: 1px solid ${PALETTE.line.normal};`,
      active: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.primary.normal};`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: 1px solid ${PALETTE.line.normal};`,
    },
    color: {
      normal: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.primary.normal};`,
      hover: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.primary.normal};`,
      active: `color: ${PALETTE.primary.normal}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.primary.normal};`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: 1px solid ${PALETTE.line.normal};`,
    },
    delete: {
      normal: `color: ${PALETTE.status.negative}; background-color: ${PALETTE.static.white}; border: 1px solid ${PALETTE.status.negative};`,
      disable: `color: ${PALETTE.text.disable}; background-color: ${PALETTE.interaction.disable}; border: 1px solid ${PALETTE.line.normal};`,
    },
  },
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      outlined = false,
      disabled = false,
      colorType = 'primary',
      size = 'medium',
      fontWeight = 500,
      icon,
      ...props
    },
    ref,
  ) => {
    const buttonColor = buttonColors[outlined ? 'outline' : 'fill'][colorType];
    return (
      <S.ButtonContainer
        {...props}
        ref={ref}
        type={props.type ?? 'button'}
        $buttonSize={size}
        $fontWeight={fontWeight}
        $buttonStyle={buttonColor[disabled ? 'disable' : 'normal']}
        $hoverStyle={!disabled && (buttonColor as any).hover}
        $activeStyle={outlined && !disabled && (buttonColor as any).active}
        disabled={disabled}
      >
        {icon && icon}
        {children}
      </S.ButtonContainer>
    );
  },
);
