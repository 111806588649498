import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const TdTooltip = styled.div`
  margin-right: 0.75rem;
`;

export const LastTdContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
`;

export const PromotionTerms = styled.div`
  position: absolute;
  top: 1.875rem;
  left: -8rem;
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.1875rem;

  padding: 10px !important;
  border-radius: 4px;

  background-color: ${PALETTE.static.white};
  box-shadow: ${BOXSHADOW.toolTip};

  > div {
    justify-content: space-between;
    padding: 0;
  }
`;

export const PromotionContentsAll = styled.div`
  padding: 0;
  border: none;

  background-color: transparent;
  text-decoration-line: underline;

  ${TYPOGRAPHY.body2};
  font-weight: 400;
  cursor: pointer;
`;

export const PromotionTdContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > div {
    padding: 0;
  }
`;
