import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;
