import { TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const ButtonContainer = styled.button<{
  $buttonSize: 'small' | 'medium' | 'large';
  $buttonStyle: string;
  $fontWeight: number;
  $hoverStyle?: string;
  $activeStyle?: string;
}>`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: ${({ $buttonSize }) => ($buttonSize === 'small' ? '5px' : '8px')}
    14px;
  border-radius: 10px;

  ${({ $buttonSize }) =>
    $buttonSize === 'small'
      ? TYPOGRAPHY.label2
      : $buttonSize === 'large'
        ? TYPOGRAPHY.title4
        : TYPOGRAPHY.body1}
  font-weight: ${({ $fontWeight }) => $fontWeight};
  cursor: pointer;

  ${({ $buttonStyle }) => $buttonStyle}
  &:hover {
    ${({ $hoverStyle }) => $hoverStyle}
  }

  &:active {
    ${({ $activeStyle }) => $activeStyle}
  }

  &.no-line {
    border: none !important;
  }
`;
