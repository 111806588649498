import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const AccordionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0.75rem 1rem;

  & + & {
    border-top: 1px solid ${PALETTE.line.normal};
  }

  background-color: ${PALETTE.background.normal};
`;
