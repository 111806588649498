import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
`;
export const Item = styled.div`
  display: grid;
  grid-template-columns: 6rem auto;
  align-items: center;
  gap: 1rem;
`;

export const Key = styled.div`
  ${TYPOGRAPHY.body2};
  font-weight: bold;
`;
export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  ${TYPOGRAPHY.body2};
`;

export const PopupPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  border-radius: 6px;

  background-color: ${PALETTE.background.normal};
  box-shadow: ${BOXSHADOW.card};
`;
export const PopupPreviewBtn = styled.div`
  cursor: pointer;

  text-decoration: underline;

  &:hover {
    color: ${PALETTE.primary.hover};
  }
`;
