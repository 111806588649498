import { Button, ColorSelect } from '@/components';

import { BOXSHADOW, ZINDEX } from '@/themes';

import { useEffect, useState } from 'react';
import { useColor } from 'react-color-palette';
import { Popover } from 'react-tiny-popover';

type LayerQrColorSelectorProps = {
  qrColor: string;
  setQrColor: (qrColor: string) => void;
  disabled?: boolean;
};

export const LayerQrColorSelector = ({
  qrColor,
  setQrColor,
  disabled = false,
}: LayerQrColorSelectorProps) => {
  const [color, setColor] = useColor(qrColor);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setQrColor(color.hex);
  }, [color]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      padding={10}
      positions={['left', 'top']}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <ColorSelect
          color={color}
          onChange={setColor}
          hideInput={['hsv', 'rgb']}
          closeColorSelect={() => setIsPopoverOpen(false)}
          containerStyle={{ boxShadow: BOXSHADOW.toolTip }}
        />
      }
      containerStyle={{
        zIndex: `${ZINDEX.popover}`,
      }}
    >
      <Button
        disabled={disabled}
        onClick={() => setIsPopoverOpen((prev) => !prev)}
      >
        색상 선택
      </Button>
    </Popover>
  );
};
