import { Button, Search } from '@/components';

import { PropsWithChildren, ReactNode } from 'react';
import { IoArrowBackOutline } from 'react-icons/io5';

import * as S from './index.style';

type HeaderProps = {
  title: ReactNode;
  isBorder?: boolean;
  moveHandler?: () => void;
  search?: {
    placeHolder: string;
    onSearch: (value: string) => void;
  };
} & PropsWithChildren;

export const Header = ({
  title,
  moveHandler,
  isBorder = true,
  search,
  children,
}: HeaderProps) => {
  return (
    <S.Container $isBorder={isBorder}>
      <S.Group>
        <S.TitleContainer>
          {moveHandler && (
            <Button.Svg onClick={moveHandler}>
              <IoArrowBackOutline size={24} />
            </Button.Svg>
          )}
          {title}
        </S.TitleContainer>
        {search && (
          <Search placeHolder={search.placeHolder} onSearch={search.onSearch} />
        )}
      </S.Group>
      <S.Group>{children}</S.Group>
    </S.Container>
  );
};
