import { Button, Empty } from '@/components';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { IoMusicalNotes, IoSearch } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type PlaylistEmptyProps = {
  search: string;
};

export const PlaylistEmpty = ({ search }: PlaylistEmptyProps) => {
  const navigate = useNavigate();
  return search.length > 0 ? (
    <Empty
      icon={<IoSearch size={60} color={PALETTE.component.normal} />}
      title={'검색 결과가 없어요.'}
    />
  ) : (
    <Empty
      icon={<IoMusicalNotes size={60} color={PALETTE.component.normal} />}
      title={'등록된 재생 목록이 없어요!'}
      description={
        <>
          콘텐츠를 추가하려면 재생 목록을 먼저 만들어야 해요.
          <br />새 재생 목록 추가 버튼을 클릭해서 재생 목록을 생성하고,
          <MdMoreVert size={18} />
          더보기 버튼 {'>'} 수정하기를 클릭해서 콘텐츠를 추가하세요.
        </>
      }
    >
      <Button
        size="medium"
        colorType="secondary"
        outlined
        onClick={() =>
          navigate(PATH.PLAYLIST_MODIFY_PAGE('new'), {
            state: {
              prevUrl: PATH.PLAYLIST_PAGE,
            },
          })
        }
      >
        새 재생 목록 추가하기
      </Button>
    </Empty>
  );
};
