import { ApiErrorResponse } from '@/types';

import {
  CheckSimultaneousResDto,
  LoginReqDto,
  getCheckSimultaneous,
} from '@/apis';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useCheckSimultaneousMutation = ({
  onSuccess,
  onError,
}: {
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
  onSuccess?: (res: CheckSimultaneousResDto) => void;
}) => {
  return useMutation({
    mutationFn: async (loginData: LoginReqDto) => {
      return await getCheckSimultaneous({ ...loginData });
    },
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
    onSuccess: (res) => {
      onSuccess?.(res!);
    },
  });
};
