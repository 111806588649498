import { PALETTE } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isBorderTop: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem 1.5rem;
  ${({ $isBorderTop }) =>
    $isBorderTop &&
    css`
      border-top: 1px solid ${PALETTE.line.normal};
    `}
`;
