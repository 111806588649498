import { useOnClickOutside } from '@/hooks';

import { PALETTE } from '@/themes';

import { ReactNode, useRef, useState } from 'react';
import { IoChevronUp } from 'react-icons/io5';

import * as S from './index.style';

export type DropdownItemType<T> = {
  label: ReactNode;
  value: T;
};

type DropDownProps<T> = {
  options: Array<DropdownItemType<T>>;
  onSelect: (value: T) => void;
  placeholder?: string;
  initialValue?: DropdownItemType<T>;
  disabled?: boolean;
};

export const DropDown = <T,>({
  options,
  onSelect,
  placeholder,
  initialValue,
  disabled = false,
}: DropDownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<DropdownItemType<T> | null>(initialValue ?? null);

  const handleOptionClick = (option: DropdownItemType<T>) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent | FocusEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  return (
    <S.DropdownContainer ref={dropdownRef}>
      <S.DropdownHeader
        onClick={() => !disabled && setIsOpen(!isOpen)}
        $isOpen={isOpen}
        $disabled={disabled}
      >
        <S.Text $disabled={disabled}>
          {placeholder ?? selectedOption?.label}
        </S.Text>
        <S.DropdownIcon $isOpen={isOpen}>
          <IoChevronUp size={20} color={PALETTE.component.strong} />
        </S.DropdownIcon>
      </S.DropdownHeader>
      {isOpen && (
        <S.DropdownList>
          {options.map((option, index) => (
            <S.DropdownItem
              key={index}
              onClick={() => handleOptionClick(option)}
              $isSelected={option.value === selectedOption?.value}
            >
              <S.Text $disabled={disabled}>{option.label}</S.Text>
            </S.DropdownItem>
          ))}
        </S.DropdownList>
      )}
    </S.DropdownContainer>
  );
};
