/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement } from 'react';
import { IoAddCircleOutline, IoPencil } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';

import * as S from './index.style';

type TextButtonType = 'add' | 'modify' | 'delete';

type TextButtonProps = {
  type: TextButtonType;
  handler: (value?: any) => void;
};

type ButtonIconObjType = {
  [key in TextButtonType]: {
    icon: ReactElement;
    title: '추가' | '수정' | '삭제';
  };
};

const BUTTON_ICON_OBJ: ButtonIconObjType = {
  add: { icon: <IoAddCircleOutline />, title: '추가' },
  modify: { icon: <IoPencil />, title: '수정' },
  delete: { icon: <MdDeleteOutline />, title: '삭제' },
};

export const TextButton = ({ type, handler }: TextButtonProps) => {
  const { icon, title } = BUTTON_ICON_OBJ[type];

  return (
    <S.TextButtonContainer type="button" onClick={handler}>
      <div>{icon}</div>
      <div>{title}</div>
    </S.TextButtonContainer>
  );
};
