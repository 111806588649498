import styled, { css } from 'styled-components';

export const ArrowButtonContainer = styled.button<{
  $direction: 'left' | 'right';
  $color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-height: 3.25rem;

  border: none;
  ${({ $direction, $color }) => css`
    border-radius: ${$direction === 'left' ? '0 6px 6px 0' : '6px 0 0 6px'};

    background-color: ${$color};
  `}

  cursor: pointer;

  > svg {
    width: 1.5rem;
    height: 1.5rem;

    color: #fff;
  }
`;
