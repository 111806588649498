import React, { memo, useCallback, useEffect, useState } from 'react';
import { ColorService, type IColor } from 'react-color-palette';

import { formatHsv, formatRgb, isFieldHide } from '../format';
import * as S from './index.style';

type IFieldsProps = {
  hideInput: (keyof IColor)[] | boolean;
  color: IColor;
  onChange: (color: IColor) => void;
  onChangeComplete?: (color: IColor) => void;
};

export const Fields = memo(
  ({ hideInput, color, onChange, onChangeComplete }: IFieldsProps) => {
    const [fields, setFields] = useState({
      hex: {
        value: color.hex,
        inputted: false,
      },
      rgb: {
        value: formatRgb(color.rgb),
        inputted: false,
      },
      hsv: {
        value: formatHsv(color.hsv),
        inputted: false,
      },
    });

    useEffect(() => {
      if (!fields.hex.inputted) {
        setFields((_fields) => ({
          ..._fields,
          hex: { ..._fields.hex, value: color.hex },
        }));
      }
    }, [fields.hex.inputted, color.hex]);

    useEffect(() => {
      if (!fields.rgb.inputted) {
        setFields((_fields) => ({
          ..._fields,
          rgb: { ..._fields.rgb, value: formatRgb(color.rgb) },
        }));
      }
    }, [fields.rgb.inputted, color.rgb]);

    useEffect(() => {
      if (!fields.hsv.inputted) {
        setFields((_fields) => ({
          ..._fields,
          hsv: { ..._fields.hsv, value: formatHsv(color.hsv) },
        }));
      }
    }, [fields.hsv.inputted, color.hsv]);

    const onInputChange = useCallback(
      <T extends keyof typeof fields>(field: T) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;

          setFields((_fields) => ({
            ..._fields,
            [field]: { ..._fields[field], value },
          }));
          if (field === 'hsv') {
            onChange(ColorService.convert('hsv', ColorService.toHsv(value)));
          } else if (field === 'rgb') {
            onChange(ColorService.convert('rgb', ColorService.toRgb(value)));
          } else {
            onChange(ColorService.convert('hex', value));
          }
        },
      [onChange],
    );

    const onInputFocus = useCallback(
      <T extends keyof typeof fields>(field: T) =>
        () => {
          setFields((_fields) => ({
            ..._fields,
            [field]: { ..._fields[field], inputted: true },
          }));
        },
      [],
    );

    const onInputBlur = useCallback(
      <T extends keyof typeof fields>(field: T) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;

          setFields((_fields) => ({
            ..._fields,
            [field]: { ..._fields[field], inputted: false },
          }));

          if (field === 'hsv') {
            onChangeComplete?.(
              ColorService.convert('hsv', ColorService.toHsv(value)),
            );
          } else if (field === 'rgb') {
            onChangeComplete?.(
              ColorService.convert('rgb', ColorService.toRgb(value)),
            );
          } else {
            onChangeComplete?.(ColorService.convert('hex', value));
          }
        },
      [onChangeComplete],
    );

    return (
      <S.Container>
        {!isFieldHide(hideInput, 'hex') && (
          <S.Floor>
            <S.Field>
              <S.Input
                id="hex"
                value={fields.hex.value}
                onChange={onInputChange('hex')}
                onFocus={onInputFocus('hex')}
                onBlur={onInputBlur('hex')}
              />
              <S.Label htmlFor="hex">HEX</S.Label>
            </S.Field>
          </S.Floor>
        )}
        {(!isFieldHide(hideInput, 'rgb') || !isFieldHide(hideInput, 'hsv')) && (
          <S.Floor>
            {!isFieldHide(hideInput, 'rgb') && (
              <S.Field>
                <S.Input
                  id="rgb"
                  value={fields.rgb.value}
                  onChange={onInputChange('rgb')}
                  onFocus={onInputFocus('rgb')}
                  onBlur={onInputBlur('rgb')}
                />
                <S.Label htmlFor="rgb">RGB</S.Label>
              </S.Field>
            )}
            {!isFieldHide(hideInput, 'hsv') && (
              <S.Field>
                <S.Input
                  id="hsv"
                  value={fields.hsv.value}
                  onChange={onInputChange('hsv')}
                  onFocus={onInputFocus('hsv')}
                  onBlur={onInputBlur('hsv')}
                />
                <S.Label htmlFor="hsv">HSV</S.Label>
              </S.Field>
            )}
          </S.Floor>
        )}
      </S.Container>
    );
  },
);
