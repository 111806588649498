import { Radio } from '@/components';

import * as S from './index.style';

export type RadioItem = {
  name: string;
  value: string;
  checked: boolean;
  disabled: boolean;
};

type RadioListProps = {
  infiniteRef?: (node?: Element | null) => void;
  title?: string;
  items: Array<RadioItem>;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: RadioItem) => void;
  onFocus?: (value: string) => void;
};

export const RadioList = ({
  infiniteRef,
  title,
  items,
  onSelect,
  onFocus,
}: RadioListProps) => {
  return (
    <S.Container>
      {title && <S.Title>{title}</S.Title>}
      <S.List>
        {items.map((v) => (
          <S.CheckBoxWrapper key={v.value} onClick={() => onFocus?.(v.value)}>
            <Radio
              label={v.name}
              id={v.value}
              name={'popupLayer'}
              checked={v.checked}
              disabled={v.disabled}
              value={v.value}
              onChange={(e) => onSelect(e, v)}
            />
          </S.CheckBoxWrapper>
        ))}
        <div ref={infiniteRef} />
      </S.List>
    </S.Container>
  );
};
