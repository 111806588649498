import { DeviceListItemResDto } from '@/apis';

import { DeleteModal, Tooltip, useModal } from '@/components';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { UseMutateFunction } from '@tanstack/react-query';
import { PaginationState } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type TableTooltipProps = {
  id: string;
  pagination: PaginationState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteDevice: UseMutateFunction<unknown, unknown, string, any>;
  setDeviceData: Dispatch<SetStateAction<DeviceListItemResDto[]>>;
};

export const TableTooltip = ({
  id,
  pagination,
  deleteDevice,
  setDeviceData,
}: TableTooltipProps) => {
  const navigate = useNavigate();

  const deleteModal = useModal(() => (
    <DeleteModal
      onSubmit={() => {
        deleteDevice(id);
        setDeviceData((prev) => prev.filter((el) => el.id !== id));
      }}
      closeModal={deleteModal.closeModal}
    />
  ));

  return (
    <S.LastTdContainer>
      <S.TdTooltip>
        <Tooltip
          itemList={[
            {
              name: '수정하기',
              icon: <IoPencil />,
              color: PALETTE.text.normal,
              handle: () =>
                navigate(PATH.DEVICE_MODIFY_PAGE(id), {
                  state: { prevUrl: PATH.DEVICE_PAGE, device: { pagination } },
                }),
            },
            {
              name: '삭제하기',
              icon: <IoTrash />,
              color: PALETTE.status.negative,
              handle: deleteModal.openModal,
            },
          ]}
        />
      </S.TdTooltip>
    </S.LastTdContainer>
  );
};
