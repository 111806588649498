import { postAddPlayListToDeviceByIds } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddPlaylistToDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      deviceIds,
      playlistId,
    }: {
      deviceIds: Array<string>;
      playlistId: string;
    }) => postAddPlayListToDeviceByIds(deviceIds, playlistId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.PLAYLIST(),
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.DEVICE(),
      });
      open;
    },
  });
};
