import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const TextButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  padding: 4px;
  border: none;

  background: none;

  color: ${PALETTE.text.normal};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  cursor: pointer;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${PALETTE.text.disable};
  }
`;
