import { PageReqOptionDto, SearchReqDto } from '@/types';

type Id = string;

export const QUERY_KEY = {
  DEVICE: (options?: PageReqOptionDto) => [
    'devices',
    ...(options ? [options] : []),
  ],
  DEVICE_INFINITY: (search?: SearchReqDto) => [
    'devices',
    'infinity',
    ...(search ? [search] : []),
  ],
  DEVICE_ID: (deviceId: Id) => ['device', deviceId],

  PLAYLIST: (search?: SearchReqDto) => [
    'playlists',
    ...(search ? [search] : []),
  ],
  PLAYLIST_INFINITY: (search?: SearchReqDto) => [
    'playlists',
    'infinity',
    ...(search ? [search] : []),
  ],
  PLAYLIST_ID: (playlistId: Id) => ['playlist', playlistId],

  POPUPLAYER: (search?: SearchReqDto) => [
    'popuplayers',
    ...(search ? [search] : []),
  ],
  POPUPLAYER_INFINITY: (search?: SearchReqDto) => [
    'popuplayers',
    'infinity',
    ...(search ? [search] : []),
  ],
  POPUPLAYER_ID: (popuplayerId: Id) => ['popuplayer', popuplayerId],

  STORE: (options?: PageReqOptionDto) => [
    'stores',
    ...(options ? [options] : []),
  ],
  STORE_ID: (storeId: Id) => ['store', storeId],
} as const;
