import styled from 'styled-components';

export const DeviceEmpty = styled.div`
  width: 100%;
  height: calc(100vh - 126px);
`;

export const MainBox = styled.main`
  width: 100%;
  margin: 0 auto;

  overflow-y: scroll;
`;
