import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
  box-shadow: ${BOXSHADOW.toolTip};
`;
export const MenuItem = styled.div<{ $isDefault?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 0.875rem;

  white-space: nowrap;
  ${({ $isDefault }) =>
    $isDefault
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;

          &:hover {
            background-color: ${PALETTE.interaction.hover.light};
          }
        `}
`;
export const MenuItemText = styled.div`
  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2};
`;
