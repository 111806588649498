import { SearchReqDto } from '@/types';

import { getDevicesDetail } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

export const useInfiniteDeviceListQuery = (search?: SearchReqDto) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery(
    {
      queryKey: QUERY_KEY.DEVICE_INFINITY(search),
      queryFn: ({ pageParam = 1 }) =>
        getDevicesDetail({ q: search?.q, page: pageParam, take: 10 }),
      select: (data) => data.pages.flatMap((page) => page.data),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.meta.hasNextPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
      gcTime: 0,
    },
    queryClient,
  );
};
