import { useIsomorphicLayoutEffect } from '@/hooks';

import { useEffect, useRef } from 'react';
import type { RefObject } from 'react';

/**
 * 미디어 쿼리 리스트 이벤트를 기반으로 이벤트 리스너를 추가합니다.
 *
 * @template K 미디어 쿼리 리스트 이벤트 맵의 키 유형
 * @param {K} eventName 이벤트 이름
 * @param {(event: MediaQueryListEventMap[K]) => void} handler 이벤트 핸들러 함수
 * @param {RefObject<MediaQueryList>} element 이벤트를 추가할 미디어 쿼리 리스트 요소의 참조 객체
 * @param {boolean | AddEventListenerOptions} [options] 이벤트 리스너 옵션
 * @returns {void}
 */
function useEventListener<K extends keyof MediaQueryListEventMap>(
  eventName: K,
  handler: (event: MediaQueryListEventMap[K]) => void,
  element: RefObject<MediaQueryList>,
  options?: boolean | AddEventListenerOptions,
): void;

/**
 * 윈도우 이벤트를 기반으로 이벤트 리스너를 추가합니다.
 *
 * @template K 윈도우 이벤트 맵의 키 유형
 * @param {K} eventName 이벤트 이름
 * @param {(event: WindowEventMap[K]) => void} handler 이벤트 핸들러 함수
 * @param {undefined} [element] 요소가 지정되지 않음
 * @param {boolean | AddEventListenerOptions} [options] 이벤트 리스너 옵션
 * @returns {void}
 */
function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: undefined,
  options?: boolean | AddEventListenerOptions,
): void;

/**
 * HTML 또는 SVG 요소 이벤트를 기반으로 이벤트 리스너를 추가합니다.
 *
 * @template K HTML 또는 SVG 요소 이벤트 맵의 키 유형
 * @template T 요소 유형 (기본적으로 HTMLDivElement 또는 SVGElement)
 * @param {K} eventName 이벤트 이름
 * @param {((event: HTMLElementEventMap[K]) => void)
 *   | ((event: SVGElementEventMap[K]) => void)} handler
 *   이벤트 핸들러 함수
 * @param {RefObject<T>} element 이벤트를 추가할 HTML 또는 SVG 요소의 참조 객체
 * @param {boolean | AddEventListenerOptions} [options] 이벤트 리스너 옵션
 * @returns {void}
 */
function useEventListener<
  K extends keyof HTMLElementEventMap & keyof SVGElementEventMap,
  T extends Element = K extends keyof HTMLElementEventMap
    ? HTMLDivElement
    : SVGElement,
>(
  eventName: K,
  handler:
    | ((event: HTMLElementEventMap[K]) => void)
    | ((event: SVGElementEventMap[K]) => void),
  element: RefObject<T>,
  options?: boolean | AddEventListenerOptions,
): void;

/**
 * 문서 이벤트를 기반으로 이벤트 리스너를 추가합니다.
 *
 * @template K 문서 이벤트 맵의 키 유형
 * @param {K} eventName 이벤트 이름
 * @param {(event: DocumentEventMap[K]) => void} handler 이벤트 핸들러 함수
 * @param {RefObject<Document>} element 이벤트를 추가할 문서 요소의 참조 객체
 * @param {boolean | AddEventListenerOptions} [options] 이벤트 리스너 옵션
 * @returns {void}
 */
function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: (event: DocumentEventMap[K]) => void,
  element: RefObject<Document>,
  options?: boolean | AddEventListenerOptions,
): void;

/**
 * 다양한 유형의 이벤트 소스(윈도우, 문서, HTML 요소, 미디어 쿼리)에 이벤트 리스너를 추가합니다.
 *
 * @template KW 윈도우 이벤트 맵의 키 유형
 * @template KH HTML 및 SVG 요소 이벤트 맵의 키 유형
 * @template KM 미디어 쿼리 리스트 이벤트 맵의 키 유형
 * @template T 요소 유형 (기본적으로 HTMLElement)
 * @param {KW | KH | KM} eventName 이벤트 이름
 * @param {(
 *   event:
 *     | WindowEventMap[KW]
 *     | HTMLElementEventMap[KH]
 *     | SVGElementEventMap[KH]
 *     | MediaQueryListEventMap[KM]
 *     | Event,
 * ) => void} handler
 *   이벤트 핸들러 함수
 * @param {RefObject<T>} [element] 이벤트를 추가할 요소의 참조 객체 (지정되지 않으면 윈도우 객체를 사용)
 * @param {boolean | AddEventListenerOptions} [options] 이벤트 리스너 옵션
 * @returns {void}
 */
function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap & keyof SVGElementEventMap,
  KM extends keyof MediaQueryListEventMap,
  T extends HTMLElement | SVGAElement | MediaQueryList = HTMLElement,
>(
  eventName: KW | KH | KM,
  handler: (
    event:
      | WindowEventMap[KW]
      | HTMLElementEventMap[KH]
      | SVGElementEventMap[KH]
      | MediaQueryListEventMap[KM]
      | Event,
  ) => void,
  element?: RefObject<T>,
  options?: boolean | AddEventListenerOptions,
) {
  // 핸들러를 저장하는 참조 생성
  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // 리스닝 대상 정의
    const targetElement: T | Window = element?.current ?? window;

    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // 핸들러 함수를 호출하는 이벤트 리스너 생성
    const listener: typeof handler = (event) => {
      savedHandler.current(event);
    };

    targetElement.addEventListener(eventName, listener, options);

    // 클린업 시 이벤트 리스너 제거
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}

export { useEventListener };
