import { DevicePlayListResDto } from '@/types';

import { Card, PlaylistAccordion } from '@/components';

import * as S from './index.style';

type DeviceContentsPreviewCardProps = {
  playlist: Array<DevicePlayListResDto>;
};

export const DeviceContentsPreviewCard = ({
  playlist,
}: DeviceContentsPreviewCardProps) => {
  return (
    <Card isShadow header={<S.Header>등록된 콘텐츠 미리보기</S.Header>}>
      {playlist.map((v, i) => (
        <PlaylistAccordion key={i} {...v.playList} />
      ))}
    </Card>
  );
};
