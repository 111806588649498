import { checkDuplicatedDeviceName } from '@/apis';

import { useMutation } from '@tanstack/react-query';

export const useCheckDeviceNameMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: boolean) => void;
  onError?: () => void;
}) => {
  return useMutation({
    mutationFn: ({ id, name }: { id: string; name: string }) =>
      checkDuplicatedDeviceName(id, name),
    onSuccess: (data) => onSuccess?.(data),
    onError: () => onError?.(),
  });
};
