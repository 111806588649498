import { DevicePopupResDto, PopupItemResDto } from '@/apis';

import { Loading, RadioList } from '@/components';
import type { RadioItem } from '@/components';

import { useInfinitePopupListQuery } from '@/hooks';

import { CenterLayout } from '@/layout';

import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './index.style';

type PopupRadioListProps = {
  search: string;
  popup: DevicePopupResDto | null;
  setPopup: (popup: DevicePopupResDto) => void;
  onFocus: (device: PopupItemResDto) => void;
};

export const PopupRadioList = ({
  search,
  popup,
  setPopup,
  onFocus,
}: PopupRadioListProps) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage, error } = useInfinitePopupListQuery({
    q: search,
  });
  const popupList = data ?? [];

  const onChecked = (currentPopup: DevicePopupResDto) =>
    currentPopup.id === popup?.id;

  const items = popupList.map((v) => ({
    name: v.name,
    value: v.id,
    checked: onChecked(v),
    disabled: false,
  }));

  const onSelect = (
    _: React.ChangeEvent<HTMLInputElement>,
    item: RadioItem,
  ) => {
    setPopup({ id: item.value, name: item.name });
  };

  const focusHandler = (popupId: string) => {
    const focusedPopup = popupList.find((value) => value.id === popupId);
    if (focusedPopup) {
      onFocus(focusedPopup);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      onFocus?.(popupList[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!error && inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      ) : (
        <S.Container>
          <RadioList
            title={'팝업 레이어 이름'}
            items={items}
            infiniteRef={ref}
            onSelect={onSelect}
            onFocus={focusHandler}
          />
        </S.Container>
      )}
    </>
  );
};
