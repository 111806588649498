import { checkDuplicatedPlaylistName } from '@/apis';

import { useMutation } from '@tanstack/react-query';

export const useCheckPlaylistNameMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: boolean) => void;
  onError?: () => void;
}) => {
  return useMutation({
    mutationFn: ({ id, name }: { id?: string; name: string }) =>
      checkDuplicatedPlaylistName({ id, name }),
    onSuccess: (data) => onSuccess?.(data),
    onError: () => onError?.(),
  });
};
