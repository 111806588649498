import { InputHTMLAttributes, forwardRef } from 'react';

import * as S from './index.style';

type CheckBoxOption = {
  label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckBoxOption>(
  ({ ...props }, ref) => {
    return (
      <S.CheckBoxWrapper>
        <S.CheckBox {...props} type="checkbox" id={props.name} ref={ref} />
        {props.label && <S.Label htmlFor={props.name}>{props.label}</S.Label>}
      </S.CheckBoxWrapper>
    );
  },
);
