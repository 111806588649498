import { ExtendedFile } from '@/types/file/extendedFile';

import { create } from 'zustand';

type FileState = {
  files: ExtendedFile[];
};

type FileActions = {
  removeFile: (id: string) => void;
  removeAll: () => void;
  appendFiles: (acceptedFiles: ExtendedFile[]) => void;
  updateUploadProgress: (id: string, uploadProgress: number) => void;
  updateUploadStatus: ({
    id,
    uuid,
    uploadStatus,
  }: {
    id: string;
    uuid?: string;
    uploadStatus: ExtendedFile['uploadStatus'];
  }) => void;
};

type FileSlice = FileState & FileActions;

export const useFileManager = create<FileSlice>()((set) => ({
  files: [],
  removeFile: (id) =>
    set((state) => ({
      files: state.files.filter((file) => file.id !== id),
    })),
  removeAll: () =>
    set(() => ({
      files: [],
    })),

  appendFiles: (acceptedFiles) =>
    set((state) => {
      const notDuplicatedNewFiles: ExtendedFile[] = acceptedFiles
        .filter((file) => {
          const isDuplicate = state.files.some(
            (subItem) => subItem.id === file.id,
          );
          return !isDuplicate;
        })
        .map((file) => ({
          file: file.file,
          id: file.id,
          uploadStatus: 'idle',
          uploadProgress: 0,
        }));

      return {
        files: [...state.files, ...notDuplicatedNewFiles],
      };
    }),

  updateUploadProgress: (id, uploadProgress) =>
    set((state) => ({
      files: state.files.map((file) =>
        file.id === id ? { ...file, uploadProgress } : file,
      ),
    })),

  updateUploadStatus: ({ id, uuid, uploadStatus }) =>
    set((state) => ({
      files: state.files.map((file) =>
        file.id === id ? { ...file, uuid, uploadStatus } : file,
      ),
    })),
}));
