import { PopupType } from '@/types';

import { Radio } from '@/components';

import * as S from './index.style';

type LayerTypeSelectorProps = {
  type: PopupType;
  setType: (type: PopupType) => void;
};

export const LayerTypeSelector = ({
  type,
  setType,
}: LayerTypeSelectorProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setType(e.target.value as PopupType);

  return (
    <S.Container>
      <Radio
        label={'QR코드'}
        name={'popupLayerType'}
        checked={type === 'qr'}
        value={'qr'}
        onChange={handleChange}
      />
      <Radio
        label={'이미지'}
        name={'popupLayerType'}
        checked={type === 'image'}
        value={'image'}
        onChange={handleChange}
      />
    </S.Container>
  );
};
