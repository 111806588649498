import { ApiErrorResponse } from '@/types';

import { DevicePutReqDto, putDeviceById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useModifyDeviceMutation = ({
  deviceId,
  onMutate,
  onSuccess,
  onError,
}: {
  deviceId: string;
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ name, model, popup, devicePlayLists }: DevicePutReqDto) =>
      putDeviceById(deviceId, {
        name,
        model,
        popup,
        devicePlayLists,
      }),
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.DEVICE_ID(deviceId),
      });
      onSuccess?.();
    },
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
  });
};
