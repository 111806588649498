import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';

export function useToggle(
  defaultState?: boolean,
  handler?: (value: boolean) => void,
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [isOn, setIsOn] = useState(!!defaultState);

  const toggle = useCallback(() => {
    setIsOn((x) => {
      handler && handler(!x);
      return !x;
    });
  }, []);

  return [isOn, toggle, setIsOn];
}
