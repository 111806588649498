import { PageReqOptionDto } from '@/types';

import { appendPageOptionsToUrl } from '@/utils';

type Id = string;

const AUTH = 'auth';
const DEVICE = 'device';
const PLAYLIST = 'playlist';
const POPUP = 'popup';
const STORE = 'store';
const FILE = 'file';
const SSE = 'sse';

export const API = {
  SIGNUP: `/${AUTH}/signup`,
  LOGIN: `/${AUTH}/login`,
  CHECK: (loginId: string) => `/${AUTH}/login/check/${loginId}`,
  LOGOUT: `/${AUTH}/logout`,
  REFRESH: `/${AUTH}/refresh`,
  CHECK_EMAIL: (email: string) => `/${AUTH}/${email}`,

  SSE: `/${SSE}/console`,

  PLAYLIST: `/${PLAYLIST}`,
  PLAYLIST_CHECK_NAME: `/${PLAYLIST}/check/name`,
  PLAYLIST_PAGE: (pageOption: PageReqOptionDto) =>
    appendPageOptionsToUrl(`/${PLAYLIST}`, pageOption),
  PLAYLIST_ID: (id: Id) => `/${PLAYLIST}/${id}`,

  DEVICE: `/${DEVICE}`,
  DEVICE_PAGE: (pageOption: PageReqOptionDto) =>
    appendPageOptionsToUrl(`/${DEVICE}`, pageOption),
  DEVICE_DETAIL: (pageOption: PageReqOptionDto) =>
    appendPageOptionsToUrl(`/${DEVICE}/detail`, pageOption),
  DEVICE_ID: (id: Id) => `/${DEVICE}/${id}`,
  DEVICE_CHECK_NAME: `/${DEVICE}/check/name`,
  DEVICE_CHECK_PROMOTIONS: `/${DEVICE}/check/promotions`,
  DEVICE_CHECK_DUID: (duid: Id) => `/${DEVICE}/duid/${duid}`,
  DEVICE_ID_ISON: (id: Id) => `/${DEVICE}/${id}/is-on`,
  DEVICE_IDS_POPUP_ID: (ids: Array<Id>, popupId: Id) =>
    `/${DEVICE}/${ids}/${POPUP}/${popupId}`,
  DEVICE_IDS_PLAYLIST_ID: (ids: Array<Id>, playlistId: Id) =>
    `/${DEVICE}/${ids}/${PLAYLIST}/${playlistId}`,

  POPUP: `/${POPUP}`,
  POPUP_PAGE: (pageOption: PageReqOptionDto) =>
    appendPageOptionsToUrl(`/${POPUP}`, pageOption),
  POPUP_ID: (id: Id) => `/${POPUP}/${id}`,

  STORE: `/${STORE}`,
  STORE_PAGE: (pageOption: PageReqOptionDto) =>
    appendPageOptionsToUrl(`/${STORE}`, pageOption),
  STORE_ID: (id: Id) => `/${STORE}/${id}`,

  FILE_INITIATE: `/${FILE}/initiate`,
  FILE_PRESIGN: `/${FILE}/presign`,
  FILE_COMPLETE: `/${FILE}/complete`,
  FILE_ABORT: `/${FILE}/abort`,
} as const;

export const API_ERROR_CODE = {
  // USER 10000
  INVALID_USER: 10_001,
  INVALID_LOGINID: 10_002,
  INVALID_PASSWORD: 10_003,
  INVALID_JWT: 10_004, // jwt 토큰 문제
  INVALID_CODE: 10_005,
  CONFLICT_LOGINID: 10_102, // 회원가입 시 로그인 ID 중복
  EXPIRED_JWT: 10_404,

  // DEVICE 20000
  INVALID_DEVICE: 20_001,
  CONFLICT_DEVICENAME: 20_102, // 디바이스 이름 중복

  // STORE 30000
  INVALID_STORE: 30_001,
  CONFLICT_STORENAME: 30_102, // 기기 이름 중복
  INUSE_STORE: 30_301, // 기기가 등록되 있어 삭제가 불가능한 매장

  // PLAYLIST 40000
  INVALID_PLAYLIST: 40_001,
  CONFLICT_PLAYLIST: 40_101,
  INUSE_PLAYLIST: 40_301, // 디바이스에서 기본 재생목록으로 사용 중인 재생목록

  // DEVICEPLAYLIST 50000
  INVALID_DEVICEPLAYLIST: 50_001,

  // POPUP 60000
  INVALID_POPUP: 60_001,

  // PROMOTION 70000
  INVALID_PROMOTION: 70_001,
  INVALID_PROMOTIONDATE: 70_002,
  INVALID_PROMOTIONTYPE: 70_003,
  CONFLICT_PROMOTIONDATE: 70_102,
  BAD_PROMOTIONDATE: 70_202,

  // PLAYCONTENT 80000
  INVALID_PLAYCONTENT: 80_001,

  // FILE 90000
  INVALID_FILE: 90_001,
};
