import { ApiErrorResponse } from '@/types';

import { StorePutReqDto, putStoreById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useModifyStoreMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void;
  onError?: (_error: AxiosError<ApiErrorResponse>) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, body }: { id: string; body: StorePutReqDto }) =>
      putStoreById(id, body),
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.STORE(),
      });
    },
  });
};
