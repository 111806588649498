import { FileType } from '@/types';

import { Accordion, Placeholder } from '@/components';

import * as S from './index.style';

export type PlaylistAccordionItemProps = {
  thumbnailUrl: string;
  originalName: string;
  type: FileType;
};

export const PlaylistAccordionItem = ({
  thumbnailUrl,
  originalName,
  type,
}: PlaylistAccordionItemProps) => {
  return (
    <Accordion.Item>
      <S.ContentThumbnailBox>
        <img
          src={thumbnailUrl}
          alt={originalName}
          onLoad={() => <Placeholder type={type} size={60} />}
          onError={() => <Placeholder type={type} size={60} />}
        />
      </S.ContentThumbnailBox>
      <div>
        <S.AccordionContentTitle>{originalName}</S.AccordionContentTitle>
        <S.AccordionContentType>
          {type === 'video' ? '비디오' : '이미지'}
        </S.AccordionContentType>
      </div>
    </Accordion.Item>
  );
};
