import { TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 4px;

  overflow: hidden;

  ${TYPOGRAPHY.title4};
  font-weight: bold;
  text-overflow: ellipsis;
  word-break: break-all;
`;
export const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  overflow: hidden;

  ${TYPOGRAPHY.body2};
  font-weight: normal;
  text-overflow: ellipsis;
  word-break: break-all;
`;
export const Dates = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;

  ${TYPOGRAPHY.body2};
  font-weight: 300;
`;
