import { ExtendedFile, FileType } from '@/types';

import { fileAbort, fileComplete, fileInitiate, filePreSign } from '@/apis';

import { useFileManager } from '@/hooks';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type CompletePart = {
  ETag: string;
  partNumber: number;
};

export const useMultipartUploadMutation = () => {
  const updateUploadProgress = useFileManager(
    (state) => state.updateUploadProgress,
  );
  const updateUploadStatus = useFileManager(
    (state) => state.updateUploadStatus,
  );
  const appendFiles = useFileManager((state) => state.appendFiles);

  return useMutation({
    mutationFn: async (files: ExtendedFile[]) => {
      const chunkSize = 10 * 1024 * 1024;
      const uploadPromises = files.map(async (file) => {
        if (file.uploadStatus === 'idle') {
          updateUploadStatus({ id: file.id, uploadStatus: 'pending' });
        }

        const fileName = file.file.name;
        const fileType = file.file.type.startsWith('image/')
          ? FileType.IMAGE
          : FileType.VIDEO;

        const { uploadId } = await fileInitiate({
          type: fileType,
          name: fileName,
        });
        try {
          const totalParts = Math.ceil(file.file.size / chunkSize);
          const progressArray = Array.from<number>({
            length: totalParts,
          }).fill(0);
          const completedParts: CompletePart[] = [];

          for (let partNumber = 0; partNumber < totalParts; partNumber++) {
            const start = partNumber * chunkSize;
            const end = Math.min(start + chunkSize, file.file.size);
            const filePart = file.file.slice(start, end);

            // Track progress in Zustand store
            const { preSignUrl } = await filePreSign({
              partNumber: partNumber + 1,
              uploadId,
            });
            if (!preSignUrl) return;
            const etagResult = await axios.put(preSignUrl, filePart, {
              onUploadProgress: (event) => {
                if (event.lengthComputable && event.total) {
                  const chunkProgress = (event.loaded / event.total) * 100;
                  progressArray[partNumber] = chunkProgress;
                  const totalProgress = Math.round(
                    progressArray.reduce((acc, cur) => acc + cur, 0) /
                      totalParts,
                  );
                  updateUploadProgress(file.id, totalProgress);
                }
              },
            });
            if (etagResult.status >= 400) {
              throw new Error(`ETag Error URL ${preSignUrl}`);
            }

            completedParts.push({
              ETag: etagResult.headers.etag,
              partNumber: partNumber + 1,
            });
          }

          const completeRes = await fileComplete({
            uploadId,
            parts: completedParts,
          });

          if (!completeRes.uuid) {
            throw new Error(`Multipartupload complete Failed`);
          }

          if (fileType === 'video') {
            updateUploadStatus({
              id: file.id,
              uuid: completeRes.uuid,
              uploadStatus: 'encoding',
            });
          } else {
            updateUploadStatus({ id: file.id, uploadStatus: 'success' });
          }
          return { id: file.id, ...completeRes };
        } catch {
          await fileAbort({ uploadId });
          updateUploadStatus({ id: file.id, uploadStatus: 'error' });
        }
      });
      return await Promise.all(uploadPromises);
    },
    onMutate: (variables) => {
      appendFiles(variables);
    },
    onSuccess: async () => {},
  });
};
