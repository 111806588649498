import {
  CheckPromotionType,
  ModifyPromotionMappedToDeviceResDto,
  NewPromotionMappedToDeviceResDto,
  PlayContentResDto,
} from '@/types';

import {
  AddPromotionModal,
  Button,
  DeviceModiftyPlaylistCard,
  useModal,
} from '@/components';

import { PALETTE } from '@/themes';

import { useCallback } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

import * as S from './index.style';
import { PromotionItemBox } from './promotionItemBox';

type PromotionCardProps = {
  playlistId: string;
  title: string;
  playTime: number;
  maxPromotionCount: number;
  promotions: Array<ModifyPromotionMappedToDeviceResDto>;
  checkPromotions: Array<CheckPromotionType>;
  playContents: PlayContentResDto[];
  setDefaultPlaylist: (playlistId: string) => void;
  deletePlaylist: (playlistId: string) => void;
  addPromotion: (
    playlistId: string,
    promotion: NewPromotionMappedToDeviceResDto,
  ) => void;
  editPromotion: (
    playlistId: string,
    promotionId: string,
    promotion: ModifyPromotionMappedToDeviceResDto,
  ) => void;
  deletePromotion: (playlistId: string, promotionId: string) => void;
};

export const PromotionCard = ({
  title,
  playTime,
  playlistId,
  maxPromotionCount,
  promotions,
  checkPromotions,
  playContents,
  setDefaultPlaylist,
  deletePlaylist,
  addPromotion,
  editPromotion,
  deletePromotion,
}: PromotionCardProps) => {
  const addPromotionModal = useModal(() => (
    <AddPromotionModal
      closeModal={addPromotionModal.closeModal}
      promotions={checkPromotions}
      onSubmit={addSubmit}
    />
  ));

  const addSubmit = useCallback(
    async (p: NewPromotionMappedToDeviceResDto) => {
      addPromotion(playlistId, p);
      addPromotionModal.closeModal();
    },
    [playlistId],
  );

  return (
    <S.Container>
      <DeviceModiftyPlaylistCard
        isDefault={false}
        playlistId={playlistId}
        playContents={playContents}
        title={title}
        playTime={playTime}
        setDefaultPlaylist={setDefaultPlaylist}
        deletePlaylist={deletePlaylist}
      />
      <S.Divider />
      <S.PromotionSection>
        <S.PromotionTitle>프로모션 기간</S.PromotionTitle>
        <S.PromotionBox>
          {promotions.map((p, i) => (
            <PromotionItemBox
              key={i}
              promotion={p}
              editPromotion={(
                promotionId: string,
                promotion: ModifyPromotionMappedToDeviceResDto,
              ) => editPromotion(playlistId, promotionId, promotion)}
              deletePromotion={(promotionId: string) =>
                deletePromotion(playlistId, promotionId)
              }
            />
          ))}
        </S.PromotionBox>
        {promotions.length < maxPromotionCount && (
          <Button.Svg onClick={addPromotionModal.openModal}>
            <S.AddButton>
              <IoAddCircleOutline size={14} color={PALETTE.text.caption} />
              <S.AddButtonText>추가</S.AddButtonText>
            </S.AddButton>
          </Button.Svg>
        )}
      </S.PromotionSection>
    </S.Container>
  );
};
