import * as S from './index.style';

export const Loading = () => {
  return (
    <S.DotContainer>
      <S.Dot $delay="0s" />
      <S.Dot $delay=".1s" />
      <S.Dot $delay=".2s" />
    </S.DotContainer>
  );
};
