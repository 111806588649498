import { PopupType } from '@/types';

import { PopupDataType, PopupExisitFile } from '@/apis';

import validator from 'validator';

import {
  LayerDescriptionColorSelector,
  LayerDescriptionTextArea,
  LayerImageUploader,
  LayerNameInput,
  LayerPositionSelector,
  LayerQrColorSelector,
  LayerTypeSelector,
  LayerUrlInput,
} from './editItem';
import * as S from './index.style';
import { PopupEditorItem } from './popupEditorItem';

type PopupEditorPanelProps = {
  popup: PopupDataType & { newFile?: File };
  setPopup: React.Dispatch<React.SetStateAction<PopupDataType>>;
};

export const PopupEditorPanel = ({
  popup,
  setPopup,
}: PopupEditorPanelProps) => {
  const disabled = !(popup.name.length > 0 && popup.type === PopupType.QR
    ? validator.isURL(popup.url)
    : popup.name.length > 0);

  return (
    <S.Container>
      <S.Wrapper>
        <S.StepBox>
          <PopupEditorItem title="팝업 레이어 유형 선택" required>
            <LayerTypeSelector
              type={popup.type}
              setType={(type) => setPopup((prev) => ({ ...prev, type }))}
            />
          </PopupEditorItem>
        </S.StepBox>
        <S.StepBox>
          <PopupEditorItem title="팝업 레이어 이름" required>
            <LayerNameInput
              name={popup.name}
              setName={(name) => setPopup((prev) => ({ ...prev, name }))}
            />
          </PopupEditorItem>
        </S.StepBox>
        {popup?.type === 'qr' && (
          <S.StepBox>
            <PopupEditorItem title="URL 입력" required>
              <LayerUrlInput
                url={popup.url}
                setUrl={(url) => setPopup((prev) => ({ ...prev, url }))}
              />
            </PopupEditorItem>
          </S.StepBox>
        )}
        {popup?.type === 'qr' ? (
          <>
            <S.StepBox>
              <PopupEditorItem title="팝업 레이어 위치" required>
                <LayerPositionSelector
                  disabled={disabled}
                  position={popup.position}
                  setPosition={(position) =>
                    setPopup((prev) => ({ ...prev, position }))
                  }
                />
              </PopupEditorItem>
              <PopupEditorItem title="테두리 색상" required>
                <LayerQrColorSelector
                  disabled={disabled}
                  qrColor={popup.qrColor}
                  setQrColor={(qrColor) =>
                    setPopup((prev) => ({ ...prev, qrColor }))
                  }
                />
              </PopupEditorItem>
              <PopupEditorItem title="이미지 추가" optional>
                <LayerImageUploader
                  disabled={disabled}
                  file={popup.newFile ?? (popup.file as PopupExisitFile)}
                  setFile={(newFile) =>
                    setPopup((prev) => ({
                      ...prev,
                      file: newFile ? prev.file : undefined,
                      newFile,
                    }))
                  }
                />
              </PopupEditorItem>
            </S.StepBox>
          </>
        ) : (
          <S.StepBox>
            <PopupEditorItem title="이미지 추가" required>
              <LayerImageUploader
                file={popup.newFile ?? (popup.file as PopupExisitFile)}
                setFile={(newFile) =>
                  setPopup((prev) => ({
                    ...prev,
                    file: newFile ? prev.file : undefined,
                    newFile,
                  }))
                }
                disabled={disabled}
              />
            </PopupEditorItem>
            <PopupEditorItem title="팝업 레이어 위치" required>
              <LayerPositionSelector
                disabled={disabled}
                position={popup.position}
                setPosition={(position) =>
                  setPopup((prev) => ({ ...prev, position }))
                }
              />
            </PopupEditorItem>
          </S.StepBox>
        )}

        <S.StepBox>
          <PopupEditorItem title="문구 입력" optional>
            <LayerDescriptionTextArea
              disabled={disabled}
              description={popup.description}
              setDescription={(description) =>
                setPopup((prev) => ({ ...prev, description }))
              }
            />
          </PopupEditorItem>
          <PopupEditorItem title="문구 색상">
            <LayerDescriptionColorSelector
              disabled={disabled}
              descriptionColor={popup.descriptionColor}
              setDescriptionColor={(descriptionColor) =>
                setPopup((prev) => ({ ...prev, descriptionColor }))
              }
            />
          </PopupEditorItem>
        </S.StepBox>
      </S.Wrapper>
    </S.Container>
  );
};
