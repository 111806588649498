import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

/** 프로모션 아이템 박스 */
export const PromotionItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
export const PromotionItemBox = styled.div<{ $bgColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;

  background-color: ${({ $bgColor }) => $bgColor};
  gap: 0.25rem;
`;
export const PromotionItemSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.375rem;
`;
export const PromotionItemDate = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2};
  font-weight: 500;
`;
export const PromotionItemTime = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2};
  font-weight: 500;
`;
export const PromotionItemRepeat = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.label1};
`;
export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.125rem;
`;
export const DeleteButtonText = styled.div`
  margin-left: 0.125rem;

  color: ${PALETTE.text.placeholder};
  ${TYPOGRAPHY.label2};
  font-weight: 700;
`;
