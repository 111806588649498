import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';

type ModalElement = {
  id: string;
  element: React.FC;
};

export const ModalContext = createContext<{
  modalElements: ModalElement[];
  setModalElements: React.Dispatch<React.SetStateAction<ModalElement[]>>;
}>({
  modalElements: [],
  setModalElements: () => {},
});

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modalElements, setModalElements] = useState<ModalElement[]>([]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setModalElements(modalElements.slice(0, -1));
      }
    };

    if (modalElements.length === 0) {
      document.removeEventListener('keydown', handleKeyDown);
    } else {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalElements]);

  return (
    <>
      <ModalContext.Provider value={{ modalElements, setModalElements }}>
        {children}
        {modalElements.map(({ id, element }) => {
          return <Component key={id} component={element} />;
        })}
      </ModalContext.Provider>
    </>
  );
};

const Component = ({ component, ...rest }: { component: React.FC }) => {
  return component({ ...rest });
};
