import { PALETTE } from '@/themes';

import {
  InputHTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
  useReducer,
} from 'react';
import { IoEye, IoEyeOff } from 'react-icons/io5';

import * as S from './index.style';

type TextFieldProps = {
  label?: string;
  isPassword?: boolean;
  errorMessage?: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  containerRef?: Ref<HTMLDivElement>;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      isPassword = false,
      errorMessage,
      suffix,
      prefix,
      label,
      type,
      containerRef,
      ...props
    },
    ref,
  ) => {
    const [isShowToggle, setIsShowToggle] = useReducer((prev) => !prev, false);

    return (
      <S.Container ref={containerRef}>
        {label && <S.Label>{label}</S.Label>}
        <S.TextFieldWrapper
          $error={!!errorMessage}
          $isDisabled={props.disabled ?? false}
        >
          {prefix && <S.Prefix>{prefix}</S.Prefix>}
          <S.Input
            {...props}
            ref={ref}
            $leftPadding={prefix ? 0 : 14}
            type={isPassword ? (isShowToggle ? 'text' : 'password') : type}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          />
          {isPassword && (
            <S.ToggleButton type="button" onClick={setIsShowToggle}>
              {isShowToggle ? (
                <IoEye size={20} color={PALETTE.component.strong} />
              ) : (
                <IoEyeOff size={20} color={PALETTE.component.strong} />
              )}
            </S.ToggleButton>
          )}
          {suffix && <S.Suffix>{suffix}</S.Suffix>}
        </S.TextFieldWrapper>
        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      </S.Container>
    );
  },
);
