import {
  Dashboard,
  Device,
  DeviceModifyPage,
  Login,
  PlayList,
  PlaylistModify,
  PopupLayer,
  PopupModify,
  Register,
  Store,
} from '@/pages';

import { ProtectedRoute } from '@/components';

import { PATH } from '@/constants';

import { Navigate, Route, Routes } from 'react-router-dom';

import { MainLayout } from './layout';

export const Router = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute authentication={false} />}>
        <Route path={PATH.LOGIN_PAGE} element={<Login />} />
        <Route path={PATH.REGISTER_PAGE} element={<Register />} />
      </Route>
      <Route
        element={
          <ProtectedRoute authentication={true}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path={PATH.ROOT}
          element={<Navigate to={PATH.DASHBOARD_PAGE} />}
        />
        <Route path={PATH.DASHBOARD_PAGE} element={<Dashboard />} />
        <Route path={PATH.DEVICE_PAGE} element={<Device />} />,
        <Route
          path={PATH.DEVICE_MODIFY_PAGE()}
          element={<DeviceModifyPage />}
        />
        <Route path={PATH.PLAYLIST_PAGE} element={<PlayList />} />
        <Route
          path={PATH.PLAYLIST_MODIFY_PAGE()}
          element={<PlaylistModify />}
        />
        <Route path={PATH.POPUP_PAGE} element={<PopupLayer />} />
        <Route path={PATH.POPUP_MODIFY_PAGE()} element={<PopupModify />} />
        <Route path={PATH.STORE_PAGE} element={<Store />} />
        <Route path="*" element={<Navigate to={PATH.ROOT} replace={true} />} />
      </Route>
    </Routes>
  );
};
