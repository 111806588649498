import { PopupDataType, PopupExisitFile } from '@/apis';

import { useDebounce } from '@/hooks';

import { Resizable } from 're-resizable';
import { useEffect, useState } from 'react';
import validator from 'validator';

import { Popup } from '../popup';
import { PreviewIFrame } from '../previewIFrame';
import { SizeInfoBox } from '../sizeInfoBox';
import * as S from './index.style';

type PopupPreviewProps = {
  popup: PopupDataType & { newFile?: File };
};

export const PopupPreview = ({ popup }: PopupPreviewProps) => {
  const QREnable = popup.type === 'qr' && validator.isURL(popup.url);
  const debounceUrl = useDebounce(QREnable ? popup.url : '');
  const debounceDescription = useDebounce(popup.description);
  const debounceDescriptionColor = useDebounce(popup.descriptionColor);
  const debouncePosition = useDebounce(popup.position);
  const debounceQrColor = useDebounce(popup.qrColor);

  const [size, setSize] = useState({ width: 0, height: 0 });
  const [resizeRef, setResizeRef] = useState<Resizable | null>(null);

  useEffect(() => {
    if (resizeRef) {
      setSize({ width: resizeRef?.size.width, height: resizeRef?.size.height });
    }
  }, [resizeRef]);

  useEffect(() => {
    if (resizeRef) {
      resizeRef.updateSize(size);
    }
  }, [size]);

  return (
    <S.Container>
      <Resizable
        ref={(c) => setResizeRef(c)}
        maxHeight={'100%'}
        maxWidth={'100%'}
        minWidth={'200px'}
        minHeight={'200px'}
        defaultSize={{
          width: '100%',
          height: '100%',
        }}
        resizeRatio={16 / 9}
        onResize={(_e, _d, ref) =>
          setSize({ width: ref.offsetWidth, height: ref.offsetHeight })
        }
      >
        <PreviewIFrame>
          <Popup
            size={size}
            url={debounceUrl ?? ''}
            fileUrl={
              popup.newFile
                ? URL.createObjectURL(popup.newFile as File)
                : popup.file && 'originalUrl' in popup.file
                  ? (popup.file as PopupExisitFile).originalUrl
                  : ''
            }
            position={debouncePosition ?? ''}
            qrColor={debounceQrColor ?? ''}
            description={debounceDescription ?? ''}
            descriptionColor={debounceDescriptionColor ?? ''}
          />
        </PreviewIFrame>
      </Resizable>
      <SizeInfoBox size={size} setSize={setSize} />
    </S.Container>
  );
};
