import { AlertModal } from '@/components';

type LeaveCurrentPageModalProps = {
  closeModal: () => void;
  onSubmit: () => void;
};

export const LeaveCurrentPageModal = ({
  closeModal,
  onSubmit,
}: LeaveCurrentPageModalProps) => {
  return (
    <AlertModal
      title="현제 페이지에서 나가겠습니까?"
      description="변경 사항이 저장되지 않습니다."
      submitButtonLabel="나가기"
      closeButtonLabel="취소"
      closeButtonEnable={true}
      onSubmit={onSubmit}
      onClose={closeModal}
      closeModal={closeModal}
    />
  );
};
