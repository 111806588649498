import { isArrEmpty } from '@/utils';

import { ModalContext } from '@/providers';

import { useCallback, useContext, useId } from 'react';

export const useModal = (component: React.FC) => {
  const { modalElements, setModalElements } = useContext(ModalContext);

  const id = useId();

  const openModal = useCallback(() => {
    setModalElements((pre) => [...pre, { id: id, element: component }]);
    document.body.style.overflow = 'hidden';
  }, [component, id, setModalElements]);

  const closeModal = useCallback(() => {
    setModalElements((pre) => pre.filter((c) => c.id !== id));

    if (isArrEmpty(modalElements)) {
      document.body.style.overflow = 'unset';
    }
  }, [id, modalElements, setModalElements]);

  const closeAllModal = useCallback(() => {
    setModalElements([]);
    document.body.style.overflow = 'unset';
  }, [setModalElements]);

  return { openModal, closeModal, closeAllModal };
};
