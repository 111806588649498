import { useAuth } from '@/hooks';

import { PATH } from '@/constants/path';

import { SSEProvider } from '@/providers';

import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  authentication: boolean;
  children?: React.ReactNode;
}

export const ProtectedRoute = ({
  authentication,
  children,
}: ProtectedRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (authentication) {
    return isAuthenticated ? (
      <SSEProvider>{children ?? <Outlet />}</SSEProvider>
    ) : (
      <Navigate to={PATH.LOGIN_PAGE} />
    );
  } else {
    return isAuthenticated ? (
      <Navigate to={PATH.DASHBOARD_PAGE} />
    ) : (
      <>{children ?? <Outlet />}</>
    );
  }
};
