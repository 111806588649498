import { AlertModal } from '@/components';

import { PALETTE } from '@/themes';

type PlaylistNotFoundModalProps = {
  closeModal: () => void;
  onSubmit: () => void;
};

export const PlaylistNotFoundModal = ({
  closeModal,
  onSubmit,
}: PlaylistNotFoundModalProps) => {
  return (
    <AlertModal
      title="기본 재생 목록이 없는 디바이스가 있어요."
      description={
        <span style={{ color: PALETTE.text.caption }}>
          기본 재생 목록이 없는 디바이스에서는 지금 추가하는 재생 목록이 기본
          재생 목록으로 설정돼요.
        </span>
      }
      submitButtonLabel="확인"
      onSubmit={onSubmit}
      closeModal={closeModal}
    />
  );
};
