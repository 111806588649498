import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

import * as S from './index.style';

type ArrowButtonProps = {
  direction: 'left' | 'right';
  color: string;
  handler: () => void;
};

export const ArrowButton = ({
  direction,
  color,
  handler,
}: ArrowButtonProps) => {
  return (
    <S.ArrowButtonContainer
      type={'button'}
      className={`arrow-button-${direction}`}
      $direction={direction}
      $color={color}
      onClick={handler}
    >
      {direction === 'right' ? (
        <IoChevronForwardOutline />
      ) : (
        <IoChevronBackOutline />
      )}
    </S.ArrowButtonContainer>
  );
};
