import { Card } from '@/components';

import { dateFormat, secondToTimeFormat } from '@/utils';

import * as S from './index.style';

type DeviceInfoCardProps = {
  name: string;
  playTime: number;
  createdAt: string | Date;
  updatedAt?: string | Date;
};

export const PlaylistInfoCard = ({
  name,
  playTime,
  createdAt,
  updatedAt,
}: DeviceInfoCardProps) => {
  return (
    <Card
      isShadow
      header={
        <S.Container>
          <S.Item>
            <S.Key>이름</S.Key>
            <S.Value>{name}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>재생 시간</S.Key>
            <S.Value>{secondToTimeFormat(playTime)}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>생성일</S.Key>
            <S.Value>{dateFormat(createdAt, '/')}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>최종 수정일</S.Key>
            {updatedAt && <S.Value>{dateFormat(updatedAt, '/')}</S.Value>}
          </S.Item>
        </S.Container>
      }
    />
  );
};
