import { datadogInit, sentryInit } from '@/utils';

import App from '@/App';

import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

const NODE_ENV = import.meta.env.VITE_NODE_ENV;
if (NODE_ENV === 'production') {
  datadogInit();
  sentryInit();
  const originalConsoleError = console.error;
  // eslint-disable-next-line no-console
  const originalConsoleLog = console.log;
  const originalConsoleInfo = console.info;

  console.error = function (
    message: string,
    error: Error,
    messageContext: Record<string, unknown>,
    ...optionalParams: unknown[]
  ) {
    datadogLogs.logger.error(message, { messageContext }, error);
    originalConsoleError.apply(console, [message, ...optionalParams]);
    Sentry.captureMessage(`[ERROR] ${message} ${optionalParams.join(' ')}`);
  };

  // eslint-disable-next-line no-console
  console.log = function (
    message: string,
    messageContext: Record<string, unknown>,
    ...optionalParams: unknown[]
  ) {
    datadogLogs.logger.info(message, { messageContext });
    originalConsoleLog.apply(console, [message, ...optionalParams]);
    Sentry.captureMessage(`[LOG] ${message} ${optionalParams.join(' ')}`);
  };
  console.info = function (
    message: string,
    messageContext: Record<string, unknown>,
    ...optionalParams: unknown[]
  ) {
    datadogLogs.logger.info(message, { messageContext });
    originalConsoleInfo.apply(console, [message, ...optionalParams]);
    Sentry.captureMessage(`[INFO] ${message} ${optionalParams.join(' ')}`);
  };
}
ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
