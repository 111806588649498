import { TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
`;
export const Item = styled.div`
  display: grid;
  grid-template-columns: 6rem auto;
  align-items: center;
  gap: 1rem;
`;

export const Key = styled.div`
  ${TYPOGRAPHY.body2};
  font-weight: bold;
`;
export const Value = styled.div`
  ${TYPOGRAPHY.body2};
`;
