import { useAuth } from '@/hooks';

import { PATH } from '@/constants';

import axios from 'axios';

export const BASE_URL = import.meta.env.VITE_APP_BASE_API_URL;

export const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use(
  async (request) => {
    try {
      await useAuth.getState().checkAndUpdateToken();
      const accessToken = useAuth.getState().accessToken;
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (refreshError) {
      console.error('API REQUEST ERROR', refreshError, {
        request,
      });
      window.location.href = PATH.LOGIN_PAGE;
      throw refreshError;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      try {
        await useAuth.getState().updateToken();
        const accessToken = useAuth.getState().accessToken;
        instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      } catch (refreshError) {
        console.error('API RESPONSE ERROR', refreshError, {
          request: originalRequest,
        });
        window.location.href = PATH.LOGIN_PAGE;
        throw refreshError;
      }
    } else {
      throw error;
    }
  },
);
