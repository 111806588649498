export enum INVALID_MASSAGE {
  WRONG_ID = '6~12자 이내, 영문/숫자/_ 조합으로 입력해 주세요.',
  WRONG_PASSWORD = '8자~20자 이내, 영문/숫자/특수문자 조합으로 입력해 주세요.',
  WRONG_EMAIL = '올바른 이메일 형식으로 입력해 주세요.',
  ALREADY_ENROLLED_TIME = '이미 등록된 시간이에요.',
  END_EARLYER_THEN_START = '종료 시각을 시작 시각보다 늦은 시각으로 입력해 주세요.',
  START_EARLYER_THEN_END = '시작 시각을 종료 시각보다 빠른 시각으로 입력해 주세요.',
  REQUIRED_STORE_NAME = '매장 이름을 입력하세요.',
  ALREADY_ADDED_STORE = '이미 등록된 매장 이름이에요.',
  ALREADY_USED_NAME = '이미 사용 중인 이름이에요.',
  INVALID_DEVICE_NAME = '잘못된 디바이스 이름이에요.',
  INVALID_PLAYLIST_NAME = '잘못된 플레이리스트 이름이에요.',
}
