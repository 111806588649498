import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Prefix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;

  background-color: transparent;
`;
export const Suffix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;

  background-color: transparent;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2};
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextFieldWrapper = styled.div<{
  $error: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 2.625rem;
  border: 1px solid
    ${(props) => (props.$error ? PALETTE.status.negative : PALETTE.line.normal)};
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
  overflow: hidden;
  outline: none;

  transition: border-color 0.2s;

  &:active {
    border-color: ${PALETTE.static.black};
  }

  &:focus {
    border-color: ${PALETTE.static.black};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      border: solid 1px ${PALETTE.component.normal};

      background-color: ${PALETTE.interaction.disable};

      color: ${PALETTE.text.disable};
    `}
`;

export const Input = styled.input<{ $leftPadding: number }>`
  width: 100%;
  height: 100%;
  padding: 0.625rem 0 0.625rem ${({ $leftPadding }) => $leftPadding}px;
  border: none;

  outline: none;

  ${TYPOGRAPHY.body2};
  &::placeholder {
    color: ${PALETTE.text.placeholder};
  }

  &:disabled {
    background-color: transparent;

    color: ${PALETTE.text.disable};
  }
`;
export const ToggleButton = styled.button`
  height: 2.5rem;
  padding: 0.625rem;
  border: none;

  background-color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
`;
export const ErrorMessage = styled.div`
  margin-top: 6px;

  ${TYPOGRAPHY.label2};
  color: ${PALETTE.status.negative};
`;
