import {
  CheckPromotionType,
  NewPromotionMappedToDeviceResDto,
  PromotionType,
} from '@/types';

import {
  Button,
  Checkbox,
  DatePicker,
  DaysPicker,
  DropDown,
  Modal,
  TimePicker,
  WeekDaysPicker,
} from '@/components';

import { useCheckDevicePromotionMutation } from '@/hooks';

import {
  dateFormat,
  hhmmToSecond,
  isArrEmpty,
  promotionTypeToKor,
} from '@/utils';

import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import * as S from './index.style';

type AddPromotionModalProps = {
  closeModal: () => void;
  onSubmit: (promotion: NewPromotionMappedToDeviceResDto) => void;
  promotions: CheckPromotionType[];
};

export const AddPromotionModal = ({
  closeModal,
  onSubmit,
  promotions,
}: AddPromotionModalProps) => {
  const [type, setType] = useState<PromotionType>(PromotionType.ONCE);
  const [periodRange, setPeriodRange] = useState<[Date | null, Date | null]>([
    new Date(),
    null,
  ]);
  const [weekDays, setWeekDays] = useState<number[]>([]);
  const [days, setDays] = useState<number[]>([]);
  const [timeRange, setTimeRange] = useState<[string | null, string | null]>([
    null,
    null,
  ]);
  const [timeRangeErrorMsg, setTimeRangeErrorMsg] = useState('');
  const [isNoEndDateEnable, setNoEndDateEnable] = useState(false);
  const [isAllDayEnable, setAllDayEnable] = useState(false);

  const { mutate: checkPromtion } = useCheckDevicePromotionMutation({
    onSuccess: () => {
      setTimeRangeErrorMsg('');
      const now = new Date();
      const promotion: NewPromotionMappedToDeviceResDto = {
        type,
        startPeriod: dateFormat(periodRange[0] === null ? now : periodRange[0]),
        endPeriod:
          periodRange[1] === null
            ? type === PromotionType.ONCE
              ? dateFormat(now)
              : null
            : dateFormat(periodRange[1]),
        startTime: timeRange[0] === null ? '00:00' : timeRange[0],
        endTime: timeRange[1] === null ? '24:00' : timeRange[1],
        promotionDates:
          type === PromotionType.WEEKLY
            ? weekDays.map((v) => ({
                date: v,
              }))
            : type === PromotionType.MONTHLY
              ? days.map((v) => ({ date: v }))
              : [],
      };
      onSubmit(promotion);
    },
    onError: () => setTimeRangeErrorMsg('이미 등록된 시간이에요.'),
  });

  const isSaveBtnEnable = useMemo(() => {
    switch (type) {
      case PromotionType.ONCE: {
        return periodRange[0] !== null &&
          (isAllDayEnable ||
            (timeRange[0] !== null &&
              timeRange[1] !== null &&
              hhmmToSecond(timeRange[0]) < hhmmToSecond(timeRange[1])))
          ? true
          : false;
      }

      case PromotionType.DAILY: {
        return (isNoEndDateEnable ||
          (periodRange[0] !== null && periodRange[1] !== null)) &&
          (isAllDayEnable ||
            (timeRange[0] !== null &&
              timeRange[1] !== null &&
              hhmmToSecond(timeRange[0]) < hhmmToSecond(timeRange[1])))
          ? true
          : false;
      }

      case PromotionType.WEEKLY: {
        return (isNoEndDateEnable ||
          (periodRange[0] !== null && periodRange[1] !== null)) &&
          (isAllDayEnable ||
            (timeRange[0] !== null &&
              timeRange[1] !== null &&
              hhmmToSecond(timeRange[0]) < hhmmToSecond(timeRange[1]))) &&
          !isArrEmpty(weekDays)
          ? true
          : false;
      }

      case PromotionType.MONTHLY: {
        return (isNoEndDateEnable ||
          (periodRange[0] !== null && periodRange[1] !== null)) &&
          (isAllDayEnable ||
            (timeRange[0] !== null &&
              timeRange[1] !== null &&
              hhmmToSecond(timeRange[0]) < hhmmToSecond(timeRange[1]))) &&
          !isArrEmpty(days)
          ? true
          : false;
      }

      default: {
        return false;
      }
    }
  }, [type, isAllDayEnable, isNoEndDateEnable, periodRange, timeRange]);

  const handleChangeType = useCallback((value: PromotionType) => {
    setType(value);
    if (value === PromotionType.ONCE) setPeriodRange((prev) => [prev[0], null]);
  }, []);

  const handlePeriodChange = useCallback(
    (v: [Date | null, Date | null]) => {
      if (isNoEndDateEnable) {
        setPeriodRange([v[0], null]);
      } else {
        setPeriodRange(v);
      }
    },
    [isNoEndDateEnable],
  );

  const handleNoEndDateChange = useCallback((checked: boolean) => {
    setNoEndDateEnable(checked);
    if (checked) setPeriodRange((prev) => [prev[0], null]);
  }, []);

  const validateTimeRange = useCallback(
    (start: string | null, end: string | null) => {
      if (start === null && end !== null) {
        setTimeRangeErrorMsg('시작시간을 입력해 주세요.');
      } else if (start !== null && end !== null) {
        const isStartOverThanEnd = hhmmToSecond(start) > hhmmToSecond(end);
        if (isStartOverThanEnd) {
          setTimeRangeErrorMsg(
            '종료 시각을 시작 시각보다 늦은 시각으로 입력해 주세요.',
          );
        } else {
          setTimeRangeErrorMsg('');
        }
      } else {
        setTimeRangeErrorMsg('');
      }
    },
    [],
  );

  const handleSave = () => {
    const now = new Date();
    checkPromtion([
      ...promotions,
      {
        type,
        startPeriod: dateFormat(periodRange[0] === null ? now : periodRange[0]),
        endPeriod:
          periodRange[1] === null
            ? type === PromotionType.ONCE
              ? dateFormat(now)
              : null
            : dateFormat(periodRange[1]),
        startTime: timeRange[0] === null ? '00:00' : timeRange[0],
        endTime: timeRange[1] === null ? '24:00' : timeRange[1],
        promotionDates:
          type === PromotionType.WEEKLY
            ? weekDays.map((v) => ({
                date: v,
              }))
            : type === PromotionType.MONTHLY
              ? days.map((v) => ({ date: v }))
              : [],
      },
    ]);
  };

  return (
    <Modal closeModal={closeModal}>
      <S.Container>
        <Modal.Header>프로모션 기간 설정</Modal.Header>
        <Modal.Content>
          <S.ContentContainer>
            <PromotionSettingSection title={'반복'}>
              <DropDown<PromotionType>
                options={Object.values(PromotionType).map((t) => ({
                  label: promotionTypeToKor(t),
                  value: t,
                }))}
                initialValue={{
                  label: promotionTypeToKor(PromotionType.ONCE),
                  value: PromotionType.ONCE,
                }}
                onSelect={handleChangeType}
              />
              {type === PromotionType.WEEKLY && (
                <>
                  <WeekDaysPicker value={weekDays} onChange={setWeekDays} />
                </>
              )}
              {type === PromotionType.MONTHLY && (
                <>
                  <DaysPicker value={days} onChange={setDays} />
                </>
              )}
            </PromotionSettingSection>
            <PromotionSettingSection title={'기간'}>
              <DatePicker
                selectRange={
                  (type === PromotionType.DAILY ||
                    type === PromotionType.WEEKLY ||
                    type === PromotionType.MONTHLY) &&
                  !isNoEndDateEnable
                }
                divider={type === PromotionType.ONCE ? undefined : '-'}
                value={periodRange}
                onChange={(v) => handlePeriodChange(v)}
              />
              {type !== PromotionType.ONCE && (
                <Checkbox
                  label="종료일 없음"
                  checked={isNoEndDateEnable}
                  onChange={(e) => handleNoEndDateChange(e.target.checked)}
                />
              )}
            </PromotionSettingSection>
            <PromotionSettingSection
              title={'재생 시각'}
              description={
                '24시간을 기준으로 콘텐츠가 재생될 정확한 시각을 입력해 주세요.'
              }
            >
              <S.TimeRangeBox>
                <S.TimeRange>
                  <TimePicker
                    width={163}
                    type={'point'}
                    buttonValue={timeRange[0]}
                    disabled={isAllDayEnable}
                    setButtonValue={(v) =>
                      setTimeRange((prev) => {
                        validateTimeRange(v, prev[1]);
                        return [v, prev[1]];
                      })
                    }
                    isValid={!!timeRangeErrorMsg}
                    name={`device_modify_startTime`}
                  />
                  ~
                  <TimePicker
                    width={163}
                    type={'point'}
                    buttonValue={timeRange[1]}
                    disabled={isAllDayEnable}
                    setButtonValue={(v) =>
                      setTimeRange((prev) => {
                        validateTimeRange(prev[0], v);
                        return [prev[0], v];
                      })
                    }
                    isValid={!!timeRangeErrorMsg}
                    name={`device_modify_endTime`}
                  />
                </S.TimeRange>
                <S.TimeRangeErrorMsg>{timeRangeErrorMsg}</S.TimeRangeErrorMsg>
              </S.TimeRangeBox>
              <Checkbox
                label="하루 종일"
                checked={isAllDayEnable}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    setTimeRange([null, null]);
                    setTimeRangeErrorMsg('');
                  }
                  setAllDayEnable(checked);
                }}
              />
            </PromotionSettingSection>
          </S.ContentContainer>
        </Modal.Content>
        <Modal.Footer>
          <Button onClick={closeModal} outlined colorType="secondary">
            취소
          </Button>
          <Button onClick={handleSave} disabled={!isSaveBtnEnable}>
            저장하기
          </Button>
        </Modal.Footer>
      </S.Container>
    </Modal>
  );
};

type PromotionSettingSectionProps = {
  title: string;
  description?: string;
} & PropsWithChildren;

const PromotionSettingSection = ({
  children,
  title,
  description,
}: PromotionSettingSectionProps) => {
  return (
    <S.SectionContainer>
      <S.SectionTitleArea>
        <S.SectionTitle>{title}</S.SectionTitle>
        {description && <S.SectionDec>{description}</S.SectionDec>}
      </S.SectionTitleArea>
      {children}
    </S.SectionContainer>
  );
};
