import { PALETTE, TYPOGRAPHY } from '@/themes';

import { styled } from 'styled-components';

export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const EmptyTitle = styled.div`
  margin-top: 12px;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.title4}
  font-weight: bold;
  text-align: center;
`;

export const EmptyDescription = styled.div`
  margin-bottom: 24px;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2}
  text-align: center;
`;
