import { PropsWithChildren } from 'react';
import { FrameContextConsumer } from 'react-frame-component';
import { StyleSheetManager } from 'styled-components';

import * as S from './index.style';

type PreviewIframeProps = PropsWithChildren;

export const PreviewIFrame = ({ children }: PreviewIframeProps) => {
  return (
    <S.Container>
      <S.IFrame
        title="popup preview"
        initialContent='<!DOCTYPE html><html><head>
      <style>
#popup-iframe,body {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100vw;
  height: 100dvh;
}

* {
  box-sizing: border-box;
}
      </style>
      </head><body><div id="popup-iframe"></div></body></html>'
        mountTarget="#popup-iframe"
      >
        <FrameContextConsumer>
          {(frameContext) => (
            <StyleSheetManager target={frameContext?.document?.head}>
              {children}
            </StyleSheetManager>
          )}
        </FrameContextConsumer>
      </S.IFrame>
      <S.IFrameOverlay />
    </S.Container>
  );
};
