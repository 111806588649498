import { Button, Modal, ModalLayoutProps } from '@/components';

import * as S from './index.style';

type DeleteModalProps = {
  onSubmit: () => void;
} & Pick<ModalLayoutProps, 'closeModal'>;

export const DeleteModal = ({ closeModal, onSubmit }: DeleteModalProps) => {
  const deleteButtonHandler = () => {
    onSubmit();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header>정말 삭제하시겠습니까?</Modal.Header>
      <Modal.Content>
        <S.ContentsContainer>
          삭제된 항목은 복구할 수 없습니다.
        </S.ContentsContainer>
      </Modal.Content>
      <Modal.Footer>
        <Button outlined colorType="secondary" onClick={closeModal}>
          취소
        </Button>
        <Button colorType="delete" onClick={deleteButtonHandler}>
          삭제
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
