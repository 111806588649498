import { DevicePopupResDto, PopupItemResDto } from '@/apis';

import {
  Button,
  Modal,
  PopupInfoCard,
  PopupPreviewCard,
  PopupRadioList,
  Search,
} from '@/components';

import { useState } from 'react';

import * as S from './index.style';

type AddPopupModalProps = {
  closeModal: () => void;
  popup: DevicePopupResDto | null;
  setPopup: (popup: DevicePopupResDto) => void;
};

export const AddPopupModal = ({
  closeModal,
  popup,
  setPopup,
}: AddPopupModalProps) => {
  const [popupSearch, onPopupSearch] = useState('');
  const [localPopup, setLocalPopup] = useState<DevicePopupResDto | null>(popup);
  const [focusPopup, setFocusPopup] = useState<PopupItemResDto>();

  const addOnClick = () => {
    if (localPopup) {
      setPopup(localPopup);
      closeModal();
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header isBorderBottom>
        <S.HeaderContainer>
          <S.HeaderTitle>팝업 레이어 추가하기</S.HeaderTitle>
          <Search
            placeHolder={'팝업 레이어 이름을 검색해 보세요.'}
            onSearch={onPopupSearch}
          />
        </S.HeaderContainer>
      </Modal.Header>
      <S.ContentContainer>
        <S.ContentWrapper>
          <PopupRadioList
            search={popupSearch}
            popup={localPopup}
            setPopup={setLocalPopup}
            onFocus={setFocusPopup}
          />
          <S.MainContent>
            {focusPopup && (
              <>
                <PopupInfoCard
                  type={focusPopup.type}
                  devices={focusPopup.devices}
                  createdAt={focusPopup.createdAt}
                  updatedAt={focusPopup.updatedAt}
                />
                <PopupPreviewCard
                  type={focusPopup.type}
                  url={focusPopup.url}
                  qrColor={focusPopup.qrColor}
                  fileUrl={
                    focusPopup.file?.originalUrl && focusPopup.file !== null
                      ? focusPopup.file?.originalUrl
                      : ''
                  }
                  description={focusPopup.description}
                  descriptionColor={focusPopup.descriptionColor}
                />
              </>
            )}
          </S.MainContent>
        </S.ContentWrapper>
      </S.ContentContainer>
      <Modal.Footer>
        <Button onClick={closeModal} outlined colorType="secondary">
          취소
        </Button>
        <Button onClick={addOnClick} disabled={!localPopup}>
          추가하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
