import { useOnClickOutside } from '@/hooks';

import { secondToTimeFormat } from '@/utils';

import { ChangeEvent, useRef, useState } from 'react';

type UseTimepickerReturnType = {
  IS_VALUE: boolean;
  IS_INITIAL: boolean;
  timeValue: string[] | null;
  settingTimeArr: string[];
  placeholder: string;
  isSetting: boolean;
  timepickerRef: React.RefObject<HTMLDivElement>;
  getTime: () => string | null;
  setExisting: (time: number) => void;
  handleTimeChange: (e: ChangeEvent<HTMLInputElement>, idx: number) => void;
  handleFocus: (idx: number) => void;
  handleBlur: (idx: number) => void;
  handleComplete: () => void;
  handleCancel: () => void;
  openSetting: () => void;
  closeSetting: () => void;
};

// 시간 설정 프로퍼티와 플레이스홀더 설정
export const TIME_PROPS = {
  settingTimeArr: ['시간', '분', '초'],
  placeholder: '시간 입력',
};
export const POINT_PROPS = {
  settingTimeArr: ['시', '분'],
  placeholder: '시각 입력',
};

export const useTimePicker = (
  type: 'time' | 'point', // 타입을 'time' 또는 'point'로 설정
  buttonValue: string,
  setButtonValue: (value: string | null) => void, // 버튼 값을 설정하는 함수
): UseTimepickerReturnType => {
  const [timeValue, setTimeValue] = useState<null | string[]>(null);
  const [prevTimeValue, setPrevTimeValue] = useState<null | string[]>(null); // 변경 전 값을 저장할 상태 변수 추가
  const [isSetting, setIsSetting] = useState(false);
  const timepickerRef = useRef(null);

  const IS_TIME = type === 'time'; // 타입이 'time'인지 확인
  const IS_VALUE = timeValue === null; // 시간 값이 초기값과 다른지 확인
  const IS_INITIAL = !IS_VALUE && timeValue.some((val) => val !== '00');
  const PROPS = IS_TIME ? TIME_PROPS : POINT_PROPS; // 타입에 따라 프로퍼티 설정

  // 시간 형식을 얻는 함수
  const getTime = () => {
    if (IS_VALUE) {
      return buttonValue;
    }

    const format = timeValue.map((val) => val.padStart(2, '0')).join(':');
    return IS_TIME ? format : format.slice(0, 5);
  };

  // 특정 인덱스의 값을 설정하는 함수
  const setValue = (idx: number, newValue: string) => {
    setTimeValue((prev) => {
      const updatedValues = prev === null ? ['00', '00', '00'] : [...prev];
      updatedValues[idx] = newValue;
      return updatedValues;
    });
  };

  // 기존 시간을 설정하는 함수
  const setExisting = (time: number) => {
    const format = secondToTimeFormat(time);
    setTimeValue(format.split(':'));
    setButtonValue(IS_TIME ? format : format.slice(0, 4));
  };

  // 입력 핸들러 -----------------------------------------------------------------------------
  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>, idx: number) =>
    setValue(idx, e.target.value.replaceAll(/\D/g, '')); // 숫자만 입력되도록 설정

  const handleFocus = (idx: number) => {
    if (timeValue === null || timeValue[idx] === '00') {
      setValue(idx, '');
    }
  }; // 포커스 시 초기값을 빈 값으로 설정

  const handleBlur = (idx: number) => {
    if (timeValue === null || timeValue[idx] === '') {
      setValue(idx, '00');
    }
  }; // 포커스 해제 시 빈 값일 경우 초기값으로 설정

  // 모달 핸들러 -----------------------------------------------------------------------------
  const openSetting = () => {
    setPrevTimeValue(timeValue);
    setIsSetting(true);
  }; // 설정 모달 열기
  const closeSetting = () => setIsSetting(false); // 설정 모달 닫기

  // 완료 버튼
  const handleComplete = () => {
    const value = getTime();
    setButtonValue(value); // 버튼 값 설정
    setTimeValue(value.split(':')); // 시간 값을 초기값으로 설정
    closeSetting(); // 설정 모달 닫기
  };

  // 취소 버튼
  const handleCancel = () => {
    setTimeValue(prevTimeValue); // 시간 값을 초기값으로 설정
    closeSetting(); // 설정 모달 닫기
  };

  useOnClickOutside(timepickerRef, handleCancel); // 외부 클릭 시 핸들러 설정

  return {
    ...PROPS,
    IS_VALUE,
    IS_INITIAL,
    timeValue,
    isSetting,
    timepickerRef,
    getTime,
    setExisting,
    handleTimeChange,
    handleFocus,
    handleBlur,
    handleComplete,
    handleCancel,
    openSetting,
    closeSetting,
  };
};
