import { PopupEntity } from '@/types';

import { getPopupById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useQuery } from '@tanstack/react-query';

/**
 * GET /popup/${popupId}
 *
 * PopupId 팝업을 조회하는 api입니다.
 *
 * 팝업 상세정보를 가져오는 모달, 수정 페이지 등에서 사용됩니다.
 */
export const usePopupQuery = ({
  popupId,
  enabled = true,
}: {
  popupId: PopupEntity['id'];
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: QUERY_KEY.POPUPLAYER_ID(popupId),
    gcTime: 0,
    queryFn: () => getPopupById(popupId),
    enabled,
  });
};
