import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 25rem;
`;
export const Description = styled.div`
  margin-bottom: 0.875rem;

  ${TYPOGRAPHY.body2};
  color: ${PALETTE.text.normal};
  font-weight: 500;
`;
