import { PALETTE } from '@/themes';

import styled, { css, keyframes } from 'styled-components';

const indeterminateAnimation = keyframes`
   0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 9999px;

  background-color: ${PALETTE.component.light};
  overflow: hidden;
`;
export const Indeterminate = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${PALETTE.primary.normal};

  animation: ${indeterminateAnimation} 1s infinite linear;
  transition-property: all;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  flex: 1 1 0%;
  transform-origin: 0% 50%;
`;
export const Indicator = styled.div<{ $color: 'success' | 'error' | 'info' }>`
  flex: 1 1 0%;
  height: 100%;
  width: 100%;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  ${({ $color }) =>
    $color === 'success'
      ? css`
          background-color: ${PALETTE.status.positive};
        `
      : $color === 'error'
        ? css`
            background-color: ${PALETTE.status.negative};
          `
        : css`
            background-color: ${PALETTE.primary.normal};
          `}
`;
