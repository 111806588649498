import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: inline-block;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.375rem;
  border-radius: 10000px;

  background-color: ${PALETTE.primary.light.normal};
  gap: 0.5rem;
`;
export const Label = styled.div<{ $maxWidth?: number }>`
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth}px;
      overflow: hidden;
    `};
  ${TYPOGRAPHY.body2}
  color: ${PALETTE.primary.normal};
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
