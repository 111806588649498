import styled from 'styled-components';

export const MainLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  height: 100vh;
`;

export const MainContent = styled.main`
  display: grid;
  grid-template-rows: 127px 1fr;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
