import { DeviceDetailResDto } from '@/apis';

import {
  Button,
  DeviceCheckboxList,
  DeviceContentsPreviewCard,
  DeviceInfoCard,
  DeviceRegisteredModal,
  Modal,
  PillsGroup,
  PlaylistNotFoundModal,
  Search,
  useModal,
} from '@/components';

import { useAddPlaylistToDeviceMutation } from '@/hooks';

import { isArrEmpty } from '@/utils';

import { PATH } from '@/constants';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type PlaylistDeviceRegistrationModalProps = {
  playlistId: string;
  closeModal: () => void;
};

export const PlaylistDeviceRegistrationModal = ({
  playlistId,
  closeModal,
}: PlaylistDeviceRegistrationModalProps) => {
  const navigate = useNavigate();
  const [deviceSearch, onDeviceSearch] = useState('');
  const [focusDevice, setFocusDevice] = useState<DeviceDetailResDto>();
  const [selectedDevice, setSelectedDevice] = useState<
    Array<DeviceDetailResDto>
  >([]);
  const { mutate: addPlaylistToDevices } = useAddPlaylistToDeviceMutation();
  const deviceRegisteredModal = useModal(() => (
    <DeviceRegisteredModal
      closeModal={deviceRegisteredModal.closeModal}
      onSubmit={() => {
        deviceRegisteredModal.closeModal();
        navigate(PATH.DEVICE_PAGE);
      }}
    />
  ));
  const deviceConflictModal = useModal(() => (
    <PlaylistNotFoundModal
      closeModal={deviceConflictModal.closeModal}
      onSubmit={() => {
        playlistRegister();
      }}
    />
  ));

  const playlistRegister = () => {
    addPlaylistToDevices(
      {
        deviceIds: selectedDevice.map((v) => v.id),
        playlistId,
      },
      {
        onSuccess: () => {
          closeModal();
          deviceConflictModal.closeModal();
          deviceRegisteredModal.openModal();
        },
      },
    );
  };

  const removeSelectedDevice = (value: string) => {
    setSelectedDevice((prev) => prev.filter((v) => v.id !== value));
  };

  const onDisabled = (device: DeviceDetailResDto) => {
    return device.devicePlayLists.some((v) => v.playList.id === playlistId);
  };
  const onChecked = (device: DeviceDetailResDto) => {
    return selectedDevice.some((v) => v.id === device.id);
  };
  const registred = () => {
    const isPlaylistExist = selectedDevice.some((v) =>
      isArrEmpty(v.devicePlayLists),
    );
    if (isPlaylistExist) {
      deviceConflictModal.openModal();
      return;
    } else {
      playlistRegister();
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header isBorderBottom>
        <S.HeaderContainer>
          <S.HeaderTitle>디바이스에 등록하기</S.HeaderTitle>
          <Search
            placeHolder={'디바이스 이름을 검색해 보세요.'}
            onSearch={onDeviceSearch}
          />
        </S.HeaderContainer>
      </Modal.Header>
      <S.ContentContainer>
        {!isArrEmpty(selectedDevice) && (
          <PillsGroup
            pills={selectedDevice.map((v) => ({
              label: v.name,
              iconClick: () => removeSelectedDevice(v.id),
            }))}
          />
        )}
        <S.ContentWrapper>
          <DeviceCheckboxList
            search={deviceSearch}
            setSelected={setSelectedDevice}
            disabled={onDisabled}
            onChecked={onChecked}
            onFocus={setFocusDevice}
          />
          <S.MainContent>
            {focusDevice && (
              <DeviceInfoCard
                name={focusDevice?.name}
                model={focusDevice?.model}
                store={focusDevice?.store}
                status={focusDevice?.status}
                popup={
                  focusDevice?.popup === null ? undefined : focusDevice?.popup
                }
                promotions={focusDevice?.promotions}
              />
            )}
            {focusDevice?.devicePlayLists && (
              <DeviceContentsPreviewCard
                playlist={focusDevice?.devicePlayLists ?? []}
              />
            )}
          </S.MainContent>
        </S.ContentWrapper>
      </S.ContentContainer>
      <Modal.Footer isBorderTop>
        <Button outlined colorType="secondary" onClick={closeModal}>
          취소
        </Button>
        <Button disabled={isArrEmpty(selectedDevice)} onClick={registred}>
          등록하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
