import { PALETTE } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div<{ $width?: number; $height?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: solid 1px ${PALETTE.line.normal};
  border-radius: 6px;

  ${({ $width }) =>
    $width
      ? css`
          width: ${$width}px;
        `
      : css`
          width: 100%;
        `}

  ${({ $height }) =>
    $height
      ? css`
          height: ${$height}px;
        `
      : css`
          height: 100%;
        `}
`;
