import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const DeviceEmpty = styled.div`
  width: 100%;
  height: calc(100vh - 126px);
`;

export const MainBox = styled.main`
  width: 100%;
  margin: 0 auto;

  overflow-y: scroll;
`;

export const DeviceModifyGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  min-height: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;

  background-color: ${PALETTE.background.light};

  > *:nth-of-type(2) {
    height: 100%;

    background-color: ${PALETTE.background.normal};
  }
`;
