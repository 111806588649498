import { PALETTE } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const DayButton = styled.button<{ $isSelected: boolean }>`
  flex: 1;
  padding: 0.375rem 0.125rem;
  border-radius: 5px;

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          border: 1px solid ${PALETTE.primary.normal};

          color: ${PALETTE.primary.normal};
        `
      : css`
          border: 1px solid ${PALETTE.line.normal};

          color: ${PALETTE.text.caption};
        `}
  background-color: ${PALETTE.static.white};

  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.interaction.hover.light};
  }
`;
