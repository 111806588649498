import { ButtonHTMLAttributes, PropsWithChildren, forwardRef } from 'react';

import * as S from './index.style';

type SvgButtonProps = PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const SvgButton = forwardRef<HTMLButtonElement, SvgButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <S.SvgButtonContainer type={props.type ?? 'button'} {...props} ref={ref}>
        {children}
      </S.SvgButtonContainer>
    );
  },
);
