import { ArrowButton } from './arrow';
import { Button as Default } from './default';
import { Loading } from './loading';
import { SvgButton } from './svg';
import { TextButton } from './text';

export type ButtonLoadingTypes = 'normal' | 'loading' | 'complete';

const Button = Default as typeof Default & {
  Arrow: typeof ArrowButton;
  Text: typeof TextButton;
  Svg: typeof SvgButton;
  Loading: typeof Loading;
};

Button.Arrow = ArrowButton;
Button.Text = TextButton;
Button.Svg = SvgButton;
Button.Loading = Loading;

export { Button };
