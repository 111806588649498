import { Button, Header } from '@/components';

import { PATH } from '@/constants';

import { PropsWithChildren } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type PopupLayoutProps = {
  onSearch: (value: string) => void;
} & PropsWithChildren;

export const PopupLayout = ({ children, onSearch }: PopupLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        title="팝업 레이어"
        search={{ placeHolder: '팝업레이어 이름을 검색해 보세요', onSearch }}
      >
        <Button
          icon={<IoAddCircleOutline size={24} />}
          colorType="primary"
          size="medium"
          onClick={() =>
            navigate(PATH.POPUP_MODIFY_PAGE('new'), {
              state: {
                prevUrl: PATH.POPUP_PAGE,
              },
            })
          }
        >
          새 팝업 레이어 추가
        </Button>
      </Header>
      <S.Main>{children}</S.Main>
    </>
  );
};
