import { DeviceIcon } from '@/components';

import * as S from './index.style';

type DeviceNameTdProps = {
  model: string;
  name: string;
};

export const DeviceNameTd = ({ model, name }: DeviceNameTdProps) => {
  return (
    <S.DeviceNameTdContainer>
      <DeviceIcon os={model} />
      <S.DeviceNameContainer>{name}</S.DeviceNameContainer>
    </S.DeviceNameTdContainer>
  );
};
