import { Button } from '@/components';

import { timeFormatToSecond } from '@/utils';

import { BOXSHADOW, PALETTE, ZINDEX } from '@/themes';

import { useEffect, useState } from 'react';
import { IoTimeOutline } from 'react-icons/io5';
import { Popover } from 'react-tiny-popover';
import { Tooltip } from 'react-tooltip';

import * as S from './index.style';
import { useTimePicker } from './useTimePicker';

type TimepickerProps = {
  name: string;
  type: 'time' | 'point';
  initialTime?: number;
  disabled?: boolean;
  buttonValue: string | null;
  setButtonValue: (value: string | null) => void;
  width?: number;
  isValid?: boolean;
  tooltipMessage?: string;
};

export const TimePicker = ({
  name,
  type,
  buttonValue,
  setButtonValue,
  initialTime,
  disabled = false,
  width = 126,
  isValid = false,
  tooltipMessage,
}: TimepickerProps) => {
  const t = useTimePicker(type, buttonValue || '00:00', setButtonValue);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const COLOR = (): string => {
    if (disabled) {
      return PALETTE.text.disable;
    }
    if ((!buttonValue || buttonValue.length === 0) && t.IS_VALUE) {
      return PALETTE.component.normal;
    }

    return PALETTE.static.black;
  };

  useEffect(() => {
    if (initialTime && initialTime > 0) {
      t.setExisting(initialTime);
    }
  }, [initialTime]);

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        padding={2}
        positions={['bottom', 'top']}
        align="start"
        onClickOutside={() => setIsPopoverOpen(false)}
        content={
          <S.SettingWrapper>
            <S.TimeContainer>
              {t.settingTimeArr.map((item, idx) => (
                <S.TimeItem key={`${name}_${item}`}>
                  <S.TimeLabel htmlFor={`${name}_${idx.toString()}`}>
                    {item}
                  </S.TimeLabel>
                  <S.TimeInputWrapper>
                    <S.TimeInput
                      id={`${name}_${idx.toString()}`}
                      maxLength={2}
                      value={
                        t.timeValue === null
                          ? buttonValue?.split(':')[idx]
                          : t.timeValue[idx]
                      }
                      onChange={(e) => t.handleTimeChange(e, idx)}
                      onFocus={() => t.handleFocus(idx)}
                      onBlur={() => t.handleBlur(idx)}
                    />
                    {idx < t.settingTimeArr.length - 1 && <div>:</div>}
                  </S.TimeInputWrapper>
                </S.TimeItem>
              ))}
            </S.TimeContainer>
            <S.ButtonContainer>
              <Button
                outlined={true}
                colorType={'secondary'}
                size="small"
                className="no-line"
                onClick={() => setIsPopoverOpen(false)}
              >
                취소
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setIsPopoverOpen(false);
                  t.handleComplete();
                }}
                disabled={
                  timeFormatToSecond((t.timeValue || []).join(':')) > 86_400
                }
              >
                완료
              </Button>
            </S.ButtonContainer>
          </S.SettingWrapper>
        }
        containerStyle={{
          zIndex: `${ZINDEX.popover}`,
        }}
      >
        <S.ButtonWrapper
          id={`timepicker-tooltip_${name}`}
          onClick={() => setIsPopoverOpen((prev) => (disabled ? false : !prev))}
          $color={COLOR()}
          $disabled={disabled}
          $width={width}
          $isValid={isValid}
        >
          <IoTimeOutline size={20} />
          <S.ButtonValue>
            {buttonValue && buttonValue?.length > 0
              ? buttonValue
              : t.placeholder}
          </S.ButtonValue>
        </S.ButtonWrapper>
      </Popover>
      {!disabled && tooltipMessage && (
        <Tooltip
          anchorSelect={`#timepicker-tooltip_${name}`}
          place="bottom-start"
          style={{
            backgroundColor: PALETTE.static.white,
            color: PALETTE.static.white,
            padding: '4px 8px',
            borderRadius: '4px',
            boxShadow: BOXSHADOW.toolTip,
          }}
        >
          <S.ToolTipText>{tooltipMessage}</S.ToolTipText>
        </Tooltip>
      )}
    </>
  );
};
