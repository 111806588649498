import * as S from './index.style';

type ContentsTdProps = {
  defaultPlayListName: string;
  otherPlayListCount: number;
};

export const ContentsTd = ({
  defaultPlayListName,
  otherPlayListCount,
}: ContentsTdProps) => {
  const setContents = () => {
    if (!defaultPlayListName) {
      return '-';
    } else if (defaultPlayListName && otherPlayListCount <= 0) {
      return (
        <S.ContentsPlaylists>
          <S.DefaultContentsName $isOneContents={true}>
            {defaultPlayListName}
          </S.DefaultContentsName>
        </S.ContentsPlaylists>
      );
    } else {
      return (
        <S.ContentsPlaylists>
          <S.DefaultContentsName $isOneContents={false}>
            {defaultPlayListName}
          </S.DefaultContentsName>
          <S.AddedContents>+{otherPlayListCount}</S.AddedContents>
        </S.ContentsPlaylists>
      );
    }
  };

  return <S.ContentsTdContainer>{setContents()}</S.ContentsTdContainer>;
};
