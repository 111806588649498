import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { PropsWithChildren } from 'react';

import { OrderId } from '.';

type SortableItemProps<T> = {
  id: OrderId;
  item: T;
  renderItem: (item: T) => React.ReactNode;
  handleEnable?: boolean;
} & PropsWithChildren;

export const SortableItem = <T,>({
  id,
  item,
  renderItem,
  handleEnable,
}: SortableItemProps<T>) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(handleEnable ? {} : listeners)}
    >
      {renderItem(item)}
    </div>
  );
};
