import { FileType } from '@/types';

import {
  ButtonLoadingTypes,
  NameModify,
  PlaylistModifyContents,
  PlaylistModifyLayout,
  UploadStateModal,
  useModal,
} from '@/components';

import {
  useAddPlaylistMutation,
  useModifyPlaylistMutation,
  useMultipartUploadMutation,
  usePlayListQuery,
} from '@/hooks';

import {
  getVideoDuration,
  getVideoThumbnail,
  hhmmssToSecond,
  isArrEmpty,
} from '@/utils';

import { PATH } from '@/constants';

import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

export type PlaylistContentType = {
  id: string;
  order: number;
  playTime: number;
  file: {
    id?: string;
    originalName?: string;
    originalUrl?: string;
    thumbnailUrl?: string;
    encodedUrl?: string;
    type?: FileType;
  };
  newFile?: File;
};

const buttonReducer = (_: ButtonLoadingTypes, action: ButtonLoadingTypes) =>
  action;

const NEW_CONTENT_ID = 'NEW_';
const COPY_CONTENT_ID = 'COPY_';

export const PlaylistModify = () => {
  const navigate = useNavigate();
  const params = useParams();
  const playlistId = params.playlistId ?? '';
  const isNew = playlistId === 'new';
  const { data } = usePlayListQuery({ playlistId, enabled: !isNew });
  const [name, setName] = useState('');
  const [contents, setContents] = useState<PlaylistContentType[]>([]);
  const [buttonState, buttonStateDispatch] = useReducer(
    buttonReducer,
    'normal',
  );
  const isSaveDisabled =
    name.length === 0 ||
    isArrEmpty(contents) ||
    contents.some((pl) => pl.playTime == 0) ||
    JSON.stringify({ name: data?.name, contents: data?.playContents }) ===
      JSON.stringify({ name, contents }) ||
    buttonState !== 'normal';

  const uploadModal = useModal(() => (
    <UploadStateModal closeModal={uploadModal.closeModal} />
  ));

  const { mutate: addPlaylist } = useAddPlaylistMutation({
    playlistId,
    onMutate: async () => buttonStateDispatch('loading'),
    onSuccess: (newId) => {
      const successTimeout = setTimeout(() => {
        buttonStateDispatch('complete');
        const timeout = setTimeout(() => {
          buttonStateDispatch('normal');
          clearTimeout(timeout);
        }, 1000);
        navigate(PATH.PLAYLIST_MODIFY_PAGE(newId), { replace: true });
        clearTimeout(successTimeout);
      }, 500);
    },
    onError: () => buttonStateDispatch('normal'),
  });

  const { mutate: modifyPlaylist } = useModifyPlaylistMutation({
    playlistId,
    onMutate: async () => buttonStateDispatch('loading'),
    onSuccess: () => {
      const successTimeout = setTimeout(() => {
        buttonStateDispatch('complete');
        const timeout = setTimeout(() => {
          buttonStateDispatch('normal');
          clearTimeout(timeout);
        }, 1000);
        clearTimeout(successTimeout);
      }, 500);
    },
    onError: () => buttonStateDispatch('normal'),
  });

  const { mutateAsync: uploadFile } = useMultipartUploadMutation();

  const addContentsHandler = async (files: File[]) => {
    const addContents: PlaylistContentType[] = [];
    for (const f of files) {
      const isVideo = f.type.includes('video');
      const isImage = f.type.includes('image');
      const blobFileUrl = URL.createObjectURL(f);
      const thumbnailUrl = isVideo
        ? await getVideoThumbnail({
            url: blobFileUrl,
            toBase64: true,
          })
        : blobFileUrl;
      const playTime = isVideo ? await getVideoDuration(blobFileUrl) : 0;
      addContents.push({
        id: `${NEW_CONTENT_ID}${v4()}`,
        order: contents.length,
        playTime,
        file: {
          originalName: f.name,
          thumbnailUrl: thumbnailUrl,
          type: (isImage && 'image') || (isVideo && 'video'),
        },
        newFile: Object.assign(f, {
          preview: thumbnailUrl,
        }),
      } as PlaylistContentType);
    }

    setContents((prev) => [...prev, ...addContents]);
  };

  const saveContentsHandler = async () => {
    // 파일 업로드 및 ID 매칭하여 결과 값 Map에 저장
    const files = contents.filter((v) => !!v.newFile);
    const filesMap = new Map();
    if (!isArrEmpty(files)) {
      uploadModal.openModal();
      const uploadedList = await uploadFile(
        files.map((v) => ({
          file: v.newFile as File,
          id: v.id,
          uploadProgress: 0,
          uploadStatus: 'idle',
        })),
      );
      uploadedList.map((v) => {
        if (!v) return;
        const { id, ...other } = v;
        filesMap.set(id, other);
      });
    }

    const newContents = contents.map((v) => {
      if (v.id.includes(COPY_CONTENT_ID)) {
        return v.file.id
          ? // 기존 콘텐츠 복사
            {
              ...v,
              id: undefined,
              file: { id: v.file.id },
              newFile: undefined,
            }
          : // 추가 콘텐츠 복사F
            {
              ...v,
              id: undefined,
              file: filesMap.get(v.id),
              newFile: undefined,
            };
      } else if (v.id.includes(NEW_CONTENT_ID)) {
        // 새로 추가한 콘텐츠
        return {
          ...v,
          id: undefined,
          file: filesMap.get(v.id),
          newFile: undefined,
        };
      } else {
        // 유지
        return {
          ...v,
          file: { ...v.file },
          newFile: undefined,
        };
      }
    });

    isNew
      ? addPlaylist({ name, contents: newContents })
      : modifyPlaylist({ name, contents: newContents });
  };

  const playTimeHandler = (contentId: string, time: string) => {
    setContents((prev) =>
      prev.map((v) =>
        v.id === contentId
          ? { ...v, playTime: hhmmssToSecond(time), file: { ...v.file } }
          : v,
      ),
    );
  };

  const contentOrderHandler = (updatedContents: PlaylistContentType[]) => {
    setContents(updatedContents);
  };

  const copyContentHandler = (contentId: string) => {
    setContents((prev) => {
      const copyContent = prev.find((v) => v.id === contentId);
      if (!copyContent) return prev;
      return [
        ...prev,
        {
          ...copyContent,
          id: `${COPY_CONTENT_ID}${copyContent.id}`,
          order: prev.length,
        },
      ];
    });
  };

  const deleteContentHandler = (contentId: string) => {
    setContents((prev) => prev.filter((v) => v.id !== contentId));
  };

  useEffect(() => {
    if (data) {
      setName(data.name ?? '');
      setContents(data.playContents ?? []);
    }
  }, [data]);

  return (
    <PlaylistModifyLayout
      isSaveDisabled={isSaveDisabled}
      buttonState={buttonState}
      addContents={addContentsHandler}
      saveContents={saveContentsHandler}
    >
      <NameModify
        playlistId={playlistId}
        defaultValue={data?.name ?? ''}
        setName={setName}
      />
      <PlaylistModifyContents
        contents={contents}
        setPlayTime={playTimeHandler}
        updateContentOrder={contentOrderHandler}
        addContents={addContentsHandler}
        copyContent={copyContentHandler}
        deleteContent={deleteContentHandler}
      />
    </PlaylistModifyLayout>
  );
};
