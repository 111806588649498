import { PopupExisitFile } from '@/apis';

import { Button } from '@/components';

import { PALETTE } from '@/themes';

import { useRef, useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';

import * as S from './index.style';

type LayerImageUploaderProps = {
  file: File | PopupExisitFile | undefined;
  setFile: (file: File | undefined) => void;
  disabled?: boolean;
};

export const LayerImageUploader = ({
  file,
  setFile,
  disabled = false,
}: LayerImageUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(
    (file as PopupExisitFile)?.originalName ?? (file as File)?.name ?? '',
  );

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFileName(files[0].name);
      setFile(files[0]);
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFile(undefined);
  };

  return (
    <S.Container>
      <S.ImageNameContainer $disabled={disabled}>
        {file && (
          <>
            <S.ImageName>{fileName}</S.ImageName>
            {!disabled && (
              <Button.Svg onClick={handleFileRemove}>
                <IoCloseCircle size={24} color={PALETTE.component.normal} />
              </Button.Svg>
            )}
          </>
        )}
      </S.ImageNameContainer>
      <Button
        colorType="secondary"
        disabled={disabled}
        onClick={handleButtonClick}
      >
        추가하기
      </Button>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </S.Container>
  );
};
