import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Floor = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px 0;
  border: 2px solid #808080;
  border-radius: 5px;

  background-color: transparent;
  outline: none;

  color: #242424;
  font-size: 14px;
  font-family: inherit;
  text-align: center;
`;

export const Label = styled.label`
  color: #242424;
  font-size: 14px;
  text-align: center;
`;
