/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PageMetaDto,
  PromotionMappedToDeviceResDto,
  StatusType,
} from '@/types';

import {
  DeviceListItemResDto,
  DeviceListResDto,
  PlayListItemResDto,
  deleteDeviceById,
} from '@/apis';

import {
  Button,
  Checkbox,
  DeleteModal,
  DropDown,
  Pagination,
  Status,
  useModal,
} from '@/components';

import { TABLE_DROPDOWN_OPTIONS } from '@/constants';

import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
} from '@tanstack/react-query';
import {
  CellContext,
  ColumnDef,
  PaginationState,
  RowSelectionState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';

import { ContentsTd } from '../table/contentsTd';
import { DeviceNameTd } from '../table/deviceNameTd';
import { DeviceTable } from '../table/deviceTable';
import { TableTooltip } from '../table/tableTooltip';
import * as S from './index.style';

type DeviceTableLayoutProps = {
  data: DeviceListResDto | undefined;
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<DeviceListResDto, Error>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

export const DeviceTableLayout = ({
  data,
  refetch,
  pagination,
  setPagination,
}: DeviceTableLayoutProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deviceData, setDeviceData] = useState<DeviceListItemResDto[]>([]);

  const { mutate: deleteDevice } = useMutation<unknown, unknown, string, any>({
    mutationFn: (ids: string) => deleteDeviceById(ids),
    onSuccess: () => {
      refetch();
      setRowSelection({});
    },
  });

  const columns: ColumnDef<DeviceListItemResDto, any>[] = [
    {
      id: 'select',
      accessorKey: 'select',
      header: ({ table }) => {
        const {
          getIsAllPageRowsSelected,
          getToggleAllPageRowsSelectedHandler,
        } = table;
        return (
          <Checkbox
            name="allSelect"
            value="all"
            readOnly
            checked={getIsAllPageRowsSelected()}
            onChange={getToggleAllPageRowsSelectedHandler()}
          />
        );
      },
      cell: ({ row }) => {
        const { id, getIsSelected, getToggleSelectedHandler } = row;
        return (
          <Checkbox
            name={id}
            checked={getIsSelected()}
            readOnly
            value={id}
            onChange={getToggleSelectedHandler()}
          />
        );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: '이름',
      cell: (info: CellContext<DeviceListItemResDto, string>) => (
        <DeviceNameTd model={info.row.original.model} name={info.getValue()} />
      ),
    },
    {
      id: 'storeName',
      accessorKey: 'storeName',
      header: '매장',
      cell: (info: CellContext<DeviceListItemResDto, string>) => (
        <S.DeviceTextTd>{info.getValue() || '-'}</S.DeviceTextTd>
      ),
    },
    {
      id: 'playlists',
      accessorKey: 'playlists',
      header: '재생 목록',
      cell: (
        info: CellContext<DeviceListItemResDto, Array<PlayListItemResDto>>,
      ) => (
        <ContentsTd
          defaultPlayListName={info.row.original.defaultPlayListName}
          otherPlayListCount={info.row.original.otherPlayListCount}
        />
      ),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: '프로모션 상태',
      cell: (info: CellContext<DeviceListItemResDto, string>) => (
        <Status type={info.renderValue() as keyof typeof StatusType} />
      ),
    },
    {
      id: 'popupName',
      accessorKey: 'popupName',
      header: '팝업 레이어',
      cell: (
        info: CellContext<
          DeviceListItemResDto,
          PromotionMappedToDeviceResDto[]
        >,
      ) => info.getValue() ?? '-',
    },
    {
      id: 'tooltip',
      accessorKey: 'tooltip',
      header: '',
      cell: (info: CellContext<DeviceListItemResDto, unknown>) => (
        <TableTooltip
          id={info.row.original.id}
          pagination={pagination}
          deleteDevice={deleteDevice}
          setDeviceData={setDeviceData}
        />
      ),
    },
  ];

  const table = useReactTable({
    data: deviceData,
    columns,
    state: { rowSelection, pagination },
    enableRowSelection: true,
    autoResetPageIndex: false,
    autoResetAll: false,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
  });

  // Table handler
  const pageSizeHandler = (value: number) => {
    if (table.getState().pagination.pageSize !== value) {
      table.setPageSize(value);
      table.setPageIndex(1);
    }
  };

  const deleteMultipleHandler = () => {
    const ids = Object.keys(rowSelection).join(', ');
    deleteDevice(ids);
  };

  const deleteModal = useModal(() => (
    <DeleteModal
      onSubmit={deleteMultipleHandler}
      closeModal={deleteModal.closeModal}
    />
  ));

  useEffect(() => {
    if (data && data?.data) {
      setDeviceData(data.data);
    }
  }, [data]);

  return (
    <S.TableContainer>
      <S.TableHeader>
        <S.DropdownWrapper>
          <DropDown<number>
            options={TABLE_DROPDOWN_OPTIONS}
            onSelect={pageSizeHandler}
            initialValue={TABLE_DROPDOWN_OPTIONS.find(
              (op) => op.value === pagination.pageSize,
            )}
          />
        </S.DropdownWrapper>
        <S.HandlerButtonContainer>
          <Button
            outlined={true}
            colorType={'secondary'}
            icon={<IoTrashOutline />}
            onClick={deleteModal.openModal}
            disabled={Object.keys(rowSelection).length <= 0}
          >
            삭제
          </Button>
        </S.HandlerButtonContainer>
      </S.TableHeader>
      <DeviceTable table={table} rowSelection={rowSelection} />
      <S.PaginationWrapper>
        <Pagination<DeviceListItemResDto>
          key={data?.meta?.pageCount ?? 0}
          {...table}
          {...(data?.meta as PageMetaDto)}
          take={1}
        />
      </S.PaginationWrapper>
    </S.TableContainer>
  );
};
