import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Header = styled.div`
  padding-top: 0.5rem;

  ${TYPOGRAPHY.body1};
  color: ${PALETTE.text.normal};
  font-weight: 700;
`;
export const Container = styled.div`
  overflow: hidden;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
`;
