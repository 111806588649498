import { refreshAccessToken } from '@/apis/auth';

import { jwtDecode } from 'jwt-decode';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type AuthUser = { loginId: string; code: string };
type AuthState = {
  isAuthenticated: boolean;

  accessToken: string | null;
  refreshToken: string | null;
  tokenExpires: number | null;
  user: AuthUser | null;
};
type AuthStore = AuthState & {
  setUser: (user: AuthUser) => void;
  setToken: (authValues: Partial<AuthState>) => void;
  checkAndUpdateToken: () => Promise<void> | undefined;
  updateToken: () => Promise<void> | undefined;
  resetAuth: () => void;
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  tokenExpires: null,
  user: null,
};

export const useAuth = create(
  persist<AuthStore>(
    (set, get) => ({
      ...initialState,
      setUser: (user: AuthUser) => {
        set((state) => {
          return {
            ...state,
            user,
          };
        });
      },
      setToken: (authValues: Partial<Omit<AuthState, 'tokenExpires'>>) => {
        set((state) => {
          const jwtExp = authValues.accessToken
            ? jwtDecode(authValues.accessToken).exp
            : undefined;
          return {
            ...state,
            ...authValues,
            isAuthenticated: true,
            tokenExpires: jwtExp ? jwtExp * 1000 : null,
          };
        });
      },
      checkAndUpdateToken: () => {
        const accessToken = get().accessToken;
        const refreshToken = get().refreshToken;
        const tokenExpires = get().tokenExpires;
        if (
          refreshToken &&
          (!accessToken || (tokenExpires && tokenExpires <= Date.now()))
        ) {
          return refreshAccessToken(refreshToken)
            .then((res) => {
              set(() => {
                const jwtExp = res.data.accessToken
                  ? jwtDecode(res.data.accessToken).exp
                  : undefined;

                return {
                  isAuthenticated: true,
                  accessToken: res.data.accessToken,
                  refreshToken: res.data.refreshToken,
                  tokenExpires: jwtExp ? jwtExp * 1000 : null,
                };
              });
            })
            .catch((error) => {
              get().resetAuth();
              console.error('로그인이 만료 되었습니다.', error);
            });
        }
      },
      updateToken: () => {
        const refreshToken = get().refreshToken;
        if (!refreshToken) {
          set(() => ({
            isAuthenticated: false,
            accessToken: null,
            refreshToken: null,
            tokenExpires: null,
          }));
          return;
        }
        return refreshAccessToken(refreshToken)
          .then((res) => {
            set(() => {
              const jwtExp = res.data.accessToken
                ? jwtDecode(res.data.accessToken).exp
                : undefined;

              return {
                isAuthenticated: true,
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
                tokenExpires: jwtExp ? jwtExp * 1000 : null,
              };
            });
          })
          .catch((error) => {
            get().resetAuth();
            console.error('로그인이 만료 되었습니다.', error);
          });
      },
      resetAuth: () => set(initialState),
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
