import { PlayContentResDto } from '@/types';

import { PlayListItemResDto } from '@/apis';

import { Modal, PlaylistAccordion } from '@/components';

import * as S from './index.style';

type PreviewContentsModalProps = {
  playContentsList?: Array<
    Pick<
      PlayListItemResDto,
      'name' | 'totalPlayTime' | 'contentCount' | 'playContents'
    >
  >;
  playContents?: Array<PlayContentResDto>;
  closeModal: () => void;
};

export const PreviewContentsModal = ({
  playContentsList,
  playContents,
  closeModal,
}: PreviewContentsModalProps) => {
  return (
    <Modal closeModal={closeModal}>
      <Modal.Header closeButtonVisible isBorderBottom>
        등록된 콘텐츠 미리보기
      </Modal.Header>
      <S.PreviewWrapper>
        {playContentsList &&
          playContentsList.map((v, i) => (
            <PlaylistAccordion
              key={i}
              name={v.name}
              totalPlayTime={v.totalPlayTime}
              contentCount={v.contentCount}
              playContents={v.playContents}
            />
          ))}
        {playContents &&
          playContents.map((v, i) => (
            <PlaylistAccordion.Item
              key={i}
              thumbnailUrl={v.file.thumbnailUrl}
              originalName={v.file.originalName}
              type={v.file.type}
            />
          ))}
      </S.PreviewWrapper>
    </Modal>
  );
};
