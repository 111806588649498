import { Button, Header } from '@/components';

import { PATH } from '@/constants';

import { PropsWithChildren } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import * as S from './index.style';

type PlaylistLayoutProps = {
  onSearch: (value: string) => void;
} & PropsWithChildren;

export const PlaylistLayout = ({ children, onSearch }: PlaylistLayoutProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Header
        title="재생목록"
        search={{ placeHolder: '재생 목록을 검색해 보세요', onSearch }}
      >
        <Button
          icon={<IoAddCircleOutline size={24} />}
          colorType="primary"
          size="medium"
          onClick={() =>
            navigate(PATH.PLAYLIST_MODIFY_PAGE('new'), {
              state: {
                prevUrl: PATH.PLAYLIST_PAGE,
              },
            })
          }
        >
          새 재생 목록 추가
        </Button>
      </Header>
      <S.Main>{children}</S.Main>
    </>
  );
};
