import {
  Card,
  DeleteModal,
  PopupCardDetail,
  PopupDeviceRegistrationModal,
  Tooltip,
  useModal,
} from '@/components';

import { useCopyPopupMutation, useDeletePopupMutation } from '@/hooks';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { useMemo } from 'react';
import { IoAdd, IoCopyOutline, IoPencil, IoTrash } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

type PopupCardProps = {
  popupId: string;
  name: string;
  type: string;
  deviceLists: string[];
  createdAt: Date;
  updatedAt?: Date;
};

export const PopupCard = ({
  popupId,
  name,
  type,
  deviceLists,
  createdAt,
  updatedAt,
}: PopupCardProps) => {
  const navigate = useNavigate();
  const { mutate: deletePopup } = useDeletePopupMutation();
  const { mutate: copyPopup } = useCopyPopupMutation();
  const deviceRegistration = useModal(() => (
    <PopupDeviceRegistrationModal
      popupId={popupId}
      closeModal={deviceRegistration.closeModal}
    />
  ));
  const deleteWarning = useModal(() => (
    <DeleteModal
      closeModal={deleteWarning.closeModal}
      onSubmit={() => deletePopup(popupId)}
    />
  ));

  const itemList = useMemo(
    () => [
      {
        name: '디바이스에 등록하기',
        icon: <IoAdd size={24} />,
        color: PALETTE.text.normal,
        handle: deviceRegistration.openModal,
      },
      {
        name: '복사하기',
        icon: <IoCopyOutline size={24} />,
        color: PALETTE.text.normal,
        handle: () => copyPopup(popupId),
      },
      {
        name: '수정하기',
        icon: <IoPencil size={24} />,
        color: PALETTE.text.normal,
        handle: () =>
          navigate(PATH.POPUP_MODIFY_PAGE(popupId), {
            state: {
              prevUrl: PATH.POPUP_PAGE,
            },
          }),
      },
      {
        name: '삭제하기',
        icon: <IoTrash size={24} />,
        color: PALETTE.status.negative,
        handle: deleteWarning.openModal,
      },
    ],
    [popupId],
  );
  return (
    <Card
      isShadow
      header={
        <PopupCardDetail
          name={name}
          type={type}
          deviceLists={deviceLists}
          createdAt={createdAt}
          updatedAt={updatedAt}
        />
      }
      controls={[<Tooltip itemList={itemList} />]}
    />
  );
};
