import { PlayListItemResDto } from '@/apis';

import { CheckBoxItem, CheckboxList, Loading } from '@/components';

import { useInfinitePlayListsQuery } from '@/hooks';

import { CenterLayout } from '@/layout';

import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './index.style';

type PlaylistCheckboxListProps = {
  search: string;
  maxSelectCount: number;
  setPlaylists: React.Dispatch<React.SetStateAction<PlayListItemResDto[]>>;
  onFocus: (playlist: PlayListItemResDto) => void;
  onChecked: (playlist: PlayListItemResDto) => boolean;
  onDisabled: (playlist: PlayListItemResDto) => boolean;
};

export const PlaylistCheckboxList = ({
  search,
  maxSelectCount,
  setPlaylists,
  onFocus,
  onChecked,
  onDisabled,
}: PlaylistCheckboxListProps) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage } = useInfinitePlayListsQuery({
    q: search,
  });
  const playlists = data ?? [];

  const onSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: CheckBoxItem,
  ) => {
    const playlist = playlists.find((v) => v.id === item.value);
    if (playlist) {
      setPlaylists((prev) =>
        event.target.checked
          ? prev.length >= maxSelectCount
            ? prev
            : [...prev, playlist]
          : prev.filter((v) => v.id !== playlist.id),
      );
    }
  };

  const items = useMemo(() => {
    return playlists.map((v) => ({
      name: v.name,
      value: v.id,
      checked: onChecked(v),
      disabled: onDisabled(v),
    }));
  }, [playlists, onChecked, onDisabled]);

  const focusHandler = (playlistId: string) => {
    const playlist = playlists.find((value) => value.id === playlistId);
    if (playlist) onFocus(playlist);
  };

  useEffect(() => {
    if (!isLoading) {
      onFocus?.(playlists[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <>
      {isLoading ? (
        <CenterLayout>
          <Loading />
        </CenterLayout>
      ) : (
        <S.Container>
          <CheckboxList
            title={'재생목록 이름'}
            items={items}
            infiniteRef={ref}
            onSelect={onSelect}
            onFocus={focusHandler}
          />
        </S.Container>
      )}
    </>
  );
};
