import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  padding: 1.5rem 0;

  &:first-child {
    padding: 0 0 1.5rem;
  }

  &:last-child {
    padding: 1.5rem 0 0;
  }
`;
export const Container = styled.div`
  width: 28.75rem;
  flex-shrink: 0;
  padding: 1rem 0;
  overflow-y: auto;

  background-color: ${PALETTE.static.white};
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 1.5rem;
  ${StepBox} + ${StepBox} {
    border-top: 1px solid ${PALETTE.line.normal};
  }
`;
