import { TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const DeviceNameContainer = styled.div`
  flex: 1;

  overflow: hidden;

  ${TYPOGRAPHY.body2}
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DeviceNameTdContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  overflow: hidden;

  ${TYPOGRAPHY.body2};
  font-weight: 400;
`;
