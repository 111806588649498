import { ApiErrorResponse, PlayContentReqDto } from '@/types';

import { putPlayListById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

/**
 * 새로 추가한 : 콘텐츠 id에 'NEW_' 포함됨, file id 가 없음, newFile 있음
 *
 * 수정한 콘텐츠 : id그대로, file id가 있음, newFile이 없음
 *
 * 기존 콘텐츠 복사 : 복사한 콘텐츠 id에 'COPY_' 포함됨, file id가 있음, newFile이 없음
 *
 * 추가한 콘텐츠 복사 : 복사한 콘텐츠 id에 'COPY_' 포함됨, file id가 없음, newFile이 있음
 */
export const useModifyPlaylistMutation = ({
  playlistId,
  onMutate,
  onError,
  onSuccess,
}: {
  playlistId: string;
  onMutate?: () => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      name,
      contents,
    }: {
      name: string;
      contents: PlayContentReqDto[];
    }) => putPlayListById({ id: playlistId, name, playContents: contents }),
    onMutate: onMutate,
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.PLAYLIST_ID(playlistId),
      });
      onSuccess?.();
    },
  });
};
