import { ReactNode, createContext, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

export type OverlayProps = {
  portalContainer?: HTMLElement;
  children: ReactNode;
};

type IOverlayContext = {
  contain: boolean;
  setContain: React.Dispatch<React.SetStateAction<boolean>>;
};

const OverlayContext = createContext<IOverlayContext | undefined>(undefined);

/**
 * A container which renders an overlay such as a popover or modal in a portal,
 * and provides a focus scope for the child elements.
 */
export const Overlay = ({
  portalContainer = document.body,
  children,
}: OverlayProps) => {
  const [contain, setContain] = useState(false);
  const contextValue = useMemo(
    () => ({ contain, setContain }),
    [contain, setContain],
  );

  return ReactDOM.createPortal(
    <OverlayContext.Provider value={contextValue}>
      {children}
    </OverlayContext.Provider>,
    portalContainer,
  );
};
