import { Button, TextField } from '@/components';

import { PALETTE, ZINDEX } from '@/themes';

import { format } from 'date-fns';
import {
  MouseEvent,
  Ref,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  IoCalendarOutline,
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { Popover } from 'react-tiny-popover';

import * as S from './index.style';

type DatePickerProps = {
  selectRange?: boolean;
  divider?: string;
  value: [Date | null, Date | null];
  onChange: (value: [Date | null, Date | null]) => void;
};

export const DatePicker = ({
  selectRange = false,
  divider,
  value,
  onChange,
}: DatePickerProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [rangeSelectState, setRangeSelectState] = useState([false, false]);
  const dateText = useMemo(() => {
    if (value[0] === null) return 'YY/MM/DD';
    const formattedStartDate = format(value[0], 'yy/MM/dd');
    if (!selectRange)
      return `${formattedStartDate}${divider ? ` ${divider}` : ''}`;
    const endDateText = value[1] ? format(value[1], 'yy/MM/dd') : '';
    return `${formattedStartDate}${divider ? ` ${divider}` : ''}${endDateText ? ` ${endDateText}` : ''}`;
  }, [value, selectRange]);

  const handleInputClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setIsPopoverOpen((prev) => !prev);
  }, []);

  const handleChange = useCallback(
    (v: Date | null | [Date | null, Date | null]) => {
      if (Array.isArray(v)) {
        onChange(v);
      } else {
        onChange([v, null]);
      }
      setIsPopoverOpen(false);
      setRangeSelectState([false, false]);
    },
    [onChange],
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      padding={4}
      positions={['bottom', 'top']}
      align="start"
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <S.ContentContainer>
          {rangeSelectState[0] ? (
            !rangeSelectState[1] &&
            selectRange && (
              <S.AlertText>
                <IoInformationCircleOutline
                  size={20}
                  color={PALETTE.primary.normal}
                />
                종료일을 선택하세요.
              </S.AlertText>
            )
          ) : (
            <S.AlertText>
              <IoInformationCircleOutline
                size={20}
                color={PALETTE.primary.normal}
              />
              시작일을 선택하세요.
            </S.AlertText>
          )}
          <Calendar
            selectRange={selectRange}
            onChange={handleChange}
            onClickDay={
              selectRange
                ? () =>
                    setRangeSelectState((prev) =>
                      prev[0] ? [true, true] : [true, false],
                    )
                : undefined
            }
            value={selectRange ? value : value[0]}
            formatDay={(_, date) => format(date, 'd')}
            minDetail="year"
            maxDetail="month"
            prevLabel={
              <IoChevronBackOutline size={20} color={PALETTE.static.black} />
            }
            nextLabel={
              <IoChevronForwardOutline size={20} color={PALETTE.static.black} />
            }
          />
        </S.ContentContainer>
      }
      containerStyle={{ zIndex: `${ZINDEX.popover}` }}
    >
      <RefTarget>
        {(ref) => (
          <TextField
            containerRef={ref}
            type="text"
            onClick={handleInputClick}
            value={dateText}
            readOnly
            prefix={
              <Button.Svg type="button" onClick={handleInputClick}>
                <IoCalendarOutline size={20} color={PALETTE.component.strong} />
              </Button.Svg>
            }
          />
        )}
      </RefTarget>
    </Popover>
  );
};
const RefTarget = forwardRef<
  HTMLDivElement,
  { children: (ref: Ref<HTMLDivElement>) => JSX.Element }
>(({ children }, ref) => <>{children(ref)}</>);
