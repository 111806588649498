import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: white;

  > * + * {
    border-top: 1px solid ${PALETTE.line.normal};
  }
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0;

  ${TYPOGRAPHY.body2}
  color: ${PALETTE.text.normal};
  font-weight: bold;
  flex-shrink: 0;
`;
export const List = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  > * + * {
    border-top: 1px solid ${PALETTE.line.normal};
  }
`;
export const CheckBoxWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0.9375rem 0 0.9375rem 0.75rem;
`;
