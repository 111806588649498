import { Loading } from '@/components';

import { GlobalStyle } from '@/themes';

import { CenterLayout } from '@/layout';
import { ModalProvider } from '@/providers';
import { Router } from '@/router';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ModalProvider>
          <Suspense
            fallback={
              <CenterLayout>
                <Loading />
              </CenterLayout>
            }
          >
            <Router />
          </Suspense>
          <GlobalStyle />
        </ModalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
