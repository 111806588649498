import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const AccordionContentType = styled.div`
  margin-top: 0.25rem;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.label2}
  font-weight: 400;
`;

export const AccordionContentTitle = styled.div`
  width: 28.125rem;
  margin-top: 4px;

  overflow: hidden;

  ${TYPOGRAPHY.body2};
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AccordionContent = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgb(112 115 124 / 22%);

  background-color: ${({ $color }) =>
    ($color ?? false) ? $color : PALETTE.background.normal};
`;

export const ContentThumbnailBox = styled.div`
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 4px;

  background-color: ${PALETTE.background.normal};
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
