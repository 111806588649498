import { ApiErrorResponse } from '@/types';

import { PopupDataType, putPopupById } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useModifyPopupMutation = ({
  popupId,
  onMutate,
  onError,
  onSuccess,
}: {
  popupId: string;
  onMutate?: () => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PopupDataType) => putPopupById(popupId, data),
    onMutate: onMutate,
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.POPUPLAYER_ID(popupId),
      });
      onSuccess?.();
    },
  });
};
