import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;
export const PillsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 1.5rem;

  white-space: nowrap;
  gap: 0.625rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;
export const ArrowButtonContainer = styled.div<{
  $direction: 'left' | 'right';
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ $direction }) =>
    $direction === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`;
