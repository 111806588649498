import { PALETTE } from '@/themes';

import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

import * as S from './index.style';

type LimitGuideProps = {
  maxPlaylistCount?: number;
  maxPromotionCount?: number;
};

export const LimitGuide = React.memo(
  ({ maxPlaylistCount = 5, maxPromotionCount = 50 }: LimitGuideProps) => {
    return (
      <S.GuideContainer>
        <S.GuideInfo>
          <IoInformationCircleOutline size={20} color={PALETTE.text.normal} />
          <S.GuidePre>
            재생 목록: 기본 재생 목록을 제외하고{' '}
            <b>최대 {maxPlaylistCount}개</b>
            까지 추가할 수 있어요.
            <br />
            프로모션 기간: 1개의 재생 목록에 <b>최대 {maxPromotionCount}개</b>의
            프로모션 기간을 추가할 수 있어요.
          </S.GuidePre>
        </S.GuideInfo>
      </S.GuideContainer>
    );
  },
);
