const LOGIN = 'login';
const REGISTER = 'register';
const DASHBOARD = 'dashboard';
const DEVICE = 'device';
const PLAYLIST = 'playlist';
const POPUP = 'popuplayer';
const STORE = 'store';
const MODIFY = 'modify';

export const PATH = {
  ROOT: '/',
  LOGIN_PAGE: `/${LOGIN}`,
  REGISTER_PAGE: `/${REGISTER}`,
  DASHBOARD_PAGE: `/${DASHBOARD}`,
  DEVICE_PAGE: `/${DEVICE}`,
  PLAYLIST_PAGE: `/${PLAYLIST}`,
  POPUP_PAGE: `/${POPUP}`,
  STORE_PAGE: `/${STORE}`,
  DEVICE_MODIFY_PAGE: (deviceId?: string) =>
    `/${DEVICE}/${MODIFY}/${deviceId ?? ':deviceId'}`,
  PLAYLIST_MODIFY_PAGE: (playlistId?: string) =>
    `/${PLAYLIST}/${MODIFY}/${playlistId ?? ':playlistId'}`,
  POPUP_MODIFY_PAGE: (popupId?: string) =>
    `/${POPUP}/${MODIFY}/${popupId ?? ':popupId'}`,
  NOT_FOUND: `/404`,
} as const;
