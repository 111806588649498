import { Button, Empty } from '@/components';

import { PATH } from '@/constants';

import { PALETTE } from '@/themes';

import { IoImageOutline, IoSearch } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type PopupListEmptyProps = {
  search: string;
};

export const PopupListEmpty = ({ search }: PopupListEmptyProps) => {
  const navigate = useNavigate();
  return search.length > 0 ? (
    <Empty
      icon={<IoSearch size={60} color={PALETTE.component.normal} />}
      title={'검색 결과가 없어요.'}
    />
  ) : (
    <Empty
      icon={<IoImageOutline size={60} color={PALETTE.component.normal} />}
      title={'등록된 팝업 레이어가 없어요!'}
      description={
        <>
          새 팝업 레이어 추가 버튼을 클릭해서 팝업 레이어를 생성하고,
          <MdMoreVert size={18} />
          더보기 버튼 {'>'} 수정하기를 클릭해서 팝업 레이어를 추가하세요.
        </>
      }
    >
      <Button
        size="medium"
        colorType="secondary"
        outlined
        onClick={() =>
          navigate(PATH.POPUP_MODIFY_PAGE('new'), {
            state: {
              prevUrl: PATH.POPUP_PAGE,
            },
          })
        }
      >
        새 팝업 레이어 추가하기
      </Button>
    </Empty>
  );
};
