import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 80px 1fr 86px;
  border-right: 1px solid ${PALETTE.line.normal};

  background-color: ${PALETTE.component.sidebar};
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 0 6px;
`;

export const Item = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  color: black;
  ${TYPOGRAPHY.body2};

  cursor: pointer;

  ${({ $isActive }) => css`
    border-radius: 6px;

    background-color: ${$isActive ? PALETTE.primary.normal : 'transparent'};
    opacity: ${$isActive ? 1 : 0.6};

    font-weight: ${$isActive ? 700 : 400};
  `}
`;
export const ItemGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  color: ${PALETTE.static.white};
`;
export const ItemIconWrapper = styled.div`
  flex-shrink: 0;
`;

export const Badge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  padding: 0 4px;
  border-radius: 4px;

  background-color: ${PALETTE.static.white};

  color: ${PALETTE.component.sidebar};
  font-size: ${TYPOGRAPHY.caption1};
`;
