import { useDebounce } from '@/hooks';

import React, { useCallback, useEffect, useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import * as S from './index.style';

type SearchProps = {
  placeHolder: string;
  onSearch: (value: string) => void;
};

export const Search = React.memo(({ placeHolder, onSearch }: SearchProps) => {
  const [localInput, setLocalInput] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalInput(event.target.value);
  };

  const debouncedSetInputValue = useDebounce(localInput);

  const handleInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        // alert(`${debouncedSetInputValue}`);
      }
    },
    [debouncedSetInputValue],
  );

  useEffect(() => {
    onSearch?.(debouncedSetInputValue);
  }, [debouncedSetInputValue, localInput]);

  return (
    <S.Container>
      <IoSearchOutline size={20} />
      <S.Input
        placeholder={placeHolder}
        value={localInput}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyPress}
      />
    </S.Container>
  );
});
