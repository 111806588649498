import React, { forwardRef, useMemo, useState } from 'react';

import * as S from './index.style';

type TextAreaProps = {
  countLengthEnable?: boolean;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { countLengthEnable = false, maxLength, onChange, value, ...props },
    ref,
  ) => {
    const isShowCounts = useMemo(
      () => countLengthEnable && maxLength && maxLength > 0,
      [countLengthEnable, maxLength],
    );
    const [currentLength, setCurrentLength] = useState(`${value}`.length ?? 0);
    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentLength(e.target.value.length);
      onChange?.(e);
    };

    return (
      <S.Container>
        <S.TextFieldWrapper>
          <S.Input
            {...props}
            $countLengthEnable={countLengthEnable}
            maxLength={maxLength}
            value={value}
            onChange={handleOnChange}
            ref={ref}
          />
          {isShowCounts && (
            <S.CountLength>
              {currentLength} / {maxLength}자
            </S.CountLength>
          )}
        </S.TextFieldWrapper>
      </S.Container>
    );
  },
);
