import { Button, TextField } from '@/components';

import { useRegisterMutation } from '@/hooks';

import { API_ERROR_CODE, INVALID_MASSAGE } from '@/constants';

import { useForm } from 'react-hook-form';

import * as S from './index.style';

export type RegisterFormType = {
  loginId: string;
  password: string;
};

export const Register = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<RegisterFormType>({
    defaultValues: { loginId: '', password: '' },
    mode: 'onChange',
  });

  const { mutate: resgisterMutation } = useRegisterMutation({
    onError: (error) => {
      if (error.response?.data.errorCode === API_ERROR_CODE.CONFLICT_LOGINID) {
        setError('loginId', { message: '사용할 수 없는 아이디에요.' });
      }
    },
  });

  const isAbleSubmit = (): boolean => {
    const loginId = watch('loginId');
    const pw = watch('password');

    return (
      typeof loginId === 'string' &&
      typeof pw === 'string' &&
      loginId.length > 0 &&
      pw.length > 0 &&
      !errors.loginId &&
      !errors.password
    );
  };

  return (
    <S.RegisterContainer>
      <S.LogoContainer>
        evryth<span>.</span>in
      </S.LogoContainer>
      <S.RegisterWrapper
        onSubmit={handleSubmit((d: RegisterFormType) => resgisterMutation(d))}
      >
        {/* 회원가입 입력창 */}
        <S.RegisterInputWrapper>
          <TextField
            {...register('loginId', {
              required: INVALID_MASSAGE.WRONG_ID,
              pattern: {
                value: /^[a-zA-Z0-9_]*$/,
                message: INVALID_MASSAGE.WRONG_ID,
              },
              minLength: { value: 6, message: INVALID_MASSAGE.WRONG_ID },
              maxLength: { value: 12, message: INVALID_MASSAGE.WRONG_ID },
              onChange: (e) =>
                (e.target.value = e.target.value.replaceAll(
                  /[^a-zA-Z0-9_]/g,
                  '',
                )),
            })}
            errorMessage={errors.loginId?.message}
            label="아이디"
            placeholder="아이디를 입력해 주세요."
            minLength={6}
            maxLength={12}
            autoComplete="username"
          />
          <TextField
            {...register('password', {
              required: INVALID_MASSAGE.WRONG_PASSWORD,
              pattern: {
                value:
                  /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,20}$/,
                message: INVALID_MASSAGE.WRONG_PASSWORD,
              },
              minLength: { value: 8, message: INVALID_MASSAGE.WRONG_PASSWORD },
              maxLength: { value: 20, message: INVALID_MASSAGE.WRONG_PASSWORD },
              onChange: (e) =>
                (e.target.value = e.target.value.replaceAll(
                  /[^a-zA-Z0-9!@#$%^*+=-]/g,
                  '',
                )),
            })}
            errorMessage={errors.password?.message}
            label="비밀번호"
            placeholder="비밀번호를 입력해 주세요."
            minLength={8}
            maxLength={20}
            isPassword
            autoComplete={'new-password'}
          />
        </S.RegisterInputWrapper>
        {/* 로그인 버튼 */}
        <S.RegisterSummitWrapper>
          <Button type="submit" size="large" disabled={!isAbleSubmit()}>
            가입하기
          </Button>
        </S.RegisterSummitWrapper>
      </S.RegisterWrapper>
    </S.RegisterContainer>
  );
};
