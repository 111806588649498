import { ExtendedFile } from '@/types';

import { HTMLAttributes, forwardRef, useCallback } from 'react';

import * as S from './index.style';

type ProgressProps = {
  status: ExtendedFile['uploadStatus'];
  value: number;
} & HTMLAttributes<HTMLDivElement>;

export const Progress = forwardRef<HTMLDivElement, ProgressProps>(
  ({ value, status, ...props }, ref) => {
    const getStatusColor = useCallback(() => {
      switch (status) {
        case 'success': {
          return 'success';
        }

        case 'error': {
          return 'error';
        }

        default: {
          return 'info';
        }
      }
    }, [status]);
    return (
      <S.Container ref={ref} {...props}>
        {status === 'encoding' ? (
          <S.Indeterminate />
        ) : (
          <S.Indicator
            $color={getStatusColor()}
            style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
          />
        )}
      </S.Container>
    );
  },
);
