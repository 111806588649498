import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${PALETTE.static.white};
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderTitle = styled.div`
  white-space: nowrap;
`;
export const ContentContainer = styled.div`
  width: 49.625rem;

  > * + * {
    border-top: 1px solid ${PALETTE.line.normal};
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  height: 30.5rem;

  > * + * {
    border-left: 1px solid ${PALETTE.line.normal};
  }
`;
export const MainContent = styled.div`
  padding: 0.875rem 1rem;
  flex-grow: 1;
  overflow-y: auto;

  background-color: ${PALETTE.background.normal};

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
