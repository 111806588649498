import { dateFormat, timeToHHMM } from '@/utils';

import * as S from './index.style';

type PromotionBoxProps = {
  title: string;
  color: string;
  startPeriod: string;
  endPeriod?: string | null;
  startTime: string;
  endTime?: string | null;
};

export const PromotionBox = ({
  title,
  color,
  startPeriod,
  endPeriod,
  startTime,
  endTime,
}: PromotionBoxProps) => {
  return (
    <S.Container $bgColor={color}>
      <S.Title>{title}</S.Title>
      <S.Range>
        <>
          {`${dateFormat(startPeriod, '/')} ${timeToHHMM(startTime)}${endPeriod || endTime ? ` - ` : ''}${endPeriod ? `${dateFormat(endPeriod, '/')} ` : ''}${endTime ? timeToHHMM(endTime) : ''}`}
        </>
      </S.Range>
    </S.Container>
  );
};
