import { postAddPopupToDeviceByIds } from '@/apis';

import { QUERY_KEY } from '@/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddPopupToDeviceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      deviceIds,
      popupId,
    }: {
      deviceIds: Array<string>;
      popupId: string;
    }) => postAddPopupToDeviceByIds(deviceIds, popupId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.POPUPLAYER(),
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY.DEVICE(),
      });
    },
  });
};
