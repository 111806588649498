import { PALETTE } from '@/themes';

import { IoChevronDown } from 'react-icons/io5';
import styled from 'styled-components';

export const AccordionSection = styled.div`
  position: relative;
  width: 100%;

  font-family: 'SUIT Variable';
  font-style: normal;
  line-height: 20px;

  user-select: none;
`;

export const AccordionTitle = styled.div`
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SelectArrow = styled(IoChevronDown)<{ $isopen: boolean }>`
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 2px;

  color: ${PALETTE.component.normal};
  transform: ${({ $isopen }) => ($isopen ? `rotate(180deg)` : `rotate(0deg)`)};

  transition: transform 0.15s;
`;

export const AccordionTitleContainer = styled.div<{ $isopen: boolean }>`
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  height: 76px;
  padding: 1rem 1.5rem 1rem 1rem;
  border-bottom: ${({ $isopen }) =>
    $isopen ? 'none' : '1px solid rgba(112, 115, 124, 0.22)'};

  background-color: ${PALETTE.static.white};

  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${PALETTE.interaction.hover.light} !important;

    > ${SelectArrow} {
      color: ${PALETTE.primary.normal};
    }
  }
`;

export const AccordionContentWrapper = styled.div<{ $isopen: boolean }>`
  height: ${({ $isopen }) => ($isopen ? `100%` : '0')};

  overflow: hidden;

  transition: height 0.2s ease;
`;
