import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const StorePhoneNumWrapper = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
  gap: 8px;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;

  color: ${PALETTE.text.caption};
  ${TYPOGRAPHY.body2};
  font-weight: bold;
`;

export const StorePhoneNumContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StoreInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;

  width: 100%;
  padding: 0 1.5rem;
`;

export const StoreInfoContainer = styled.div`
  width: 25rem;
  min-width: 100%;
  height: 100%;
`;
