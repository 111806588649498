import { StoreAddReqDto } from '@/apis';

import { Button, DropDown, Modal, TextField } from '@/components';

import { useAddStoreMutation } from '@/hooks';

import { INVALID_MASSAGE } from '@/constants';

import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as S from './index.style';

const OPTIONS: Array<{ value: string; label: string }> = [
  { value: '82', label: '+82' },
];

type AddStoreModalProps = {
  registered: Set<string>;
  closeModal: () => void;
};

export const AddStoreModal = ({
  registered,
  closeModal,
}: AddStoreModalProps) => {
  const [selected, setSelected] = useState<string>(OPTIONS[0].value);

  const { watch, setValue, getValues } = useForm<StoreAddReqDto>({
    defaultValues: {
      name: '',
      phoneNum: '',
      description: '',
    },
  });

  const { mutate: addStore } = useAddStoreMutation({
    onSuccess: closeModal,
  });

  const isAlreadyAdded = () => registered.has(watch('name'));

  const handleTextfield = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof StoreAddReqDto,
  ) => {
    if (type === 'phoneNum') {
      const regex = /^[0-9\b -]{0,20}$/;
      if (regex.test(e.target.value)) {
        setValue(type, e.target.value);
      }
      return;
    }
    setValue(type, e.target.value);
  };

  const submitStore = () => {
    const { name, phoneNum, description } = getValues();
    addStore({ name, phoneNum: `+${selected}${phoneNum}`, description });
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header>매장 추가</Modal.Header>
      <S.StoreInfoContainer>
        <S.StoreInfoForm>
          <TextField
            label="매장 이름"
            value={watch('name')}
            onChange={(e) => handleTextfield(e, 'name')}
            errorMessage={
              isAlreadyAdded() ? INVALID_MASSAGE.ALREADY_ADDED_STORE : undefined
            }
            placeholder="매장 이름을 입력해 주세요."
          />
          <S.StorePhoneNumContainer>
            <S.Label>매장 전화번호</S.Label>
            <S.StorePhoneNumWrapper>
              <DropDown
                options={OPTIONS}
                disabled={true}
                onSelect={setSelected}
                initialValue={OPTIONS[0]}
              />
              <TextField
                type="tel"
                value={watch('phoneNum')}
                onChange={(e) => handleTextfield(e, 'phoneNum')}
                maxLength={20}
                placeholder="매장 전화번호를 입력해 주세요."
              />
            </S.StorePhoneNumWrapper>
          </S.StorePhoneNumContainer>
          <TextField
            label="메모"
            value={watch('description')}
            onChange={(e) => handleTextfield(e, 'description')}
            placeholder="메모를 입력해 주세요."
          />
        </S.StoreInfoForm>
      </S.StoreInfoContainer>
      <Modal.Footer>
        <Button onClick={closeModal} outlined={true} colorType={'secondary'}>
          취소
        </Button>
        <Button
          type="submit"
          disabled={watch('name').length <= 0 || isAlreadyAdded()}
          onClick={submitStore}
        >
          추가하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
