import Frame from 'react-frame-component';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: transparent;
`;

export const IFrame = styled(Frame)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;

  background: #838383;
  outline: none;
`;
export const IFrameOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  background-color: transparent;
`;
