import { PlayContentResDto } from '@/types';

import { Card, PlaylistAccordion } from '@/components';

import * as S from './index.style';

type ContentsPreviewCardProps = {
  contents: Array<PlayContentResDto>;
};

export const ContentsPreviewCard = ({ contents }: ContentsPreviewCardProps) => {
  return (
    <Card isShadow header={<S.Header>등록된 콘텐츠 미리보기</S.Header>}>
      <S.Container>
        {contents.map((item, index) => (
          <PlaylistAccordion.Item
            key={index}
            thumbnailUrl={item.file.thumbnailUrl}
            originalName={item.file.originalName}
            type={item.file.type}
          />
        ))}
      </S.Container>
    </Card>
  );
};
