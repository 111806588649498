import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const DATA_DOG_CLIENT_TOKEN = import.meta.env
  .VITE_APP_BASE_DATA_DOG_CLIENT_TOKEN;
const DATA_DOG_SITE = import.meta.env.VITE_APP_BASE_DATA_DOG_SITE;
const DATA_DOG_APPLICATION_ID = import.meta.env
  .VITE_APP_BASE_DATA_DOG_APPLICATION_ID;
const DATA_DOG_SERVICE = import.meta.env.VITE_APP_BASE_DATA_DOG_SERVICE;
const DATA_DOG_SESSION_SAMPLE_RATE = import.meta.env
  .VITE_APP_BASE_DATA_DOG_SESSION_SAMPLE_RATE;
const DATA_DOG_SESSION_REPLAY_SAMPLE_RATE = import.meta.env
  .VITE_APP_BASE_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE;
const DATA_DOG_TRACK_USER_INTERACTIONS = import.meta.env
  .VITE_APP_BASE_DATA_DOG_TRACK_USER_INTERACTIONS;
const DATA_DOG_TRACK_RESOURCES = import.meta.env
  .VITE_APP_BASE_DATA_DOG_TRACK_RESOURCES;
const DATA_DOG_TRACK_LONG_TASKS = import.meta.env
  .VITE_APP_BASE_DATA_DOG_TRACK_LONG_TASKS;
const DATA_DOG_DEFAULT_PRIVACY_LEVEL = import.meta.env
  .VITE_APP_BASE_DATA_DOG_DEFAULT_PRIVACY_LEVEL;

export function datadogInit() {
  datadogRum.init({
    applicationId: DATA_DOG_APPLICATION_ID,
    clientToken: DATA_DOG_CLIENT_TOKEN,
    site: DATA_DOG_SITE,
    service: DATA_DOG_SERVICE,
    sessionSampleRate: Number(DATA_DOG_SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: Number(DATA_DOG_SESSION_REPLAY_SAMPLE_RATE),
    trackUserInteractions: DATA_DOG_TRACK_USER_INTERACTIONS === 'true',
    trackResources: DATA_DOG_TRACK_RESOURCES === 'true',
    trackLongTasks: DATA_DOG_TRACK_LONG_TASKS === 'true',
    defaultPrivacyLevel: DATA_DOG_DEFAULT_PRIVACY_LEVEL,
  });

  datadogLogs.init({
    clientToken: DATA_DOG_CLIENT_TOKEN,
    site: DATA_DOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: Number(DATA_DOG_SESSION_SAMPLE_RATE),
  });
}
