import { BOXSHADOW, PALETTE } from '@/themes';

import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  padding: 1rem 1.5rem;

  box-sizing: border-box;
`;
export const Controls = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 14px;
`;
export const Body = styled.div`
  border-top: 1px solid rgb(125 131 152 / 30%);
`;
export const Container = styled.div<{ $isShadow: boolean }>`
  width: 100%;
  border: 1px solid ${PALETTE.line.normal};
  border-radius: 6px;

  background-color: ${PALETTE.static.white};
  overflow: hidden;
  ${({ $isShadow }) =>
    $isShadow &&
    css`
      box-shadow: ${BOXSHADOW.card};
    `}
`;
