import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isDefault: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;

  box-sizing: border-box;

  background-color: ${PALETTE.static.white};

  ${({ $isDefault }) =>
    $isDefault &&
    css`
      border: 1px solid ${PALETTE.line.normal};
      border-radius: 0.375rem;
    `}
`;
export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;

  ${TYPOGRAPHY.body1};
  font-weight: 700;
  text-overflow: ellipsis;
  word-break: break-all;
`;
export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.625rem;
`;
export const InfoBoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  ${TYPOGRAPHY.body1};
  font-weight: 700;
`;
export const InfoBoxPlayTime = styled.div`
  ${TYPOGRAPHY.body2};
  color: ${PALETTE.text.normal};
`;
export const ControlBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
`;
export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0.125rem 0.375rem;
  border: 1px solid ${PALETTE.primary.normal};
  border-radius: 1000px;

  background-color: ${PALETTE.static.white};

  color: ${PALETTE.primary.normal};
  ${TYPOGRAPHY.label2};
  font-weight: 700;
`;
