import { RuleSet, css } from 'styled-components';

type Font = {
  font: string;
  size: number;
  lineHeight: number;
};

const FONT = ({ font, size, lineHeight }: Font): RuleSet<object> => {
  return css`
    font-size: ${size}px;
    font-family: ${font};
    line-height: ${lineHeight}px;
  `;
};

export const TYPOGRAPHY = {
  logo1: FONT({ font: 'SUIT', size: 46, lineHeight: 46 }),
  title1: FONT({ font: 'SUIT', size: 32, lineHeight: 72 }),
  title2: FONT({ font: 'SUIT', size: 26, lineHeight: 52 }),
  title3: FONT({ font: 'SUIT', size: 20, lineHeight: 34 }),
  title4: FONT({ font: 'SUIT', size: 18, lineHeight: 24 }),
  body1: FONT({ font: 'SUIT', size: 16, lineHeight: 20 }),
  body2: FONT({ font: 'SUIT', size: 14, lineHeight: 20 }),
  label1: FONT({ font: 'SUIT', size: 13, lineHeight: 20 }),
  label2: FONT({ font: 'SUIT', size: 12, lineHeight: 20 }),
  caption1: FONT({ font: 'SUIT', size: 10, lineHeight: 18 }),
  caption2: FONT({ font: 'SUIT', size: 7, lineHeight: 12 }),
} as const;
