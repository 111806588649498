import { useCallback } from 'react';

import * as S from './index.style';

type WeekDayLabel = '월' | '화' | '수' | '목' | '금' | '토' | '일';
type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type WeekDaysPickerProps = {
  value: number[];
  onChange: (days: number[]) => void;
};

export const WeekDaysPicker = ({ value, onChange }: WeekDaysPickerProps) => {
  const handleDayClick = useCallback(
    (day: DayData) => {
      onChange(
        value.includes(day.value)
          ? value.filter((v) => v !== day.value)
          : [...value, day.value],
      );
    },
    [value, onChange],
  );

  const isSelected = useCallback(
    (day: DayData) => {
      return value.includes(day.value);
    },
    [value],
  );

  return (
    <S.Container>
      {DAYS_OF_WEEK.map((day) => (
        <S.DayButton
          key={day.value}
          $isSelected={isSelected(day)}
          onClick={() => handleDayClick(day)}
        >
          {day.label}
        </S.DayButton>
      ))}
    </S.Container>
  );
};

type DayData = {
  label: WeekDayLabel;
  value: WeekDay;
};

const DAYS_OF_WEEK: DayData[] = [
  {
    label: '월',
    value: 1,
  },
  {
    label: '화',
    value: 2,
  },
  {
    label: '수',
    value: 3,
  },
  {
    label: '목',
    value: 4,
  },
  {
    label: '금',
    value: 5,
  },
  {
    label: '토',
    value: 6,
  },
  {
    label: '일',
    value: 0,
  },
];
