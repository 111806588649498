/**
 * 배열이 비어 있는지 확인합니다.
 *
 * @param arr - 확인할 배열
 * @returns 배열의 길이가 0이면 true, 그렇지 않으면 false를 반환합니다.
 */
export const isArrEmpty = (arr: unknown[]): boolean => arr.length <= 0;

/**
 * 입력된 값이 파일 객체인지 여부를 확인합니다.
 *
 * @param input - 확인할 값
 * @returns 입력된 값이 File 객체이면 true, 그렇지 않으면 false를 반환합니다.
 */
export const isFile = (input: unknown): boolean =>
  'File' in window && input instanceof File;
