/* eslint-disable @typescript-eslint/no-explicit-any */
import { PageMetaDto } from '@/types';

import { StoreItemResDto, StoreListResDto } from '@/apis';

import {
  Button,
  Checkbox,
  DeleteModal,
  DropDown,
  Pagination,
  useModal,
} from '@/components';

import { useDeleteStoreMutation } from '@/hooks';

import { API_ERROR_CODE, TABLE_DROPDOWN_OPTIONS } from '@/constants';

import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import {
  CellContext,
  ColumnDef,
  PaginationState,
  RowSelectionState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IoAdd, IoTrashOutline } from 'react-icons/io5';

import { AddStoreModal, MoveToDevicePageModal } from '../modal';
import { StoreTable } from '../table';
import { TableTooltip } from '../table/tableTooltip';
import * as S from './index.style';

type StoreTableLayoutProps = {
  data: StoreListResDto | undefined;
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<StoreListResDto, Error>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

export const StoreTableLayout = ({
  data,
  pagination,
  setPagination,
}: StoreTableLayoutProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [storeData, setStoreData] = useState<StoreItemResDto[]>([]);

  const deleteModal = useModal(() => (
    <DeleteModal
      onSubmit={deleteMultipleHandler}
      closeModal={deleteModal.closeModal}
    />
  ));

  const moveToModal = useModal(() => (
    <MoveToDevicePageModal closeModal={moveToModal.closeModal} />
  ));

  const addStoreModal = useModal(() => (
    <AddStoreModal
      registered={new Set(storeData.map((sd) => sd.name))}
      closeModal={addStoreModal.closeModal}
    />
  ));

  const { mutate: deleteStore } = useDeleteStoreMutation({
    onSuccess: () => {
      deleteModal.closeModal();
      setRowSelection({});
    },
    onError: (error) => {
      deleteModal.closeModal();
      setRowSelection({});
      if (error.response?.data.errorCode === API_ERROR_CODE.INUSE_STORE) {
        moveToModal.openModal();
      }
    },
  });

  const columns: ColumnDef<StoreItemResDto, any>[] = [
    {
      id: 'select',
      accessorKey: 'select',
      header: ({ table }: { table: any }) => {
        const {
          getIsAllPageRowsSelected,
          getToggleAllPageRowsSelectedHandler,
        } = table;
        return (
          <Checkbox
            name="allSelect"
            value="all"
            readOnly
            checked={getIsAllPageRowsSelected()}
            onChange={getToggleAllPageRowsSelectedHandler()}
          />
        );
      },
      cell: ({ row }) => {
        const { id, getIsSelected, getToggleSelectedHandler } = row;
        return (
          <Checkbox
            name={id}
            checked={getIsSelected()}
            readOnly
            value={id}
            onChange={getToggleSelectedHandler()}
          />
        );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: '이름',
      cell: (info: CellContext<StoreItemResDto, string>) => (
        <S.StoreTextTd>{info.getValue()}</S.StoreTextTd>
      ),
    },
    {
      id: 'phoneNum',
      accessorKey: 'phoneNum',
      header: '매장 전화번호',
      cell: (info: CellContext<StoreItemResDto, string>) => {
        // TODO : 국가 번호/지역번호 고려
        // https://www.npmjs.com/package/libphonenumber-js
        // const formattedPhoneNum = info.getValue().replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
        return <S.StoreTextTd>{info.getValue()}</S.StoreTextTd>;
      },
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: '메모',
      cell: (info: CellContext<StoreItemResDto, string>) => (
        <S.StoreTextTd>{info.getValue()}</S.StoreTextTd>
      ),
    },
    {
      id: 'tooltip',
      accessorKey: 'tooltip',
      header: '',
      cell: (info: CellContext<StoreItemResDto, unknown>) => {
        return (
          <TableTooltip
            info={info.row.original}
            registered={new Set(storeData.map((sd) => sd.name))}
          />
        );
      },
    },
  ];

  const table = useReactTable({
    data: storeData,
    columns,
    state: { rowSelection, pagination },
    enableRowSelection: true,
    autoResetPageIndex: false,
    autoResetAll: false,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    if (data && data?.data) {
      setStoreData(data.data);
    }
  }, [data]);

  const deleteMultipleHandler = () => {
    const ids = Object.keys(rowSelection).join(',');
    deleteStore(ids);
  };

  const pageSizeHandler = (value: number) => {
    if (table.getState().pagination.pageSize !== value) {
      table.setPageSize(value);
      table.setPageIndex(1);
    }
  };

  return (
    <S.TableContainer>
      <S.TableHeader>
        <S.DropdownWrapper>
          <DropDown
            options={TABLE_DROPDOWN_OPTIONS}
            onSelect={pageSizeHandler}
            initialValue={TABLE_DROPDOWN_OPTIONS[0]}
          />
        </S.DropdownWrapper>
        <S.HandlerButtonContainer>
          <Button
            outlined={true}
            colorType={'secondary'}
            icon={<IoTrashOutline />}
            onClick={deleteModal.openModal}
            disabled={Object.keys(rowSelection).length <= 0}
          >
            삭제
          </Button>
          <Button
            outlined={true}
            colorType={'color'}
            icon={<IoAdd />}
            onClick={addStoreModal.openModal}
          >
            매장 추가
          </Button>
        </S.HandlerButtonContainer>
      </S.TableHeader>
      <StoreTable table={table} rowSelection={rowSelection} />
      <S.PaginationWrapper>
        <Pagination<StoreItemResDto>
          key={data?.meta?.pageCount ?? 0}
          {...table}
          {...(data?.meta as PageMetaDto)}
          take={1}
        />
      </S.PaginationWrapper>
    </S.TableContainer>
  );
};
