import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
`;

export const CheckBox = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  border: solid 1px ${PALETTE.component.normal};
  border-radius: 4px;

  background-color: ${PALETTE.static.white};
  cursor: pointer;
  appearance: none;

  &:checked {
    border: solid 1px ${PALETTE.primary.normal};
    border-radius: 4px;

    background: ${PALETTE.primary.normal} url('/imgs/check.svg?react') no-repeat
      center / 1.25rem;

    color: ${PALETTE.static.white};
  }

  &:disabled {
    border: solid 1px ${PALETTE.component.normal};
    border-radius: 4px;

    background: ${PALETTE.interaction.disable};
  }

  > svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const Label = styled.label`
  width: calc(100% - 10px);

  overflow: hidden;

  color: ${PALETTE.text.normal};
  ${TYPOGRAPHY.body2}
  font-style: normal;
  font-weight: 400;
  user-select: none;
  cursor: pointer;

  white-space: nowrap;
  text-overflow: ellipsis;
`;
