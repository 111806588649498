import { ApiErrorResponse } from '@/types';

import { SignupReqDto, postSignup } from '@/apis';

import { PATH } from '@/constants';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export const useRegisterMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: AxiosError<ApiErrorResponse>) => void;
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (registerData: SignupReqDto) =>
      postSignup({ ...registerData }),
    onSuccess: () => {
      onSuccess?.();
      navigate(PATH.LOGIN_PAGE);
    },
    onError: (error: AxiosError<ApiErrorResponse>) => onError?.(error),
  });
};
