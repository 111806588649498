import { enhanceChildElement } from '@/utils';

import { HTMLAttributes, ReactNode } from 'react';

import { ModalContent } from './modalContent';
import { ModalFooter } from './modalFooter';
import { ModalHeader } from './modalHeader';
import { ModalLayout } from './modalLayout';

export type ModalLayoutProps = {
  children?: ReactNode;
  closeModal: () => void;
} & HTMLAttributes<HTMLDivElement>;

function Modal({ children, closeModal, ...args }: ModalLayoutProps) {
  return (
    <ModalLayout {...args}>
      {enhanceChildElement({
        children: children,
        component: ModalHeader,
        newProps: { closeModal },
      })}
    </ModalLayout>
  );
}

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export { Modal };
