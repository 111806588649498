import { PopupDeviceResDto } from '@/apis';

import { Card } from '@/components';

import { dateFormat } from '@/utils';

import * as S from './index.style';

type PopupInfoCardProps = {
  type: string;
  devices: Array<PopupDeviceResDto>;
  createdAt: Date;
  updatedAt?: Date;
};

export const PopupInfoCard = ({
  type,
  devices,
  createdAt,
  updatedAt,
}: PopupInfoCardProps) => {
  return (
    <Card
      isShadow
      header={
        <S.Container>
          <S.Item>
            <S.Key>유형</S.Key>
            <S.Value>{type === 'qr' ? 'QR코드' : '이미지'}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>등록된 디바이스</S.Key>
            <S.Value>{devices.map((device) => device.name)}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>생성일</S.Key>
            <S.Value>{dateFormat(createdAt, '/')}</S.Value>
          </S.Item>
          <S.Item>
            <S.Key>최종 수정일</S.Key>
            {updatedAt && <S.Value>{dateFormat(updatedAt, '/')}</S.Value>}
          </S.Item>
        </S.Container>
      }
    />
  );
};
