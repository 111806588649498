import { PopupLayout, PopupList } from '@/components';

import { useState } from 'react';

export const PopupLayer = () => {
  const [search, setSearch] = useState('');
  return (
    <PopupLayout onSearch={setSearch}>
      <PopupList search={search} />
    </PopupLayout>
  );
};
