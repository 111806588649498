import { BOXSHADOW, PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;

  padding: 1.5rem;
  border-radius: 0.625rem;
  place-items: center;
  grid-gap: 0.5rem;

  box-sizing: border-box;
  box-shadow: ${BOXSHADOW.calendar};
`;
export const DayButton = styled.button<{
  $isSelected: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 100%;

  cursor: pointer;

  ${TYPOGRAPHY.body2};

  ${({ $isSelected }) =>
    $isSelected
      ? css`
          background-color: ${PALETTE.primary.normal};

          color: ${PALETTE.static.white};
          font-weight: 700;
        `
      : css`
          background-color: ${PALETTE.static.white};

          color: ${PALETTE.text.normal};
        `}
`;
export const DayPickerAlertText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;

  color: ${PALETTE.primary.normal};
  ${TYPOGRAPHY.label2};
`;
