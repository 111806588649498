import { useToggle } from '@/hooks';

import { MouseEvent, PropsWithChildren, ReactNode } from 'react';

import { AccordionItem } from './accordionItem';
import * as S from './index.style';

type AccordionProps = {
  header: ReactNode;
} & PropsWithChildren;

const Accordion = ({ header, children }: AccordionProps) => {
  const [isOn, toggle] = useToggle();

  const toggleAccordion = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    toggle();
  };

  return (
    <S.AccordionSection>
      <S.AccordionTitleContainer onClick={toggleAccordion} $isopen={isOn}>
        <S.AccordionTitle>{header}</S.AccordionTitle>
        <S.SelectArrow $isopen={isOn} />
      </S.AccordionTitleContainer>
      <S.AccordionContentWrapper $isopen={isOn}>
        {children}
      </S.AccordionContentWrapper>
    </S.AccordionSection>
  );
};

Accordion.Item = AccordionItem;
export { Accordion };
