import React, { Children, cloneElement, isValidElement } from 'react';

type EnhanceChildElementProps<T> = {
  children: React.ReactNode;
  component?: React.ComponentType<T>;
  newProps: Partial<T>;
};

/**
 * `enhanceChildElement` 함수는 자식 요소들에 새로운 props를 주입하여 확장하는 유틸리티 함수입니다. 특정 컴포넌트
 * 타입의 자식 요소에만 props를 주입할 수 있습니다.
 *
 * @template T - 주입할 props의 타입.
 * @param props - 컴포넌트의 속성들.
 * @param props.children - 자식 요소들.
 * @param props.component - 주입할 props의 대상이 되는 특정 컴포넌트 타입. 이 타입의 자식 요소에만 props가
 *   주입됩니다.
 * @param props.newProps - 주입할 새로운 props.
 * @returns - 새 props가 주입된 자식 요소들을 반환합니다.
 */
export const enhanceChildElement = <T>({
  children,
  component,
  newProps,
}: EnhanceChildElementProps<T>) => {
  return Children.map(children, (child) => {
    if (isValidElement(child) && (!component || child.type === component)) {
      return cloneElement(
        child as React.ReactElement<T>,
        {
          ...child.props,
          ...newProps,
        } as Partial<T>,
      );
    }
    return child;
  });
};
