import { FileType, PopupPosition, PopupType } from '@/types';

import { PopupDataType } from '@/apis';

import {
  ButtonLoadingTypes,
  PopupEditorPanel,
  PopupModifyLayout,
  PopupPreview,
} from '@/components';

import {
  useAddPopupMutation,
  useFileManager,
  useModifyPopupMutation,
  useMultipartUploadMutation,
  usePopupQuery,
} from '@/hooks';

import { PATH } from '@/constants';

import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

const buttonReducer = (_: ButtonLoadingTypes, action: ButtonLoadingTypes) =>
  action;

type Popup = Omit<PopupDataType, 'file'> &
  Partial<Pick<PopupDataType, 'file'>> & { newFile?: File };

const initPopup: Popup = {
  type: PopupType.QR,
  name: '',
  position: PopupPosition.LEFT,
  description: '',
  qrColor: '#000000',
  descriptionColor: '#000000',
  url: '',
  file: undefined,
  newFile: undefined,
};

export const PopupModify = () => {
  const navigate = useNavigate();
  const params = useParams();
  const popupId = params.popupId ?? '';
  const isNew = popupId === 'new';
  const { data } = usePopupQuery({ popupId, enabled: !isNew });
  const [popup, setPopup] = useState<Popup>(initPopup);
  const [buttonState, buttonStateDispatch] = useReducer(
    buttonReducer,
    'normal',
  );
  const isSaveDisabled =
    !popup.name ||
    !popup.type ||
    !popup.position ||
    (popup.type === PopupType.QR
      ? !popup.url
      : !(popup.file || popup.newFile)) ||
    buttonState !== 'normal';

  const { mutate: addPopup } = useAddPopupMutation({
    popupId,
    onMutate: async () => buttonStateDispatch('loading'),
    onSuccess: (newId) => {
      const successTimeout = setTimeout(() => {
        buttonStateDispatch('complete');
        const timeout = setTimeout(() => {
          buttonStateDispatch('normal');
          clearTimeout(timeout);
        }, 1000);
        navigate(PATH.POPUP_MODIFY_PAGE(newId), { replace: true });
        clearTimeout(successTimeout);
      }, 500);
    },
    onError: () => buttonStateDispatch('normal'),
  });

  const { mutate: modifyPopup } = useModifyPopupMutation({
    popupId,
    onMutate: async () => buttonStateDispatch('loading'),
    onSuccess: () => {
      const successTimeout = setTimeout(() => {
        buttonStateDispatch('complete');
        const timeout = setTimeout(() => {
          buttonStateDispatch('normal');
          clearTimeout(timeout);
        }, 1000);
        clearTimeout(successTimeout);
      }, 500);
    },
    onError: () => buttonStateDispatch('normal'),
  });

  const { mutateAsync: uploadFile } = useMultipartUploadMutation();

  const removeAll = useFileManager((state) => state.removeAll);

  const savePopupHandler = async () => {
    let popupImage = {
      type: FileType.IMAGE,
      uuid: '',
      originalName: '',
      originalUrl: '',
    };

    if (popup.newFile) {
      const uploaded = await uploadFile([
        {
          file: popup.newFile,
          id: v4(),
          uploadProgress: 0,
          uploadStatus: 'idle',
        },
      ]);
      if (uploaded[0]) {
        // eslint-disable-next-line no-unused-vars
        const { id, ...image } = uploaded[0];
        removeAll();
        popupImage = image;
      }
    }

    if (isNew) {
      addPopup({
        name: popup.name,
        type: popup.type,
        url: popup.type === PopupType.IMAGE ? '' : popup.url,
        qrColor: popup.qrColor,
        description: popup.description,
        descriptionColor: popup.descriptionColor,
        position: popup.position,
        file: popupImage.uuid ? popupImage : undefined,
      });
    } else {
      modifyPopup({
        name: popup.name,
        type: popup.type,
        url: popup.type === PopupType.IMAGE ? '' : popup.url,
        qrColor: popup.qrColor,
        description: popup.description,
        descriptionColor: popup.descriptionColor,
        position: popup.position,
        file: popup.newFile
          ? popupImage
          : popup.file
            ? { id: (popup.file as { id: string }).id }
            : undefined,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setPopup({
        name: data.name ?? initPopup.name,
        type: data.type ?? initPopup.type,
        url: data.url ?? initPopup.url,
        qrColor: data.qrColor ?? initPopup.qrColor,
        description: data.description ?? initPopup.description,
        descriptionColor: data.descriptionColor ?? initPopup.descriptionColor,
        position: data.position ?? initPopup.position,
        file: data.file ?? initPopup.file,
      });
    }
  }, [data]);

  return (
    <PopupModifyLayout
      isSaveDisabled={isSaveDisabled}
      buttonState={buttonState}
      savePopup={savePopupHandler}
    >
      <PopupPreview popup={popup} />
      <PopupEditorPanel popup={popup} setPopup={setPopup} />
    </PopupModifyLayout>
  );
};
