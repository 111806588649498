import { PageReqOptionDto, PromotionType } from '@/types';

/**
 * 초 단위의 숫자를 'HH:MM:SS' 형식의 문자열로 변환합니다.
 *
 * @param seconds - 초 단위의 숫자
 * @returns 'HH:MM:SS' 형식의 문자열
 */
export const convertSecondsToTimeString = (seconds: number): string => {
  // 두 자리 숫자로 포맷팅
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  const secs = String(seconds % 60).padStart(2, '0');

  // 'HH:MM:SS' 형식의 문자열로 반환
  return `${hours}:${minutes}:${secs}`;
};

export const paddingZero = (num: number) => `${num}`.padStart(2, '0');

export const secondToTimeFormat = (second: number) =>
  `${paddingZero(Math.floor(second / 3600))}:${paddingZero(Math.floor((second % 3600) / 60))}:${paddingZero(second % 60)}`;

export const timeFormatToSecond = (time: string): number => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const dateFormat = (date: string | Date, divider = '-') => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  return `${year}${divider}${paddingZero(month)}${divider}${paddingZero(day)}`;
};
export const dashToSlash = (text: string) => text.replaceAll('-', '/');
export const timeToHHMM = (date: string) =>
  date.split(':').slice(0, 2).join(':');
export const hhmmToSecond = (hhmm: string) =>
  Number(hhmm.split(':')[0]) * 60 * 60 + Number(hhmm.split(':')[1]) * 60;
export const hhmmssToSecond = (hhmm: string) =>
  Number(hhmm.split(':')[0]) * 60 * 60 +
  Number(hhmm.split(':')[1]) * 60 +
  Number(hhmm.split(':')[2]);
export const promotionTypeToKor = (type: string) => {
  switch (type) {
    case PromotionType.ONCE: {
      return '한 번만';
    }

    case PromotionType.DAILY: {
      return '매일';
    }

    case PromotionType.WEEKLY: {
      return '주간';
    }

    case PromotionType.MONTHLY: {
      return '월간';
    }
  }
};
export const numToWeekDay = (num: number) =>
  ['일', '월', '화', '수', '목', '금', '토'][num];

/**
 * URL에 페이지 요청 옵션을 쿼리 파라미터로 추가하여 반환합니다.
 *
 * @param url - 기본 URL
 * @param options - 페이지 요청 옵션 객체
 * @returns 쿼리 파라미터가 추가된 URL 문자열
 */
export const appendPageOptionsToUrl = (
  url: string,
  options: PageReqOptionDto,
): string => {
  const params = new URLSearchParams();
  if (options.page) {
    params.append('page', options.page.toString());
  }
  if (options.take) {
    params.append('take', options.take.toString());
  }
  if (options.q) {
    params.append('q', options.q);
  }
  return `${url}${params.toString().length > 0 ? '?' : ''}${params.toString()}`;
};

export const stringToHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const codePoint = str.codePointAt(i) || 0;
    hash = (hash << 5) - hash + codePoint;
    hash = hash & hash; // 32비트 정수로 변환
  }
  return Math.abs(hash);
};

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: 'accurate' | 'normal';
  } = {},
) {
  const { decimals = 0, sizeType = 'normal' } = opts;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const accurateSizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === 'accurate'
      ? (accurateSizes[i] ?? 'Bytest')
      : (sizes[i] ?? 'Bytes')
  }`;
}
