import { PALETTE } from '@/themes';

import styled from 'styled-components';

export const SvgButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  padding: 0;
  border: none;

  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &:hover > * {
    color: ${PALETTE.interaction.hover.dark};
  }
`;
