import { TextField } from '@/components/common';

import { useCheckPlaylistNameMutation, useDebounce } from '@/hooks';

import { INVALID_MASSAGE } from '@/constants';

import { useEffect, useState } from 'react';

import * as S from './index.style';

type NameModifyProps = {
  playlistId: string;
  defaultValue?: string;
  setName: (name: string) => void;
};

export const NameModify = ({
  playlistId,
  defaultValue = '',
  setName,
}: NameModifyProps) => {
  const [localName, setLocalName] = useState(defaultValue);
  const [nameError, setNameError] = useState({ isError: false, message: '' });
  const debouncedName = useDebounce(localName);

  const { mutate: checkDuplicatedName } = useCheckPlaylistNameMutation({
    onSuccess: (isDuplicated) => {
      if (isDuplicated) {
        setName('');
        setNameError({
          isError: true,
          message: INVALID_MASSAGE.ALREADY_USED_NAME,
        });
      } else {
        setName(localName);
        setNameError({ isError: false, message: '' });
      }
    },
    onError: () => {
      setName('');
      setNameError({
        isError: true,
        message: INVALID_MASSAGE.INVALID_PLAYLIST_NAME,
      });
    },
  });

  useEffect(() => {
    if (debouncedName.length > 0) {
      checkDuplicatedName({
        id: playlistId === 'new' ? undefined : playlistId,
        name: debouncedName,
      });
    }
  }, [debouncedName]);

  useEffect(() => {
    if (localName.length === 0) {
      setName('');
      setNameError({ isError: false, message: '' });
    }
  }, [localName]);

  useEffect(() => {
    setLocalName(defaultValue);
  }, [defaultValue]);

  return (
    <S.Container>
      <S.Title>
        재생 목록 이름 <S.Required>(필수)</S.Required>
      </S.Title>
      <TextField
        value={localName}
        onChange={(e) => setLocalName(e.target.value)}
        errorMessage={nameError.message}
      />
    </S.Container>
  );
};
