import { PALETTE, TYPOGRAPHY } from '@/themes';

import styled, { css } from 'styled-components';

export const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Button = styled.button<{ $isfocus: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6px;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  ${TYPOGRAPHY.body2};

  ${({ $isfocus }) => css`
    border: ${$isfocus ? `solid 1px ${PALETTE.primary.normal}` : 'none'};

    background: ${$isfocus ? PALETTE.primary.light.normal : 'transparent'};

    color: ${$isfocus ? PALETTE.primary.normal : PALETTE.text.normal};
    font-weight: ${$isfocus ? 700 : 400};
  `}
`;

export const ArrowBtn = styled.div`
  display: flex;
  align-items: center;
`;
